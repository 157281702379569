import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useReducer,
} from "react";
import {
  ToggleButtonGroup,
  ToggleButton,
  Button,
  IconButton,
  Tooltip,
  createTheme,
  ThemeProvider,
  TextField,
  Grid,
} from "@mui/material";
import {
  headStyle,
  hrStyle,
  buttonStyle,
  headStyleInverted,
  backgroundStyle,
  lightColor,
  verticalCenter,
  colourFadeBack,
  colourFadeBackDarker,
} from "../../css/styling.js";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import StepContent from "@mui/material/StepContent";
import Paper from "@mui/material/Paper";
import OutlinedInput from "@mui/material/OutlinedInput";
import * as API2 from "../states.js";
import * as API from "../apis/APIWeb.js";
import HexagonIcon from "@mui/icons-material/Hexagon";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SettingsIcon from "@mui/icons-material/Settings";
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
// import PropTypes from 'prop-types';

import { colourAccent as defaultColourAccent, colourAccentSecondary } from "../../css/styling.js";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

// import { PanToolOutlined } from '@mui/icons-material';
// import PanToolIcon from '@mui/icons-material/PanTool';
// import DragHandleIcon from '@mui/icons-material/DragHandle';
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
// import ClearAllIcon from '@mui/icons-material/ClearAll';
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import { RemoveCircleOutline, VisibilityOutlined } from "@mui/icons-material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
// import Typography from '@mui/material/Typography';
// import Box from '@mui/material/Box';
import Stack from "@mui/material/Stack";
import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

import md5 from "md5";

import { DateTimeRangePickerPopper } from "../dElements/DateTimeRangePicker.js";

import {
  useElementOnScreen,
  Trailer,
  spanCharacters,
  spanArray,
  spanArrayStringEqualSplit,
  Transitioner,
  Transvoider,
} from "../dElements/Animations.js";

import * as tf from '@tensorflow/tfjs';

import { canvasFetchModelData } from "../apis/APIWeb.js";
// import CanvasDataVolume from './CDataVolume.js';
// import CanvasMLConfig from './CMLConfig.js';

// import Box from '@mui/material/Box';
// import Slider from '@mui/material/Slider';

// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { renderDigitalClockTimeView } from '@mui/x-date-pickers/timeViewRenderers';
// import { DateTimeRangePicker } from '@mui/x-date-pickers-pro/DateTimeRangePicker';

import dayjs from "dayjs";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { NewID } from "../datastructures/UserStructures.js";
import { CanvasMLConfig } from "./CanvasMLConfig.js";
import {
  tensorTrainDynamic,
  tensorPredictDynamic,
} from "../mlmethods/tensorflow.js";
import { FileUploader, UploadModel } from './UploadLoad.js';
import { DButton } from "../dElements/DButton.js";
import { DInput } from "../dElements/DInput.js";
import { COLORS } from "../Platform.js";
import TInputText from "../dElements/TInputText.js";
import TButton from "../dElements/TButton.js";

// import { CanvasDataVolume } from './CanvasDataVolume.js';

/* eslint-disable */
// eslint-disable-next-line
// const brain = require("../../brain.js"); // eslint-disable-line
const steps = [
  {
    label: "Name your inference model",
    description: `Our platform allows you to create multiple inference models, this will allow us to create what-if scenarios for you using multiple models at once.`,
    description4: '(Provide a name for this model)'
  },
  {
    label: "Select and prepare features",
    description: `OUTPUT FEATURES: These are the prediction values you expect the machine learning models to infer.`,
    description2: `INPUT FEATURES: These will be independant values your machine learning models will base inferences from.`,
    description4: "(Select sensors on map to add features)",
  },
  {
    label: "Machine learning configurations",
    description: `Set the machine learning models. Use our recommended configurations if you are unsure.`,
    description4: "(Select and ML algorithm to use)",
    // description3: '[recurrent LSTM - locked]'
  },
  {
    label: "Training data volume",
    // description2: `INTERVAL: The interval between each time-series data-points your model is intended for.`,
    description: `RANGE: Data within this date-time range will be used for training for this model.`,
    description2: `EMBEDDINGS: Different time components can be included for this model.`,
    description3: 'DROPS: Rows to drop in the training data for these values.',
    description4: '(Provide a training data range)',
    // description4: '[15mins, Day-range - locked]'
  },
  // {
  //     label: 'Select input features',
  //     description:
  //         'These will be dependant values your machine learning models will make inferences from.',
  //     todo: '(Select sensors and add features)'
  // },
];

// function LinearProgressWithLabel(props) {
//     return (
//         <Box sx={{ display: 'flex', alignItems: 'center' }}>
//             <Box sx={{ width: '100%', mr: 1 }}>
//                 <LinearProgress variant="determinate" {...props} />
//             </Box>
//             <Box sx={{ minWidth: 35 }}>
//                 <Typography variant="body2" color="text.secondary">{`${Math.round(
//                     props.value,
//                 )}%`}</Typography>
//             </Box>
//         </Box>
//     );
// }

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "darkcyan" : "cyan",
  },
}));


export default function CanvasView({ style, focusCb }) {

  // const [activeStep, setActiveStep] = React.useState(0);
  const { colourAccent, borderRadius } = API2.globalStore()
  const aiworkflow = API2.aiworkflow();
  const modelProgress = API2.aiworkflow().modelProgress;
  const setModelProgress = API2.aiworkflow().setModelProgress;
  // const [mlModel, setMlModel] = useState(API2.MLMODEL);
  const currentModel = API2.currentModelStore();
  const {
    id,
    setCurrentModel,
    getCoordinates,
    coordinates,
    algorithm,
    trainingData,
    modelInterval,
    modelBinary,
    setEmbeddings,
    setDrops,
    lastTrainedOn
  } = API2.currentModelStore();
  const clearModel = API2.currentModelStore().clearModel;
  const removeFeature = API2.currentModelStore().removeFeature;
  // const updateModel = API2.globalStore().updateModel;
  const { updateState, urlParams, progress, setProgress } = API2.globalStore();
  const { canvas, setPredictionResults } = API2.globalStore();
  const setTrainingStage = API2.aiworkflow().setTrainingStage.bind(this);
  const trainingStage = API2.aiworkflow().trainingStage;
  // const trainingData = API2.aiworkflow().trainingData;
  const saveModel = API2.currentModelStore().saveModel;
  const setFocusNode = API2.aiworkflow().setFocusNode;
  const {
    canvasModels,
    updateModel,
    removeModel,
    removeImport,
    setImportVisibility,
    setCanvasMode,
    downloadCanvas,
    // geojsonImports,
    imports,
    aggregators,
    updateTrainingData,
    // loadCanvas,
    // loadCanvas2,
    // loadCanvas3,
    // loadCanvas4,
    currentNexFocus,
    setCurrentNexFocus,
    duplicateCanvasModel,
    removeAggregator,
    setAggregatorVisibility,
    exportModel,
    exportAggregator
  } = API2.aiworkflow();
  const {
    setDataStart,
    setDataEnd,
    updateAccuracy,
    setTrainingDataEmbeddings,
    layers,
    compile,
    saveModelBinary,
    updateLastTrainedOn
  } = API2.currentModelStore();
  const { dataStart, dataEnd, embeddings } =
    API2.currentModelStore().trainingData;

  const [tData, setTData] = useState(null);
  const [uploadModel, setUploadModel] = useState(false);
  // const [progress, setProgress] = React.useState(-1);

  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const handleNext = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setModelProgress(modelProgress + 1);
    updateModel(currentModel);

    // aiworkflow.setNextModelProgress()
  };

  const handleBack = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setModelProgress(modelProgress - 1);
  };

  const handleReset = () => {
    // setActiveStep(0);
    setModelProgress(0);
  };

  useEffect(() => {
    updateModel(currentModel);
  }, [JSON.stringify(currentModel.inputs), JSON.stringify(currentModel.outputs)])

  // const fetchTrainingData = () => {
  //   let response = {};

  useEffect(() => {
    if (progress >= 0 && progress !== 100)
      setModelProgress(steps.length)
  }, [progress])




  return (
    <Box
      className="row"
      style={{
        padding: "10px",
        ...backgroundStyle,
        display: "inline-grid",
        zIndex: 1,
        borderRadius: borderRadius,
        width: modelProgress === -1 ? 415 : 515,
        marginLeft: modelProgress === -1 ? 150 : 0,
        maxHeight: 715,
        ...style,
      }}
    >
      <Tooltip title="canvas manager">
        <ChevronLeft
          style={{
            top: 10,
            right: "45px",
            position: "absolute",
            cursor: "pointer",
            display: modelProgress === -1 ? "none" : "inline-block",
          }}
          onClick={() => {
            // clearModel();
            updateModel(currentModel);
            getCoordinates();
            setModelProgress(-1);
          }}
        />
      </Tooltip>
      <DragIndicatorIcon
        style={{ top: 10, right: "15px", position: "absolute", cursor: "grab" }}
      />
      <div
        style={{
          width: modelProgress === 1 ? "50%" : "100%",
          fontSize: "14px",
          paddingBottom: "10px",
          paddingLeft: "5px",
        }}
      >
        {modelProgress === -1 && " NEXUS CANVAS"}{" "}
        {modelProgress !== -1
          ? currentModel?.name && " NEX_AI - " + currentModel?.name
          : null}
      </div>

      {/* {false && modelProgress === -1 && <Box style={{ display: 'inline-flex', width: '100%', marginBottom: '5px' }}>

        <FileUploader mode="file" accept=".canv"
          btnStyle={{
            textAlign: "center",
            color: colourAccent,
            borderRadius: "3px",
            cursor: "pointer",
            color: "white",
            display: "inline-block",
          }}
          boxStyle={{
            textAlign: "center",
            color: colourAccent,
            borderRadius: "2px",
            cursor: "pointer",
            color: "white",
            border: `1px solid rgba(255,255,255,0.2)`,
            width: "50%",
            textAlign: "center",
            // background: 'transparent',
            background: colourAccentSecondary,
            color: "white",
            opacity: 0.9,
            margin: '3px',
            display: "inline-block",
            textAlign: 'center',
            // fontSize: 'max(0.5vh, 12px)'
          }}
          handleFileChange={async (event) => {
            const file = event.target.files[0];
            if (file) {
              try {
                // const loadedCanvasStructure = await loadCanvas(file);
                // setCanv2(true)
                // console.log('Canvas loaded:', loadedCanvasStructure);
                // Further handling of the loaded structure here
              } catch (error) {
                console.error('Error loading canvas structure:', error);
              }
            }
          }}
          label="UPLOAD CANVAS" />

        <Button

          style={{
            padding: "8px",
            // background: 'rgba(255,255,255,0.8)',
            textAlign: "center",
            // color: colourAccent,
            // background: 'rgb(31 31 31 / 40%)',
            borderRadius: "3px",
            cursor: "pointer",
            // background: colourAccentSecondary,
            color: "white",
            border: `1px solid rgba(255,255,255,0.2)`,
            // border: `2px solid ${colourAccent}`,
            // borderColor: colourAccent, border: 'solid',
            // borderWidth: '5px'
            width: "50%",
            // display: "inline-block",
            // float: "right",
            padding: "8px",
            textAlign: "center",
            // background: 'transparent',
            color: "white",
            borderRadius: '2px',
            // border: `1px solid rgba(255,255,255,0.2)`,
            opacity: 0.9,
            margin: '3px',
            // width: "31%",
            display: "inline-block",
            textAlign: 'center'
          }}

          onClick={async () => {
            downloadCanvas()
          }}
        >
          Download Canvas
        </Button>

      </Box>} */}

      <div style={{ display: "flex" }}>
        {modelProgress === -1 && (
          <div
            style={{
              width: "100%",
              // paddingLeft: '10px',
              // paddingRight: '10px',
              // placeSelf: 'center',
              // marginTop: '-54px',
            }}
          >
            <div style={{ height: "4px" }} />
            <Trailer
              open={true}
              behaviour={{
                x: 0,
                y: 0,
                height: 0,
                from: { opacity: 0, scale: 0.98 },
                to: { opacity: 1, scale: 1 },
                delay: 25,
              }}
              behaviourConfig={{ duration: 20 }}
            >
              {Object.keys(canvasModels).map((k) => (
                <Box
                  style={{
                    padding: "10px",
                    marginBottom: "2px",
                    // background: 'rgba(255,255,255,0.2)',
                    background: colourFadeBackDarker,
                    borderRadius: "3px",
                    cursor: "pointer",
                    // borderColor: colourAccent,
                    // border: 'solid',
                    border:
                      currentNexFocus === canvasModels[k].id
                        ? `2px solid ${colourAccent}`
                        : `1px solid rgba(255,255,255,0.1)`,
                    // borderWidth: '2px', border: 'solid', borderColor: colourAccent
                  }}
                >
                  <span
                    onClick={() => {
                      setCurrentModel(canvasModels[k]);
                      setCurrentNexFocus(canvasModels[k].id)

                      // focusCb && focusCb(currentModel.coordinates)
                      // console.log('canvas focuscb', canvasModels[k].coordinates)
                      focusCb && focusCb('NEX_AI', canvasModels[k])
                      // setModelProgress(0)
                    }}
                    style={{
                      width: "calc(100% - 90px)",
                      display: "inline-block",
                    }}
                  >
                    <span className="w-[10px] h-[10px] transform rotate-45 inline-block mr-[10px]" style={{ backgroundColor: `rgb(${COLORS.NEXUS9.join(',')})` }} />

                    <span
                      style={{
                        width: "55%",
                        display: "inline-block",
                        // textAlign: 'center'
                      }}
                    >
                      {canvasModels[k].name}
                    </span>

                    <span style={{ width: "35%" }}>
                      AI MODEL
                      {/* {canvasModels[k].algorithm.algo !== "" &&
                        `${canvasModels[k].algorithm.algo}, `}
                      {canvasModels[k]?.trainingData?.lastAccuracy >= 0 &&
                        `${canvasModels[k]?.trainingData?.lastAccuracy}% `} */}
                    </span>
                  </span>

                  <span
                    style={{ float: "right", color: "red" }}
                    onClick={() => removeModel(canvasModels[k].id)}
                  >
                    <RemoveCircleOutlineIcon />
                  </span>

                  <span
                    style={{ float: "right", color: "white" }}
                    onClick={() => {
                      setCurrentModel(canvasModels[k]);
                      focusCb && focusCb('NEX_AI', canvasModels[k])
                      setModelProgress(0);
                    }}
                  >
                    <SettingsIcon />
                  </span>

                  <span
                    style={{ float: "right", color: "white" }}
                    onClick={() => {
                      // setCurrentModel(canvasModels[k])
                      // setModelProgress(0)
                      // setCurrentModel(canvasModels[k]);
                      let newid = duplicateCanvasModel(k)
                      // focusCb && focusCb('NEX_AI', canvasModels[newid])
                      // setCanvasMode("analytics");
                      // alert('analysis')
                    }}
                  >
                    <ContentCopyIcon />
                  </span>

                  <span
                    style={{ float: "right", color: "white" }}
                    onClick={() => {
                      // setCurrentModel(canvasModels[k])
                      // setModelProgress(0)
                      setCurrentModel(canvasModels[k]);
                      focusCb && focusCb('NEX_AI', canvasModels[k])
                      setCanvasMode("analytics");
                      // alert('analysis')
                    }}
                  >
                    <AutoGraphIcon />
                  </span>

                  <span
                    style={{ float: "right", color: "white" }}
                    onClick={() => {
                      // setCurrentModel(canvasModels[k])
                      // setModelProgress(0)
                      exportModel(canvasModels[k]);
                      // alert('analysis')
                    }}
                  >
                    <FileDownloadIcon />
                  </span>

                  {/* <span
                    style={{ float: "right", color: colourAccent }}
                    onClick={() => {
                      // setCurrentModel(canvasModels[k])
                      // setModelProgress(0)
                      // setCanvasMode("analytics");
                      // alert('analysis')
                    }}
                  >
                    <ModelTrainingIcon />
                  </span> */}

                  {/* <span
                    style={{ float: "right", color: "white" }}
                    onClick={() => {
                      // setCurrentModel(canvasModels[k])
                      // setModelProgress(0)
                      // setCanvasMode("analytics");
                      console.log(currentModel)
                      // alert('analysis')
                    }}
                  >
                    L
                  </span> */}

                  {/* <span style={{ float: 'right', width: '20px' }} /> */}
                </Box>
              ))}

              {Object.keys(imports).map((k) => (
                <Box
                  style={{
                    padding: "10px",
                    marginBottom: "2px",
                    // background: 'rgba(255,255,255,0.2)',
                    background: colourFadeBackDarker,
                    borderRadius: "3px",
                    cursor: "pointer",
                    // borderColor: colourAccent,
                    // border: 'solid',
                    border:
                      currentNexFocus === imports[k].id
                        ? `2px solid ${colourAccent}`
                        : `1px solid rgba(255,255,255,0.1)`,
                    // borderWidth: '2px', border: 'solid', borderColor: colourAccent
                  }}
                >
                  <span
                    onClick={() => {
                      // setCurrentImport(csvImports[k]);
                      // setCurrentModel(csvImports[k])
                      // focusCb && focusCb(currentModel.coordinates)
                      console.log(imports[k])
                      setCurrentNexFocus(imports[k].id)
                      focusCb && focusCb('NEX_IMPORT', imports[k])
                      // setModelProgress(0)
                    }}
                    style={{
                      width: "calc(100% - 90px)",
                      display: "inline-block",
                    }}
                  >
                    <span className="w-[10px] h-[10px] transform rotate-45 inline-block mr-[10px]" style={{ backgroundColor: `rgb(${COLORS.NEXUS7.join(',')})` }} />
                    <span
                      style={{
                        width: "55%",
                        display: "inline-block",
                        // textAlign: 'center'
                      }}
                    >
                      {imports[k].asset_id}
                    </span>

                    <span style={{ width: "35%" }}>
                      IMPORT [{imports[k].fileType.replace('.', '').toUpperCase()}]
                    </span>
                  </span>


                  <span
                    style={{ float: "right", color: "red" }}
                    onClick={() => removeImport(imports[k].id)}
                  >
                    <RemoveCircleOutlineIcon />
                  </span>



                  {imports[k].fileType === '.geojson' && <span
                    style={{ float: "right", color: imports[k].visible ? 'cyan' : "white" }}
                    onClick={() => setImportVisibility(imports[k].id)}
                  >
                    {/* <RemoveCircleOutlineIcon /> */}
                    <VisibilityOutlined />
                  </span>}

                </Box>
              ))}

              {/* {JSON.stringify(aggregators)} */}

              {/* {JSON.stringify(currentNexFocus)} */}

              {Object.keys(aggregators).map((k) => (
                <Box
                  style={{
                    padding: "10px",
                    marginBottom: "2px",
                    // background: 'rgba(255,255,255,0.2)',
                    background: colourFadeBackDarker,
                    borderRadius: "3px",
                    cursor: "pointer",
                    // borderColor: colourAccent,
                    // border: 'solid',
                    border:
                      currentNexFocus === aggregators[k].asset_id
                        ? `2px solid ${colourAccent}`
                        : `1px solid rgba(255,255,255,0.1)`,
                    // borderWidth: '2px', border: 'solid', borderColor: colourAccent
                  }}
                >
                  <span
                    onClick={() => {
                      // setCurrentImport(csvImports[k]);
                      // setCurrentModel(csvImports[k])
                      // focusCb && focusCb(currentModel.coordinates)
                      console.log(`NEX_AGGREGATOR.${aggregators[k].asset_id}`)
                      setCurrentNexFocus(aggregators[k].asset_id)
                      focusCb && focusCb('NEX_AGGREGATOR', aggregators[k])
                      // setModelProgress(0)
                    }}
                    style={{
                      width: "calc(100% - 90px)",
                      display: "inline-block",
                    }}
                  >
                    <span className="w-[10px] h-[10px] transform rotate-45 inline-block mr-[10px]" style={{ backgroundColor: `rgb(${COLORS.NEXUS6.join(',')})` }} />
                    <span
                      style={{
                        width: "55%",
                        display: "inline-block",
                        // textAlign: 'center'
                      }}
                    >
                      {aggregators[k].asset_id}
                    </span>

                    <span style={{ width: "35%" }}>
                      AGGREGATOR
                    </span>
                  </span>


                  <span
                    style={{ float: "right", color: "red" }}
                    onClick={() => removeAggregator(k)}
                  >
                    <RemoveCircleOutlineIcon />
                  </span>

                  {aggregators[k].fileType === '.geojson' && <span
                    style={{ float: "right", color: aggregators[k].visible ? 'cyan' : "white" }}
                    onClick={() => setAggregatorVisibility(aggregators[k].id)}
                  >
                    {/* <RemoveCircleOutlineIcon /> */}
                    <VisibilityOutlined />
                  </span>}

                  <span
                    style={{ float: "right", color: "white" }}
                    onClick={() => {
                      // setCurrentModel(canvasModels[k])
                      // setModelProgress(0)
                      exportAggregator(k);
                      // alert('analysis')
                    }}
                  >
                    <FileDownloadIcon />
                  </span>

                </Box>
              ))}

              {/* 
              {Object.keys(csvImports).map((k) => (
                <Box
                  style={{
                    padding: "10px",
                    marginBottom: "2px",
                    // background: 'rgba(255,255,255,0.2)',
                    background: colourFadeBackDarker,
                    borderRadius: "3px",
                    cursor: "pointer",
                    // borderColor: colourAccent,
                    // border: 'solid',
                    border:
                      currentNexFocus === csvImports[k].id
                        ? `2px solid ${colourAccent}`
                        : `1px solid rgba(255,255,255,0.1)`,
                    // borderWidth: '2px', border: 'solid', borderColor: colourAccent
                  }}
                >
                  <span
                    onClick={() => {
                      // setCurrentImport(csvImports[k]);
                      // setCurrentModel(csvImports[k])
                      // focusCb && focusCb(currentModel.coordinates)
                      // console.log(csvImports[k])
                      setCurrentNexFocus(csvImports[k].id)
                      focusCb && focusCb('NEX_IMPORT', csvImports[k])
                      // setModelProgress(0)
                    }}
                    style={{
                      width: "calc(100% - 90px)",
                      display: "inline-block",
                    }}
                  >
                    <span className="w-[10px] h-[10px] transform rotate-45 inline-block mr-[10px]" style={{ backgroundColor: `rgb(${COLORS.NEXUS7.join(',')})` }} />
                    <span
                      style={{
                        width: "60%",
                        display: "inline-block",
                        // textAlign: 'center'
                      }}
                    >
                      {csvImports[k].asset_id}
                    </span>

                    <span style={{ width: "30%" }}>
                      IMPORT [CSV]
                    </span>
                  </span>

                  <span
                    style={{ float: "right", color: "red" }}
                    onClick={() => removeCsvImport(csvImports[k].id)}
                  >
                    <RemoveCircleOutlineIcon />
                  </span>

                </Box>
              ))}

              {Object.keys(geojsonImports).map((k) => (
                <Box
                  style={{
                    padding: "10px",
                    marginBottom: "2px",
                    // background: 'rgba(255,255,255,0.2)',
                    background: colourFadeBackDarker,
                    borderRadius: "3px",
                    cursor: "pointer",
                    // borderColor: colourAccent,
                    // border: 'solid',
                    border:
                      currentNexFocus === geojsonImports[k].id
                        ? `2px solid ${colourAccent}`
                        : `1px solid rgba(255,255,255,0.1)`,
                    // borderWidth: '2px', border: 'solid', borderColor: colourAccent
                  }}
                >
                  <span
                    onClick={() => {
                      // setCurrentImport(csvImports[k]);
                      // setCurrentModel(csvImports[k])
                      // focusCb && focusCb(currentModel.coordinates)
                      // console.log(csvImports[k])
                      setCurrentNexFocus(geojsonImports[k].id)
                      focusCb && focusCb('NEX_IMPORT', geojsonImports[k])
                      // setModelProgress(0)
                    }}
                    style={{
                      width: "calc(100% - 90px)",
                      display: "inline-block",
                    }}
                  >
                    <div className="w-[10px] h-[10px] transform rotate-45 inline-block mr-[10px]" style={{ backgroundColor: `rgb(${COLORS.NEXUS7.join(',')})` }} />
                    <span
                      style={{
                        width: "60%",
                        display: "inline-block",
                      }}
                    >
                      {geojsonImports[k].asset_id}
                    </span>

                    <span style={{ width: "30%" }}>
                      IMPORT [GEOJSON]
                    </span>
                  </span>

                  <span
                    style={{ float: "right", color: "red" }}
                    onClick={() => removeGeojsonImport(geojsonImports[k].id)}
                  >
                    <RemoveCircleOutlineIcon />
                  </span>

                </Box>
              ))} */}

              <div style={{ height: "5px" }} />

              {/* <span style={{ paddingTop: "10px" }}> */}
              <Box style={{ display: 'inline-flex', marginBottom: '5px', width: '100%' }}>

                <Button
                  style={{
                    padding: "8px",
                    // background: 'rgba(255,255,255,0.8)',
                    textAlign: "center",
                    color: colourAccent,
                    // background: 'rgb(31 31 31 / 40%)',
                    borderRadius: "3px",
                    cursor: "pointer",
                    // background: colourAccentSecondary,
                    color: "white",
                    border: `1px solid rgba(255,255,255,0.2)`,
                    // border: `2px solid ${colourAccent}`,
                    // borderColor: colourAccent, border: 'solid',
                    // borderWidth: '5px'
                    width: "50%",
                    // display: "inline-block",
                    // float: "right",
                    padding: "8px",
                    textAlign: "center",
                    background: 'transparent',
                    color: "white",
                    borderRadius: '2px',
                    // border: `1px solid rgba(255,255,255,0.2)`,
                    opacity: 0.9,
                    margin: '3px',
                    // width: "31%",
                    display: "inline-block",
                    textAlign: 'center'
                  }}
                  onClick={() => {
                    clearModel({});
                    setModelProgress(0);
                    console.log(imports, canvasModels)
                  }}
                >
                  NEW MODEL
                </Button>

                {/* <Box
                  style={{
                    padding: "8px",
                    // background: 'rgba(255,255,255,0.8)',
                    textAlign: "center",
                    color: colourAccent,
                    // background: 'rgb(31 31 31 / 40%)',
                    borderRadius: "3px",
                    cursor: "pointer",
                    background: colourAccentSecondary,
                    color: "white",
                    border: `1px solid rgba(255,255,255,0.2)`,
                    // border: `2px solid ${colourAccent}`,
                    // borderColor: colourAccent, border: 'solid',
                    // borderWidth: '5px'
                    width: "43%",
                    display: "inline-block",
                    float: "right",
                  }}
                  onClick={() => {
                    // clearModel({ id: md5(new Date().toLocaleString()) });
                    alert('feature locked')
                    // setModelProgress(0);
                    // setCanvasMode("infer");
                  }}
                >
                  <span>PREDICTIONS</span>
                </Box> */}


                <FileUploader
                  multiple={true}
                  btnStyle={{
                    textAlign: "center",
                    // color: colourAccent,
                    borderRadius: "3px",
                    cursor: "pointer",
                    color: "white",
                    display: "inline-block",
                  }}
                  boxStyle={{
                    textAlign: "center",
                    // color: colourAccent,
                    borderRadius: "2px",
                    cursor: "pointer",
                    color: "white",
                    border: `1px solid rgba(255,255,255,0.2)`,
                    width: "50%",
                    textAlign: "center",
                    background: 'transparent',
                    color: "white",
                    opacity: 0.9,
                    margin: '3px',
                    display: "inline-block",
                    textAlign: 'center',
                    // fontSize: 'max(0.5vh, 12px)'
                  }}
                  mode="canvmdl" accept=".canvmdl,.canvagg" label="IMPORT" />

              </Box>

              {/* <pre style={{ overflowY: 'auto', height: '300px', width: '450px' }}>
                {JSON.stringify(Object.values(canvasModels), null, 2)}
                {JSON.stringify(Object.values(canvasModels).map(v => v.coordinates), null, 2)}
              </pre> */}

              {/* {uploadModel && <Box style={{ display: 'inline-flex', width: '100%' }}>
                <UploadModel close={() => setUploadModel(false)} />
              </Box>} */}

              {/* </span> */}
            </Trailer>
          </div>
        )}

        {modelProgress === 0 && (
          <div
            style={{
              paddingLeft: "5px",
              paddingRight: "5px",
              placeSelf: "center",
              marginTop: "-54px",
            }}
          >
            <Trailer
              open={true}
              behaviour={{
                x: 0,
                y: 0,
                height: 0,
                from: { opacity: 0, scale: 0.90 },
                to: { opacity: 1, scale: 1 },
                delay: 100,
              }}
              behaviourConfig={{ duration: 150 }}
            >
              <div
                style={{
                  background: colourFadeBack,
                  borderRadius: "4px",
                  padding: "8px",
                  color: colourAccent,
                  fontSize: "12px",
                }}
              >
                Nexus Name
                {/* <IconButton className='rounded-full text-diatomicCyan'>L</IconButton> */}
                <div style={{ height: '5px' }} />
                <TInputText
                  // class="w3-input w3-border w3-hover-blue"
                  className='w-[200px]'
                  type="text"
                  // style={{ marginTop: '6px', padding: '6px' }}
                  // onFocus={()=>}
                  // style={{
                  //   margin: 0,
                  //   top: "5px",
                  //   // padding: "6px",
                  //   borderRadius: "4px",
                  //   background: backgroundStyle.backgroundColor,
                  //   color: "white",
                  //   padding: '12px 20px',

                  //   width: '100%'
                  // }}
                  value={currentModel.name}
                  // defaultValue={'SAMPLE'}
                  onChange={
                    (e) => {
                      e.target.value = e.target.value.toUpperCase().replace('.', '_').replace(' ', '_')
                      const regex = /^[0-9a-zA-Z_.\[\](\-)]+$/;
                      if (e.target.value.toUpperCase().match(regex)) {
                        setCurrentModel({ ...currentModel, name: e.target.value })
                      }
                    }
                    // setCurrentModel({ ...currentModel, name: e.target.value.toUpperCase() })}
                  }
                />
                {/* <br />
                <br />
                Scope
                <div style={{ height: '5px' }} />
                <TButton className='shadow-none border-none'>Local</TButton>
                <TButton className='shadow-none border-none'>Regional</TButton>
                <TButton className='shadow-none border-none'>National</TButton> */}
              </div>
            </Trailer>
          </div>
        )}

        {modelProgress === 1 && (
          <div
            className="column"
            style={{
              color: colourAccent,
              borderRadius: "4px",
              background: true
                ? "rgb(31 31 31 / 40%)"
                : "rgba(255, 255, 255, 0.8)",
              marginRight: "10px",
              minWidth: "45%",
              // width: modelProgress !== 1 ? '0%' : '40%',
              display: modelProgress !== 1 ? "none" : "",
              overflowX: "auto",
              maxHeight: "calc(100% - 40px)",
              fontSize: "12px",
            }}
          >
            <Trailer
              open={true}
              behaviour={{
                y: 0,
                height: 0,
                from: { opacity: 0, x: 20 },
                to: { opacity: 1, x: 0 },
                delay: 200,
              }}
              behaviourConfig={{ duration: 150 }}
            >
              <div style={{ maxWidth: '310px' }}>
                {/* {JSON.stringify(currentModel)} */}
                OUTPUTS
                <br />
                {/* <hr style={{ background: 'darkcyan' }} /> */}
                <div style={{ height: "10px" }} />
                <Trailer
                  open={currentModel?.outputs}
                  behaviour={{
                    from: { opacity: 0, x: 0, height: 0 },
                    to: { opacity: 1, height: 32 },
                  }}
                  behaviourConfig={{ duration: 150 }}
                >
                  {Object.keys(currentModel.outputs).map((v, i) => (
                    <>
                      <Button
                        variant="outlined"
                        style={{
                          color: colourAccent,
                          background: "rgb(31 31 31 / 40%)",
                          borderColor: "rgb(31 31 31 / 40%)",
                          marginBottom: "3px",
                          // width: "100%",
                          minWidth: '220px',
                          whiteSpace: 'nowrap',
                          justifyContent: "left",
                          fontSize: "10px",
                          paddingRight: "30px",
                        }}
                        startIcon={
                          <RemoveCircleOutline
                            onClick={() => {
                              console.log('removing', currentModel.outputs[v])
                              removeFeature("outputs", currentModel.outputs[v])
                            }}
                          />
                        }
                      >
                        {currentModel.outputs[v].name}
                        {/* <span style={{ width: "30px" }} /> */}
                      </Button>
                      <br />
                    </>
                  ))}
                </Trailer>
                <br />
                INPUTS
                <br />
                {/* <hr style={{ background: 'darkcyan' }} /> */}
                <div style={{ height: "10px" }} />
                <Trailer
                  open={currentModel?.inputs}
                  behaviour={{
                    height: 32,
                    from: { opacity: 0, x: 0, height: 0 },
                    to: { opacity: 1, height: 32 },
                  }}
                  behaviourConfig={{ duration: 150 }}
                >
                  {Object.keys(currentModel.inputs).map((v, i) => (
                    <>
                      <Button
                        variant="outlined"
                        style={{
                          color: colourAccent,
                          background: "rgb(31 31 31 / 40%)",
                          borderColor: "rgb(31 31 31 / 40%)",
                          marginBottom: "3px",
                          // width: "100%",
                          // width: "100%",
                          minWidth: '220px',
                          whiteSpace: 'nowrap',
                          justifyContent: "left",
                          fontSize: "10px",
                        }}
                        // style={{ color: 'rgb(31 31 31 / 40%)', background: colourAccent, borderColor: colourAccent, marginBottom: '3px', width: '100%', justifyContent: 'left', fontSize: '12px' }}
                        startIcon={
                          <RemoveCircleOutline
                            onClick={() => {
                              // console.log(currentModel.inputs[v])
                              removeFeature("inputs", currentModel.inputs[v]);
                            }}
                          />
                        }
                      >
                        {currentModel.inputs[v].name}
                        <span style={{ width: "30px" }} />
                      </Button>
                      <br />
                    </>
                  ))}
                </Trailer>
              </div>
            </Trailer>
          </div>
        )}

        {modelProgress !== -1 && (
          <div
            className="column"
            style={{ width: modelProgress !== 1 ? "100%" : "55%" }}
          >
            <Stepper activeStep={modelProgress} orientation="vertical">
              {steps.map((step, index) => (
                <Step
                  key={step.label}
                  sx={{
                    "& .MuiStepIcon-text": {
                      // color: 'limegreen', // circle color (COMPLETED)
                      fontSize: "medium",
                      fill: "silver",
                    },
                    "& .MuiStepLabel-root .Mui-completed": {
                      color: colourAccent, // circle color (COMPLETED)
                    },
                    "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                    {
                      color: "grey.500", // Just text label (COMPLETED)
                    },
                    "& .MuiStepLabel-root .Mui-active": {
                      color: "white", // circle color (ACTIVE)
                    },
                    "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                    {
                      color: "white", // Just text label (ACTIVE)
                    },
                    "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                      fill: "black", // circle's number (ACTIVE)
                      fontSize: "large",
                    },
                  }}
                >
                  <StepLabel
                  //  style={{color: 'green'}}
                  // optional={
                  //     index === 2 ? (
                  //         <Typography style={{ color: 'white' }} variant="caption">Last step</Typography>
                  //     ) : null
                  // }
                  >
                    <Typography
                      style={{ color: colourAccent, fontSize: "14px" }}
                      variant="caption"
                    >
                      {step.label}
                    </Typography>
                  </StepLabel>

                  <StepContent>
                    {step.description ? (
                      <Typography
                        style={{
                          color: "white",
                          fontSize: "12px",
                          paddingBottom: "12px",
                        }}
                      >
                        {step.description}
                      </Typography>
                    ) : null}
                    {step.description2 ? (
                      <Typography
                        style={{
                          color: "white",
                          fontSize: "12px",
                          paddingBottom: "12px",
                        }}
                      >
                        {step.description2}
                      </Typography>
                    ) : null}
                    {step.description3 ? (
                      <Typography
                        style={{
                          color: "white",
                          fontSize: "12px",
                          paddingBottom: "12px",
                        }}
                      >
                        {step.description3}
                      </Typography>
                    ) : null}
                    {step.description4 ? (
                      <Typography
                        style={{
                          color: "white",
                          fontSize: "12px",
                          paddingBottom: "12px",
                        }}
                      >
                        {step.description4}
                      </Typography>
                    ) : null}

                    <Trailer
                      open={true}
                      behaviour={{
                        height: 230,
                        y: 0,
                        from: { x: 0, opacity: 0, scale: 0.95 },
                        to: { x: 0, opacity: 1, scale: 1 },
                        delay: 300,
                      }}
                    >
                      {modelProgress === 2 && (
                        <Paper
                          elevation={0}
                          sx={{ p: 3 }}
                          style={{
                            marginTop: "0px",
                            // marginBottom: "10px",
                            background: colourFadeBack,
                          }}
                        >
                          <CanvasMLConfig />
                        </Paper>
                      )}
                    </Trailer>

                    <Trailer
                      open={true}
                      behaviour={{
                        height: 230,
                        y: 0,
                        from: { x: 0, opacity: 0, scale: 0.95 },
                        to: { x: 0, opacity: 1, scale: 1 },
                        delay: 300,
                      }}
                    >
                      {
                        modelProgress === 3 && (
                          <Paper
                            elevation={0}
                            sx={{ p: 3 }}
                            style={{
                              marginTop: "0px",
                              // marginBottom: "10px",
                              background: colourFadeBack,
                            }}
                          >
                            <Grid container spacing={1} >
                              <Grid item xs={8}>

                                <DateTimeRangePickerPopper
                                  range={true}
                                  btnLabel={trainingData?.dataStart && trainingData?.dataEnd ? `${trainingData?.dataStart} --- ${trainingData?.dataEnd}` : `Select Range`}
                                  btnStyle={{ width: '100%', margin: 0, padding: 0 }}
                                  closeBtnStyle={{
                                    color: "white",
                                    background: "darkcyan",
                                    borderRadius: "2px",
                                    width: "120px",
                                    display: "flex",
                                    padding: 0,
                                    margin: 0,
                                    borderColor: 'darkcyan'
                                  }}
                                  onChange={(range) => {
                                    setDataStart(
                                      range[0].startDate.toISOString().split("T")[0]
                                    );
                                    setDataEnd(
                                      range[0].endDate.toISOString().split("T")[0]
                                    );
                                  }}
                                />
                              </Grid>

                              <Grid item xs={4}>
                                <TButton
                                  disabled={
                                    !trainingData?.dataStart ||
                                    !trainingData?.dataEnd
                                  }
                                  onClick={() => setCanvasMode('analysis')}
                                >
                                  Preview Data
                                </TButton>
                              </Grid>

                              {/* <div style={{ height: '10px' }} /> */}


                              {trainingData?.dataStart &&
                                trainingData?.dataEnd && <>
                                  <Grid item xs={1 / 5 * 12} style={{ placeContent: 'center' }}>
                                    <span style={{ color: 'white', paddingLeft: '18px' }}>Embeddings</span>
                                  </Grid>
                                  <Grid item xs={1 / 5 * 12}>
                                    <DButton
                                      active={trainingData.embeddings.includes('month')}
                                      style={{
                                        width: "100%",
                                      }}
                                      onClick={() => {
                                        let embeds = embeddings;
                                        if (embeds.includes('month'))
                                          setEmbeddings(embeds.filter(i => i !== 'month'))
                                        else
                                          setEmbeddings([...embeds, 'month'])
                                      }}
                                    >
                                      Month
                                    </DButton>
                                  </Grid>
                                  <Grid item xs={1 / 5 * 12}>
                                    <DButton
                                      active={trainingData.embeddings.includes('day')}
                                      style={{
                                        width: "100%",
                                      }}
                                      onClick={() => {
                                        let embeds = embeddings;
                                        if (embeds.includes('day'))
                                          setEmbeddings(embeds.filter(i => i !== 'day'))
                                        else
                                          setEmbeddings([...embeds, 'day'])
                                      }}
                                    >
                                      Day
                                    </DButton>
                                  </Grid>
                                  <Grid item xs={1 / 5 * 12}>
                                    <DButton
                                      active={trainingData.embeddings.includes('hour')}
                                      style={{
                                        width: "100%",
                                      }}
                                      onClick={() => {
                                        let embeds = embeddings;
                                        if (embeds.includes('hour'))
                                          setEmbeddings(embeds.filter(i => i !== 'hour'))
                                        else
                                          setEmbeddings([...embeds, 'hour'])
                                      }}
                                    >
                                      Hour
                                    </DButton>
                                  </Grid>
                                  <Grid item xs={1 / 5 * 12}>
                                    <DButton
                                      active={trainingData.embeddings.includes('minute')}
                                      style={{
                                        width: "100%",
                                      }}
                                      onClick={() => {
                                        let embeds = embeddings;
                                        if (embeds.includes('minute'))
                                          setEmbeddings(embeds.filter(i => i !== 'minute'))
                                        else
                                          setEmbeddings([...embeds, 'minute'])
                                      }}
                                    >
                                      Minute
                                    </DButton>
                                  </Grid>

                                  <Grid item xs={1 / 5 * 12} style={{ placeContent: 'center' }}>
                                    <span style={{ color: 'white', paddingLeft: '18px' }}>Drops</span>
                                  </Grid>
                                  <Grid item xs={1 / 5 * 12}>
                                    <DButton
                                      active={trainingData.drops.nulls}
                                      style={{
                                        width: "100%",
                                      }}
                                      onClick={() => setDrops({ ...trainingData.drops, nulls: !trainingData.drops.nulls })}
                                    >
                                      Nulls
                                    </DButton>
                                  </Grid>
                                  <Grid item xs={1 / 5 * 12}>
                                    <DButton
                                      active={trainingData.drops.zeroes}
                                      style={{
                                        width: "100%",
                                      }}
                                      onClick={() => setDrops({ ...trainingData.drops, zeroes: !trainingData.drops.zeroes })}
                                    >
                                      Zeroes
                                    </DButton>
                                  </Grid>
                                </>}





                            </Grid>

                          </Paper>
                        )}
                    </Trailer>

                    <Box sx={{ mb: 2 }}>
                      {(modelProgress === 0 && currentModel.name !== "") ||
                        (modelProgress === 1 &&
                          Object.keys(currentModel.outputs).length !== 0) ||
                        (modelProgress === 3 &&
                          trainingData.dataStart != null &&
                          trainingData.dataEnd != null) ||
                        (modelProgress === 2 &&
                          currentModel.algorithm.algo !== "") ? (
                        <Button
                          variant="contained"
                          className="buttonexotic"
                          style={{
                            ...buttonStyle,
                            color: "rgba(0,0,0,0.9)",
                            borderWidth: "revert",
                            // borderColor: 'seagreen',
                            // borderStyle: 'solid',
                            width: "120px",
                            height: "40px",
                            // fontSize: "12px",
                          }}
                          onClick={handleNext}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          {index === steps.length - 1 ? "Finish" : "Continue"}
                        </Button>
                      ) : null}
                      <Button
                        // className='buttonexotic'
                        style={{
                          color: "white",
                          width: "90px",
                          fontSize: "12px",
                        }}
                        // disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {index === 0 ? "Cancel" : "Back"}
                      </Button>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>

            <Trailer
              open={true}
              behaviour={{
                height: 250,
                y: 0,
                from: { x: 0, opacity: 0, scale: 0.6 },
                to: { x: 0, opacity: 1, scale: 1 },
                delay: 300,
              }}
            >
              {modelProgress === steps.length && (
                <Paper
                  elevation={0}
                  sx={{ p: 3 }}
                  style={{
                    marginTop: "20px",
                    background: "rgba(255,255,255,0.8)",
                  }}
                >
                  <Typography style={{ fontSize: "12px" }}>
                    Models will be trained in your browser so do not close your
                    browser. Training time depends on your model and your
                    computer hardware capabilities.
                  </Typography>
                  <br />
                  <Box style={{ paddingLeft: "10px" }}>
                    {/* <Typography style={{ fontSize: "14px" }}>
                      Algorithm: {algorithm.algo} @ {modelInterval}min Interval
                    </Typography> */}

                    <Typography style={{ fontSize: "12px" }}>
                      Algorithm: {algorithm.algo}
                    </Typography>




                    <Typography style={{ fontSize: "12px" }}>
                      Epochs: {currentModel.epochs}
                    </Typography>

                    {/* <Typography style={{ fontSize: "14px" }}>
                      Drops: {trainingData.embedding.join(' ').toUpperCase()}
                    </Typography> */}
                    {/* <br /> */}
                    {/* <br /> */}

                    <Typography style={{ fontSize: "12px" }}>
                      Outputs: {Object.keys(currentModel.outputs).length}, Inputs: {Object.keys(currentModel.inputs).length}
                    </Typography>

                    <Typography style={{ fontSize: "12px" }}>
                      Embeddings: {trainingData.embeddings.join(' ').toUpperCase()}
                    </Typography>

                    <Typography style={{ fontSize: "12px" }}>
                      Data: [ {trainingData?.dataStart?.toLocaleString()} --- {trainingData?.dataEnd?.toLocaleString()} ]
                    </Typography>

                    {/* <Typography style={{ fontSize: "14px" }}>
                 
                    </Typography> */}


                    <Typography style={{ fontSize: "12px" }}>
                      Trained: {lastTrainedOn}
                    </Typography>

                    {/* <br /> */}
                    {/* <Typography style={{ fontSize: '14px' }}>
                            Interval: {modelInterval}
                        </Typography>
                        <br /> */}
                  </Box>

                  <div style={{ height: '12px' }} />

                  <Typography
                    style={{
                      ontSize: "12px",
                      display: "inline-flex",
                      justifyContent: "center",
                      width: "-webkit-fill-available",
                    }}
                  >
                    Latest Accuracy: {trainingData.lastAccuracy}%
                  </Typography>

                  <div style={{ height: '6px' }} />

                  {(progress < 0 || progress == 100) && (
                    <>
                      <span
                        style={{
                          display: "inline-flex",
                          justifyContent: "center",
                          width: "-webkit-fill-available",
                        }}
                      >
                        <Trailer
                          open={true}
                          behaviour={{ height: 40, delay: 800 }}
                        >
                          {spanArray(
                            spanArrayStringEqualSplit(
                              `Your model is ready for ${modelBinary ? 're' : ''}training.`,
                              9
                            ),
                            { fontSize: "12px" }
                          )}
                        </Trailer>
                      </span>

                      {/* <Typography style={{ fontSize: '14px' }}>Your model is ready for training.</Typography> */}
                      <br />
                      <Grid container >

                        <Grid xs={2 / 3 * 12}>
                          <Button
                            className="buttonexotic"
                            style={{
                              ...buttonStyle,
                              // background: 'teal',
                              color: "firebrick",
                              borderWidth: "revert",
                              borderColor: "firebrick",
                              borderStyle: "solid",
                              width: '98%',
                              height: '40px',
                              margin: 0,
                              padding: 0
                              // width: "270px",
                            }}
                            onClick={async () => {
                              function delay(milliseconds) {
                                return new Promise(resolve => {
                                  setTimeout(resolve, milliseconds);
                                });
                              }

                              setFocusNode(null);
                              setProgress(0.2)
                              saveModelBinary(null)

                              await delay(200);

                              // try {

                              // tf.ENV.set('WEBGL_FORCE_CPU_FORWARD', true)
                              // tf.env().set('WEBGL_FORCE_CPU_FORWARD', true)
                              // console.log(tf.engine().registry);

                              console.log('CurrentModel', currentModel)


                              // tf.setBackend('cpu')

                              // console.log('canvasFetchModelData()')
                              // let fetched = await fetchTrainingData();

                              const modelData = await canvasFetchModelData(
                                currentModel, dataStart, dataEnd, null, 60, aggregators, imports)
                                .then(response => {
                                  setTData(response)
                                  return response
                                })

                              // console.log(modelData)

                              if (modelData?.invalid) {
                                alert(`for range ${dataStart}-${dataEnd}. ` + modelData.invalid + ' find a better data range for training.')
                                setModelProgress(3)
                                setProgress(-1);
                                return
                              }


                              // console.log('urlParams', urlParams)

                              // console.log('compile in model', currentModel.compile)

                              // console.log('training model on data', modelData)
                              const { model, tensors } = await tensorTrainDynamic(
                                modelData,
                                currentModel,
                                {
                                  epochs: currentModel.epochs,
                                  callbacks: {
                                    onTrainBegin: async () => {
                                      setProgress(0);
                                    },
                                    onTrainEnd: async () => {
                                      setProgress(100);
                                      //   console.log(model, order);
                                    },
                                    onEpochEnd: async (epoch, logs) => {
                                      if (epoch % 5 == 0) {
                                        setProgress((epoch / currentModel.epochs) * 100);
                                        updateAccuracy((logs.acc * 100).toFixed(3));
                                        console.log(
                                          `epoch:${epoch} logs:${Object.keys(
                                            logs
                                          )} Train Accuracy: ${logs.acc}`
                                        );
                                      }
                                    },
                                  },
                                },
                                layers,
                                'webgl',
                                trainingData.drops
                              );

                              let newDate = new Date().toLocaleString()
                              console.log(newDate)
                              updateLastTrainedOn(newDate)
                              saveModelBinary(model)
                              updateModel(currentModel, { modelBinary: model })

                              // setInterval(()=> setCurrentModel(canvasModels[currentModel.id]), 200)

                              // Set current model again !!!!!
                              // }
                              // catch (e) {
                              //   alert(e)
                              //   setProgress(-1)
                              // }

                            }}

                          >
                            Fetch Data & Train Model
                          </Button>
                        </Grid>

                        <Grid xs={1 / 3 * 12}>
                          <Button
                            className="buttonexotic"
                            style={{
                              ...buttonStyle, width: '100%', margin: 0,
                              padding: 0, height: '40px',
                            }}
                            onClick={handleBack}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            Reconfigure
                          </Button>
                        </Grid>

                      </Grid>

                      {/* <br/> */}
                      <div style={{ height: '10px' }} />


                      {modelBinary && <Grid xs={12}>
                        <DButton
                          // className="buttonexotic"
                          style={{
                            ...buttonStyle,
                            // background: 'teal',
                            color: "darkcyan",
                            borderWidth: "revert",
                            borderColor: "darkcyan",
                            borderStyle: "solid",
                            width: '100%',
                            height: '40px',
                            margin: 0,
                            padding: 0
                          }}
                          onClick={() => setCanvasMode('analysis')}
                        >
                          MAKE PREDICTIONS
                        </DButton>
                      </Grid>}

                      {/* {modelBinary && <Grid xs={12}>
                        <DButton
                          // className="buttonexotic"
                          style={{
                            ...buttonStyle,
                            // background: 'teal',
                            color: "darkcyan",
                            borderWidth: "revert",
                            borderColor: "darkcyan",
                            borderStyle: "solid",
                            width: '100%',
                            height: '40px',
                            margin: 0,
                            padding: 0
                          }}TButton
                          onClick={() => console.log(currentModel)}
                        >
                          Model data
                        </DButton>
                      </Grid>} */}

                    </>
                  )}

                  {progress >= 0 && progress !== 100 && (
                    <>
                      <br />
                      <BorderLinearProgress
                        variant="determinate"
                        value={progress}
                      />
                    </>
                  )}

                </Paper>
              )}
            </Trailer>
          </div>
        )}
      </div>
    </Box >
  );
}