
import React, { useEffect, useState, useRef, useCallback } from 'react';
// import * as API2 from '../STATES';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
// import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
// import { headStyle, hrStyle, buttonStyle, headStyleInverted, backgroundStyle, lightColor, verticalCenter } from '../../css/styling';


function GradientCircularProgress({ style }) {
    return (
        <React.Fragment>
            <svg style={style}>
                <defs>
                    <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        {/* <stop offset="0%" stopColor="darkcyan" /> */}
                        <stop offset="0%" stopColor="cyan" />
                        {/* <stop offset="20%" stopColor="darkcyan" /> */}
                        {/* <stop offset="60%" stopColor="purple" /> */}
                        <stop offset="100%" stopColor="silver" />
                        {/* <stop offset="100%" stopColor="MidnightBlue" /> */}
                    </linearGradient>
                </defs>
            </svg>
            <CircularProgress size={80} thickness={0.8} sx={{ scale: "-4 0.8", 'svg circle': { stroke: 'url(#my_gradient)' } }} />
            {/* <CircularProgress size={20} thickness={2} sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} /> */}
        </React.Fragment>
    );
}

export default function SplashScreen({ children }) {
    return (<Box style={{
        position: 'absolute', 
        height: '100vh', width: '100vw', 
        paddingTop:'50vh',
        // top: 0, left: 0, 
        background: 'rgba(0, 0, 0, 0.95)', 
        zIndex: 1000, 
        // paddingLeft:'50vw'
        // textAlign: 'center',
        display: 'inline-flex',
        justifyContent: 'center',
        // alignItems: 'center',
        // height: '200px',
        // border: '3px solid green'

        // line-height: 200px;
        // height: 200px;
        // border: 3px solid green;
        // textAlign: 'center'
    }}>
                {/* <img
            src='https://i0.wp.com/digitalbirmingham.co.uk/wp-content/uploads/2023/07/Full-Colour-Spaced.png?resize=1024%2C1024&ssl=1'
            style={{
                // marginTop:'-300px',
                // marginTop:'-80vh',
                position: 'fixed', 
                width: '40vh', 
                top: '25vh',
                // display: 'inline-block',
                // verticalAlign: 'middle',
                // paddingBottom:'300px'
                // left: '50%',
                // msTransform: 'translate(-50%, -50%)',
                // transform: 'translate(-50%, -50%)',
                // objectFit: 'cover', 
                // objectPosition: '-20% 0'
            }}
        /> */}

        <GradientCircularProgress style={{
            position: 'fixed', 
            // paddingTop: '50vh', 
            // width: '20%', top: '50%',
            width: '30vw', 
            top: '0vw',
            // left: '50%',
            // msTransform: 'translate(-50%, -50%)',
            // transform: 'translate(-50%, -50%)',
            // display: 'inline-block',
            // verticalAlign: 'middle'
        }} />

        {children}</Box>)
}