import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { ToggleButtonGroup, ToggleButton, Button, IconButton, Tooltip, duration } from '@mui/material';
import { headStyle, hrStyle, buttonStyle, headStyleInverted, backgroundStyle, lightColor, verticalCenter, colourAccent as defaultColourAccent, colourBackground, colourAccentSecondary, colourAccentDisabled } from '../css/styling';
import ViewInAr from '@mui/icons-material/ViewInAr';
import { BlurCircular } from '@mui/icons-material';
import CycloneIcon from '@mui/icons-material/Cyclone';
import PlaceIcon from '@mui/icons-material/Place';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness5Icon from '@mui/icons-material/Brightness5';
import Brightness3Icon from '@mui/icons-material/Brightness3';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import HexagonIcon from '@mui/icons-material/Hexagon';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import LayersIcon from '@mui/icons-material/Layers';
import { motion, AnimatePresence } from 'framer-motion';

import { useElementOnScreen, Transitioner, Trailer, spanCharacters, spanArray, spanArrayStringEqualSplit, Transvoider } from './dElements/Animations';

import { MapboxOverlay } from '@deck.gl/mapbox';

import Map, { useControl } from 'react-map-gl';
import { SimpleMeshLayer } from '@deck.gl/mesh-layers';

import DeckGL from '@deck.gl/react';
import { HexagonLayer, ArcLayer } from 'deck.gl';

import { AmbientLight, PointLight, DirectionalLight, LightingEffect } from '@deck.gl/core';
import { brightnessContrast } from '@luma.gl/shadertools';
import { PostProcessEffect } from '@deck.gl/core';
import { OBJLoader } from '@loaders.gl/obj';

import Insights from './windows/Insights';
import Datasets from './windows/Datasets';
import Cases from './windows/Cases';
import APIs from './windows/APIS';
import Window from './windows/Window';
import * as API2 from './states';

import { parse } from '@loaders.gl/core';

import { Tile3DLayer } from '@deck.gl/geo-layers';
import { TextLayer, GeoJsonLayer } from '@deck.gl/layers';
import { CubeGeometry, IcoSphereGeometry, SphereGeometry } from '@luma.gl/engine'

import CanvasView from './canvas/CanvasView';
import Contribute from './windows/Contribute';
import Roadmap from './windows/Roadmap';

import AssetInfo from './canvas/AssetInfo';
import Analytics from './canvas/Analytics';
import Draggable from 'react-draggable';
import SettingsIcon from '@mui/icons-material/Settings';
import * as API from './apis/APIWeb';

import { debounce, throttle } from 'throttle-debounce';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileImport from './features/FileImport';

/* eslint-disable import/no-webpack-loader-syntax */
import mapboxgl from 'mapbox-gl';
import Merlin from './merlin/Merlin';

import MerlinInsights from './merlin/MerlinInsights';

import { AssetsCompositeLayer, ImportsGeoJsonCompositeLayer, ImportsCSVCompositeLayer } from './decklayers/Composites';
import { DTooltip } from './dElements/DTooltip';

import TMenuItem from './dElements/TMenuItem';
import { seededRandom } from 'three/src/math/MathUtils.js';
import Aggregator from './canvas/Aggregator';


const LEGACY = false;
const INTERLEAVE = false;  // bug with object lightings as of Tues 2 April
const ENABLE_MAP = true;
const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_APIKEY;


export const COLORS2 = [
    [254, 235, 226],
    [251, 180, 185],
    [247, 104, 161],
    [197, 27, 138],
    [122, 1, 119]
];


// create ambient light source
const ambientLight = new AmbientLight({
    color: [255, 255, 255],
    intensity: 2.0
});

// create point light source
const pointLight = new PointLight({
    color: [255, 255, 255],
    intensity: 1.0,
    // use coordinate system as the same as view state
    position: [52.3765, -1.8036, 1000]
});

// create directional light source
const directionalLight = new DirectionalLight({
    color: [255, 255, 255],
    intensity: 1.0,
    direction: [-3, -9, -1]
});

// create lighting effect with light sources
const lightingEffect = new LightingEffect({
    ambientLight,
    pointLight,
    directionalLight
});

// @ts-ignore
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const postProcessEffect = new PostProcessEffect(brightnessContrast, {
    brightness: 0.05,
    contrast: 0.05
});

const INITIAL_VIEW_STATE = {
    "longitude": -1.9053006641463526,
    "latitude": 52.47992709031189,
    "zoom": 16.030968845856695,
    "pitch": 52.942010970722855,
    "bearing": -59.350466074324345,
}

const INITIAL_VIEW_STATE2 = {
    longitude: -1.90247585,
    latitude: 52.478445165,
    zoom: 12,
    pitch: 30,
    bearing: 0,
    altitude: 1.5
};

const INITIAL_VIEW_STATE3 = {
    "latitude": 52.479931554916924,
    "longitude": -1.9053091746231987,
    "zoom": 17.169927850106813,
    "bearing": -75.34883720930233,
    "pitch": 59.925992698334355,
    "altitude": 1.5,

}

const INITIAL_VIEW_STATE4 =  /// INITIAL_VIEW_STATE4 alternative
{
    "longitude": -1.902935174924096,
    "latitude": 52.479364363542146,
    "zoom": 14.843858745413273,
    "pitch": 49.02115616725596,
    "bearing": -56.91679800750626,
}

const NODE_MATERIAL = {
    ambient: 0.7,  // Increase ambient lighting for a glowing feel
    diffuse: 0.6,
    shininess: 200, // Higher shininess for more reflective, glowy surfaces
    specularColor: [255, 255, 255], // White highlights
    wireframe: true
}

const INITIAL_VIEW_STATE_HOME = INITIAL_VIEW_STATE

export const COLORS = { RTEM: [220, 20, 60], SCOOT: [255, 140, 0], AIRVIRO: [0, 189, 189], AIRQ: [30, 144, 255], DEFAULT: [242, 242, 242], NEXUS: [128, 0, 128], NEXUS2: [255, 87, 51], NEXUS3: [0, 153, 204], NEXUS4: [72, 61, 139], NEXUS5: [0, 255, 255], NEXUS6: [153, 50, 204], NEXUS7: [100, 149, 237], NEXUS8: [127, 0, 255], NEXUS9: [255, 85, 0], NEXUS10: [255, 255, 0] }
// const NEXUSCOLORLIGHT = COLORS.NEXUS9
// const NEXUSCOLORDARK = COLORS.NEXUS9 // nexus8 orange
const ASSETOPACITY = 100

const layerItemStyle = { borderRadius: '1px', float: 'right', marginLeft: '5px', textAlign: 'left', fontSize: '12px', padding: '5px' }


function DeckGLOverlayInterleaved(props) {
    const overlay = useControl(() => new MapboxOverlay({ ...props, interleaved: true }));
    overlay.setProps(props);
    return null;
}

function DeckGLOverlay(props) {
    const overlay = useControl(() => new MapboxOverlay({ ...props, interleaved: false }));
    overlay.setProps(props);
    return null;
}


function Platform({ }) {

    var [date, setDate] = useState(new Date());

    useEffect(() => {
        var timer = setInterval(() => setDate(new Date()), 10000)
        return function cleanup() {
            clearInterval(timer)
        }
    });

    const { colourAccent, borderRadius } = API2.globalStore()
    const { ctxMenuPos, setCtxMenuPos } = API2.ctxMenuStore();
    const deckprops = API2.deckPropsStore();
    const { canvasMode, setCanvasMode, setFocusNode, canvasModels, imports, aggregators,
        focusNode, trainingStage, currentNexFocus, setCurrentNexFocus, setModelProgress, } = API2.aiworkflow();
    const [userSetMapLight, setUserSetMapLight] = useState(null);

    const { light, setLight, page, setPage, windowOpen, setWindowOpen } = API2.globalStore();
    const { init, setInit, collections, setCollections, assets, setAssets, barMessage, merlinWard, setMerlinWard } = API2.globalStore();
    const currentModel = API2.currentModelStore();


    const [mapKey, setMapKey] = useState(`${Math.random()}`);


    const [viewState, setViewState] = useState(INITIAL_VIEW_STATE)
    const debounceSetViewState = throttle(200, setViewState, { noLeading: true })
    const { updateLayersVisibility } = API2.globalStore();
    const { merlinAssist, setMerlinAssist, setCollectionVisibility } = API2.globalStore();
    const { urlParams, setUrlParams, zoomScope, setZoomScope } = API2.globalStore();
    const debounceSetZoomScope = throttle(200, setZoomScope, { noLeading: true })

    const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, lat: null, lon: null });
    const isDragging = useRef(false); // Ref to track dragging state
    const mouseDownPosition = useRef({ x: 0, y: 0 }); // Track initial mouse down position


    const NexusMeshesAI = useMemo(() => {
        let coords = Object.values(canvasModels).map(v => ({ coordinates: v?.coordinates, name: v.name, id: v.id, opacity: currentModel.id === v.id ? 255 : 120, collection: 'NEX_AI', asset_id: v.name }))
        let _meta = coords.reduce((acc, cur) => {
            acc[cur.id] = cur
            return acc
        }, {})
        let inputs = Object.values(canvasModels).map(v =>
            Object.values(v.inputs).map(n => [n, _meta[v.id].coordinates, currentModel.id === v.id ? 255 : 160])
        ).flat()
        let outputs = Object.values(canvasModels).map(v =>
            Object.values(v.outputs).map(n => [n, _meta[v.id].coordinates, currentModel.id === v.id ? 255 : 160])
        ).flat()

        // inputs.forEach(el=>console.log('one obj ',el))
        return { coords, inputs: inputs, outputs: outputs }

    }, [Object.values(canvasModels), currentModel])


    const NexusMeshesImports = useMemo(() => {

        let coords = Object.values(imports).map(v => ({
            ...v,
            // coordinates: v?.coordinates, 
            // name: v.name, 
            // id: v.id, 
            opacity: currentNexFocus === v.id ? 255 : 120,
            // collection: 'NEX_IMPORT', 
            asset_id: v.asset_id,
            // outputs: v.outputs,
            // outputs: v.outputs
        }))

        // let csvCoords = Object.values(csvImports).map(v => ({
        //     ...v,
        //     // coordinates: v?.coordinates, 
        //     // name: v.name, 
        //     // id: v.id, 
        //     opacity: currentNexFocus === v.id ? 255 : 120,
        //     // collection: 'NEX_IMPORT', 
        //     asset_id: v.name,
        //     // outputs: v.outputs,
        //     // outputs: v.outputs
        // }))

        // let geoCoords = Object.values(geojsonImports).map(v => ({
        //     ...v,
        //     // coordinates: v?.coordinates, 
        //     // name: v.name, 
        //     // id: v.id, 
        //     opacity: currentNexFocus === v.id ? 255 : 120,
        //     // collection: 'NEX_IMPORT', 
        //     asset_id: v.name,
        //     // outputs: v.outputs,
        //     // outputs: v.outputs
        // }))

        // let coords = [...csvCoords, ...geoCoords].flat()

        console.log(coords)

        // let _meta = coords.reduce((acc, cur) => {
        //     acc[cur.id] = cur
        //     return acc
        // }, {})

        // inputs.forEach(el=>console.log('one obj ',el))
        return { coords }

    }, [Object.keys(imports).length])


    const NexusMeshesAggregators = useMemo(() => {

        let coords = Object.values(aggregators).map(v => ({
            ...v,
            // coordinates: v?.coordinates, 
            // name: v.name, 
            // id: v.id, 
            opacity: currentNexFocus === v.id ? 255 : 120,
            // collection: 'NEX_IMPORT', 
            asset_id: v.asset_id,
            // outputs: v.outputs,
            // outputs: v.outputs
        }))

        // let csvCoords = Object.values(csvImports).map(v => ({
        //     ...v,
        //     // coordinates: v?.coordinates, 
        //     // name: v.name, 
        //     // id: v.id, 
        //     opacity: currentNexFocus === v.id ? 255 : 120,
        //     // collection: 'NEX_IMPORT', 
        //     asset_id: v.name,
        //     // outputs: v.outputs,
        //     // outputs: v.outputs
        // }))

        // let geoCoords = Object.values(geojsonImports).map(v => ({
        //     ...v,
        //     // coordinates: v?.coordinates, 
        //     // name: v.name, 
        //     // id: v.id, 
        //     opacity: currentNexFocus === v.id ? 255 : 120,
        //     // collection: 'NEX_IMPORT', 
        //     asset_id: v.name,
        //     // outputs: v.outputs,
        //     // outputs: v.outputs
        // }))

        // let coords = [...csvCoords, ...geoCoords].flat()

        console.log(coords)

        // let _meta = coords.reduce((acc, cur) => {
        //     acc[cur.id] = cur
        //     return acc
        // }, {})

        // inputs.forEach(el=>console.log('one obj ',el))
        return { coords }

    }, [Object.keys(aggregators).length])





    const mapRef = useRef();

    const refreshCollections = useCallback(() => {
        console.log('collections changes')
        return API.getCollections(r => {
            setCollections(r)
            // return r
        }).then(j => {

            // POSSIBLE BOTTLENECK
            let newAssets = []

            Object.keys(j).forEach(l => {
                API.getAssets(l,
                    (m) => {
                        let filts = Object.values([...(Object.values(m).map(v => ({ ...Object.values(v) })))][0]).map(v => ({ ...v, collection: l, visible: true }))
                        // console.log('filts', filts)
                        newAssets = [...newAssets, ...filts]
                        // console.log('newAssets', newAssets)
                        setAssets(newAssets)
                    })
            })
            // setTimeout(() => console.log('done fetching collections and assets', assets), 5000)
        })
    }, [])

    useEffect(() => {
        const assets_list = assets?.map(a => ({ ...a, visible: collections[a.collection].visible }))
        setAssets(assets_list)

    }, [JSON.stringify(Object.values(collections).map(x => x.visible))])


    useEffect(() => {
        if (page == "platform") {
            mapRef.current?.flyTo({ ...INITIAL_VIEW_STATE2, center: [INITIAL_VIEW_STATE2.longitude, INITIAL_VIEW_STATE2.latitude], duration: 3000 })
            mapRef.current?.setConfigProperty('basemap', 'showPlaceLabels', true);
            mapRef.current?.setConfigProperty('basemap', 'showRoadLabels', false);
            mapRef.current?.setConfigProperty('basemap', 'showPointOfInterestLabels', false);
            mapRef.current?.setConfigProperty('basemap', 'showTransitLabels', false);
        }

        if (page == "home") {
            setTimeout(() => mapRef.current?.easeTo({ center: [INITIAL_VIEW_STATE3.longitude, INITIAL_VIEW_STATE3.latitude], zoom: INITIAL_VIEW_STATE3.zoom + 0.4, bearing: INITIAL_VIEW_STATE3.bearing - 20, duration: 60000 * 2 }), 800)
        }
    }, [page])



    const assetFiltered = useMemo(() => {
        let showAlways = Object.values(canvasModels)
            .map(x => [...Object.keys(x.inputs), ...Object.keys(x.outputs)]).flat()
            .map(x => x.split('.')[1])

        // console.log(showAlways, assets)

        return assets.filter(x => x.visible || showAlways.includes(x.asset_id))
    }, [assets, collections])

    // useEffect(() => {
    //     if (init === false) {
    //         // loadInfoData("RTEM")
    //         loadInfoData("SCOOT")
    //         loadInfoData("AIRVIRO")
    //         refreshCollections().finally(() => setInit(true))
    //     }
    // }, [init])

    // const sphereMesh = new SphereGeometry({
    //     radius: 20,
    //     nlat: 10,
    //     nlong: 10,
    // });

    const icosphereMesh = new IcoSphereGeometry({});
    const cubeMesh = new CubeGeometry({})

    // const [credits, setCredits] = useState();

    const textLayerProps = {
        getPixelOffset: [0, 25],
        getColor: light === 'dusk' || light === 'night' ? [230, 230, 230, 160] : [20, 20, 20, 240],
        getBackgroundColor: light === 'dusk' || light === 'night' ? [230, 230, 230, 160] : [20, 20, 20, 240],
        getSize: 12,
        getAngle: 0,
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'center',
        background: false,
        getBackgroundColor: [58, 60, 63, 100],
        outlineColor: light === 'dusk' || light === 'night' ? [250, 250, 250, 220] : [0, 0, 0, 0],
        outlineWidth: light === 'dusk' || light === 'night' ? 1 : 0,
        fontSettings: { sdf: true, radius: 12, cutoff: 0.18, smoothing: 0.4 },
        pickable: true
    }




    const NexusMeshLayer_AI = new SimpleMeshLayer({
        id: 'nexus-mesh-layer-ai',
        visible: true,
        data: NexusMeshesAI.coords,
        // data: Object.values(canvasModels).map(v => ({ coordinates: v?.coordinates, name: v.name, id: v.id })),
        // data: NexusMeshes.coords,
        // data: Object.values(canvasModels).map(v => ({ coordinates: v?.coordinates, name: v.name, id: v.id })),
        // texture: 'texture.png',
        // wireframe:true,
        mesh: icosphereMesh,
        loaders: [OBJLoader],
        material: NODE_MATERIAL,
        // mesh: new CubeGeometry(),
        getPosition: d => [d.coordinates[0], d.coordinates[1], deckprops.layers.bPht ? 300 : 90],
        getColor: d => COLORS.NEXUS9,
        getOrientation: d => [0, 0, 0], // middle heading value
        wireframe: false,
        // sizeScale: 1 + (20 - viewState.zoom) * 3,
        sizeScale: 1 + (20 - viewState.zoom) * 7,
        // getScale: d => [1 + 0.2 * d.modelSize, 1 + 0.2 * d.modelSize, 1 + 0.2 * d.modelSize],
        pickable: true,
        autoHighlight: true,
        opacity: 1.0,
        // updateTriggers: {
        //     data: API2.currentModelStore()
        // },
        // onClick: (info, event) => {
        //     // console.log(info, event)
        //     currentModel.setCurrentModel(canvasModels[info.object.id])
        //     return mapRef.current?.flyTo({
        //         center: [...info.object.coordinates, 200],
        //         essential: true,
        //         duration: 2000,
        //         zoom: 16,
        //         pitch: 30,
        //         // ...value
        //         // bearing: viewState.zoom > 12 ? 0 : null

        //     })
        // },
        onClick: (info, event) => {
            currentModel.setCurrentModel(canvasModels[info.object.id])
            setCurrentNexFocus(info.object.id)
            setFocusNodeFly({
                // collection,
                name: [info.object].name,
                node: {
                    ...info.object,
                    meta: {
                        algorithm: canvasModels[info.object.id]?.algorithm.algo,
                        outputs: Object.keys(canvasModels[info.object.id]?.outputs).length,
                        inputs: Object.keys(canvasModels[info.object.id]?.inputs).length,
                        last_Accuracy: canvasModels[info.object.id]?.trainingData.lastAccuracy,
                        status: currentModel?.modelBinary ? "TRAINED" : 'UNTRAINED',
                    },
                    actions: {
                        analytics: () => {
                            console.log('analystics clicked')
                            setCanvasMode('analytics')
                            setFocusNodeFly(null)
                        }
                    },
                },
                longitude: info.object.coordinates[0],
                latitude: info.object.coordinates[1],
                altitude: deckprops.layers.bPht ? 400 : 0,
                hosted: true,
                zoom: 16,
                pitch: 30,
            })
        }
    })

    const NexusMeshWireframeLayer_AI = new SimpleMeshLayer({
        id: 'nexus-wireframe-layer-ai',
        visible: true,
        data: NexusMeshesAI.coords,
        // data: Object.values(canvasModels).map(v => ({ coordinates: v?.coordinates, name: v.name, id: v.id })),
        // data: NexusMeshes.coords,
        // data: Object.values(canvasModels).map(v => ({ coordinates: v?.coordinates, name: v.name, id: v.id })),
        // texture: 'texture.png',
        // wireframe:true,
        mesh: icosphereMesh,
        loaders: [OBJLoader],
        // mesh: new CubeGeometry(),
        getPosition: d => [d.coordinates[0], d.coordinates[1], deckprops.layers.bPht ? 300 : 90],
        getColor: d => [30, 30, 30, focusNode?.name === d ? 255 : ASSETOPACITY],
        getOrientation: d => [0, 0, 0], // middle heading value
        wireframe: true,
        sizeScale: 1 + (20 - viewState.zoom) * 7,
        // getScale: d => [1 + 0.2 * d.modelSize, 1 + 0.2 * d.modelSize, 1 + 0.2 * d.modelSize],
        pickable: false,
        opacity: 0.1,

    })

    const NexusTextLayer_AI = new TextLayer({
        id: 'nexus-text-layer-ai',
        visible: deckprops.layers.labels || viewState.zoom <= 100 ? true : false,
        data: NexusMeshesAI.coords,
        getPosition: d => [d.coordinates[0], d.coordinates[1], deckprops.layers.bPht ? 290 : 80],
        getText: d => 'NEX_AI\n' + d.name,
        ...textLayerProps,
        getColor: d => [textLayerProps.getColor[0], textLayerProps.getColor[0], textLayerProps.getColor[0], Math.min(d.opacity * 1.4, 255)],
        getPixelOffset: [0, 30],
        getSize: 14,
        // background: true,
        // backgroundPadding: [10, 5],
        // ...textHighlightLayerProps
    });




    const NexusInputLinkLayer_AI = new ArcLayer({
        id: 'nexus-ilink-layer-ai',
        data: NexusMeshesAI.inputs,
        // data: Object.keys(currentModel.inputs),
        getTargetPosition: k => [k[0].node.coordinates.long, k[0].node.coordinates.lat, deckprops.layers.bPht ? 210 : 0],
        getSourcePosition: k => [...k[1], deckprops.layers.bPht ? 290 : 80],
        getSourceColor: k => light === 'dusk' || light === 'night' ? [240, 240, 240, k[2]] : [80, 80, 80, 80],
        getTargetColor: k => light === 'dusk' || light === 'night' ? [240, 240, 240, k[2]] : [80, 80, 80, 80],
        getHeight: k => 0.05,
        getWidth: 2,
        pickable: false,
        greatCircle: false,
        numSegments: 20,
        widthMinPixels: 1,
        // getTilt:2
    });

    const NexusOutputLinkLayer_AI = new ArcLayer({
        id: 'nexus-olink-layer-ai',
        data: NexusMeshesAI.outputs,
        getTargetPosition: k => [k[0].node.coordinates.long, k[0].node.coordinates.lat, deckprops.layers.bPht ? 210 : 0],
        getSourcePosition: k => [...k[1], deckprops.layers.bPht ? 290 : 80],
        getSourceColor: k => [...COLORS.NEXUS9, k[2]],
        getTargetColor: k => [...COLORS.NEXUS9, k[2]],
        getHeight: k => 0.15,
        getWidth: 2,
        pickable: false,
        greatCircle: false,
        numSegments: 20,
        widthMinPixels: 1
        // getTilt:-2
    });




    const NexusMeshLayer_Imports = new SimpleMeshLayer({
        id: 'nexus-mesh-layer-imp',
        visible: true,
        data: NexusMeshesImports.coords,
        mesh: icosphereMesh,
        loaders: [OBJLoader],
        material: NODE_MATERIAL,
        getPosition: d => [d.coordinates.long, d.coordinates.lat, deckprops.layers.bPht ? 210 : 0],
        getColor: d => [...COLORS.NEXUS4, currentNexFocus === d.id ? 255 : ASSETOPACITY],
        getOrientation: d => [0, 0, 0], // middle heading value
        wireframe: false,
        // sizeScale: 1 + (20 - viewState.zoom) * 3,
        sizeScale: (1 + (20 - viewState.zoom) * 3) * (deckprops.layers.cubes ? 1 : 0.01),
        // getScale: d => [1 + 0.2 * d.modelSize, 1 + 0.2 * d.modelSize, 1 + 0.2 * d.modelSize],
        pickable: true,
        autoHighlight: true,
        opacity: 1.0,
        updateTriggers: {

        },
        onClick: (info, event) => {
            setCurrentNexFocus(info.object.id)
            let n = {
                // collection,
                name: [info.object].asset_id,
                node: info.object,
                longitude: info.object.coordinates.long,
                latitude: info.object.coordinates.lat,
                altitude: deckprops.layers.bPht ? 400 : 0,
                hosted: true,
                zoom: 16,
                pitch: 30,
            }
            console.log('info object', info.object)
            console.log('node object', n)
            setFocusNodeFly(n)
        }
    })

    const NexusMeshWireframeLayer_Imports = new SimpleMeshLayer({
        id: 'nexus-wireframe-layer-imp',
        visible: true,
        data: NexusMeshesImports.coords,
        mesh: icosphereMesh,
        loaders: [OBJLoader],
        getPosition: d => [d.coordinates.long, d.coordinates.lat, deckprops.layers.bPht ? 210 : 0],
        getColor: d => [30, 30, 30, focusNode?.name === d ? 255 : ASSETOPACITY],
        getOrientation: d => [0, 0, 0], // middle heading value
        wireframe: true,
        sizeScale: (1 + (20 - viewState.zoom) * 3) * (deckprops.layers.cubes ? 1 : 0.01),
        pickable: false,
        opacity: 0.1,

    })

    const NexusTextLayer_Imports = new TextLayer({
        id: 'nexus-text-layer-imp',
        visible: deckprops.layers.labels || viewState.zoom <= 100 ? true : false,
        data: NexusMeshesImports.coords,
        getPosition: d => [d.coordinates.long, d.coordinates.lat, deckprops.layers.bPht ? 210 : 0],
        getText: d => `NEX_IMPORT [${d.fileType.replace('.', '').toUpperCase()}]\n` + d.asset_id,
        ...textLayerProps,
        getColor: d => [textLayerProps.getColor[0], textLayerProps.getColor[0], textLayerProps.getColor[0], Math.min(d.opacity * 1.4, 255)],
        getPixelOffset: [0, 30],
        getSize: 14
    });




    const NexusMeshLayer_Aggregators = new SimpleMeshLayer({
        id: 'nexus-mesh-layer-agg',
        visible: true,
        data: NexusMeshesAggregators.coords,
        mesh: icosphereMesh,
        loaders: [OBJLoader],
        material: NODE_MATERIAL,
        getPosition: d => [d.coordinates.long, d.coordinates.lat, deckprops.layers.bPht ? 210 : 0],
        getColor: d => [...COLORS.NEXUS8, currentNexFocus === d.id ? 255 : ASSETOPACITY],
        getOrientation: d => [0, 0, 0], // middle heading value
        wireframe: false,
        sizeScale: (1 + (20 - viewState.zoom) * 3) * (deckprops.layers.cubes ? 1 : 0.01),
        pickable: true,
        autoHighlight: true,
        opacity: 1.0,
        updateTriggers: {

        },
        onClick: (info, event) => {
            setCurrentNexFocus(info.object.id)
            console.log(info.object.id)
            let n = {
                name: info.object.asset_id,
                node: info.object,
                longitude: info.object.coordinates.long,
                latitude: info.object.coordinates.lat,
                altitude: deckprops.layers.bPht ? 400 : 0,
                hosted: true,
                zoom: 16,
                pitch: 30,
            }
            console.log('info object', info.object)
            console.log('node object', n)
            setFocusNodeFly(n)
        }
    })

    const NexusMeshWireframeLayer_Aggregators = new SimpleMeshLayer({
        id: 'nexus-wireframe-layer-agg',
        visible: true,
        data: NexusMeshesAggregators.coords,
        mesh: icosphereMesh,
        loaders: [OBJLoader],
        getPosition: d => [d.coordinates.long, d.coordinates.lat, deckprops.layers.bPht ? 210 : 0],
        getColor: d => [30, 30, 30, currentNexFocus === d ? 255 : ASSETOPACITY],
        getOrientation: d => [0, 0, 0], // middle heading value
        wireframe: true,
        sizeScale: (1 + (20 - viewState.zoom) * 3) * (deckprops.layers.cubes ? 1 : 0.01),
        pickable: false,
        opacity: 0.1
    })

    const NexusTextLayer_Aggregators = new TextLayer({
        id: 'nexus-text-layer-agg',
        visible: deckprops.layers.labels || viewState.zoom <= 100 ? true : false,
        data: NexusMeshesAggregators.coords,
        getPosition: d => [d.coordinates.long, d.coordinates.lat, deckprops.layers.bPht ? 210 : 0],
        getText: d => `NEX_AGGREGATOR\n` + d.asset_id,
        ...textLayerProps,
        getColor: d => [textLayerProps.getColor[0], textLayerProps.getColor[0], textLayerProps.getColor[0], Math.min(d.opacity * 1.4, 255)],
        getPixelOffset: [0, 30],
        getSize: 14
    });




    const MerlinWardMeshLayer = new SimpleMeshLayer({
        id: 'merlin-layer',
        visible: true,
        data: [merlinWard],
        // texture: 'texture.png',
        mesh: icosphereMesh,
        loaders: [OBJLoader],
        material: NODE_MATERIAL,
        // mesh: new CubeGeometry(),
        getPosition: d => [d.coordinates[0], d.coordinates[1], 130],
        getColor: d => [65, 105, 225, 255],
        getOrientation: d => [0, 0, 0], // middle heading value
        wireframe: false,
        sizeScale: 1 + (20 - viewState.zoom) * 10,
        // getScale: d => [1 + 0.2 * d.modelSize, 1 + 0.2 * d.modelSize, 1 + 0.2 * d.modelSize],
        pickable: true,
        autoHighlight: true,
        opacity: 1.0,
        // updateTriggers: {
        //     data: API2.currentModelStore()
        // },
        // onClick: (info, event) => mapRef.current?.flyTo({
        //     center: [...currentModel.coordinates, 200],
        //     essential: true,
        //     duration: 2000,
        //     zoom: 16,
        //     pitch: 30,
        //     // ...value
        //     // bearing: viewState.zoom > 12 ? 0 : null
        // })
    })

    const MerlinWardTextLayer = new TextLayer({
        id: 'merlin-ward-text-layer',
        visible: true,
        data: [merlinWard],
        getPosition: d => [d.coordinates[0], d.coordinates[1], 120],
        getText: d => "Merlin's Ward",
        ...textLayerProps,
        getPixelOffset: [0, 30],
        getSize: 14,
        // background: true,
        // backgroundPadding: [10, 5]
    });


    const PointerMeshLayer = new SimpleMeshLayer({
        id: 'pointer-mesh-layer',
        visible: true,
        data: [contextMenu],
        mesh: icosphereMesh,
        loaders: [OBJLoader],
        material: NODE_MATERIAL,
        getPosition: d => [d.lon, d.lat, deckprops.layers.bPht ? 230 : 20],
        getColor: d => [255, 255, 255],
        getOrientation: d => [0, 0, 0], // middle heading value
        wireframe: false,
        sizeScale: 1 + (20 - viewState.zoom) * 3,
        opacity: 0.2
    })

    const PointerWireframeMeshLayer = new SimpleMeshLayer({
        id: `pointer-wireframe-layer`,
        data: [contextMenu],
        mesh: deckprops.layers.cubes ? icosphereMesh : parse(COIL, OBJLoader),
        loaders: [OBJLoader],
        getPosition: d => [d.lon, d.lat, deckprops.layers.bPht ? 230 : 20],
        getColor: d => [0, 0, 0],
        getOrientation: d => [0, 0, 90], // middle heading value
        wireframe: true,
        sizeScale: 1 + (20 - viewState.zoom) * 3,
        opacity: 0.1
    })




    const AssetCompositeLayer = new AssetsCompositeLayer({
        data: assetFiltered,
        elevation: deckprops.layers.bPht ? 210 : 0,
        sizeScale: (1 + (20 - viewState.zoom) * 3) * (deckprops.layers.cubes ? 1 : 0.01),
        darkMode: light === 'dusk' || light === 'night' ? true : false,
        labels: page !== 'home' && (deckprops.layers.labels || viewState.zoom > 14) ? true : false,
        collectionLabel: viewState.zoom >= 16,
        colors: {
            'TRAFFIC_RTEM': COLORS.RTEM,
            'AQ_AIRVIRO': COLORS.AIRVIRO,
            'AQ_AIRLY': COLORS.AIRVIRO,
            'WIREFRAME': [30, 30, 30]
        },
        darkColors: {
            'TRAFFIC_RTEM': COLORS.RTEM,
            'AQ_AIRVIRO': COLORS.AIRVIRO,
            'AQ_AIRLY': COLORS.AIRVIRO,
            'WIREFRAME': [120, 120, 120]
        },
        focusNode: focusNode,
        onClick: (object) => {
            console.log('clicked focus node', object)
            setFocusNodeFly({
                name: object.asset_id,
                node: object,
                longitude: object?.coordinates?.long,
                latitude: object?.coordinates?.lat,
                altitude: deckprops.layers.bPht ? 400 : 0,
                hosted: true,
                zoom: viewState.zoom > 17 ? viewState.zoom : 17,
                pitch: 45,
            })
        }
    })

    const GeoJsonCompositeLayer = new ImportsGeoJsonCompositeLayer({
        id: 'geojson-composite-layer',
        imports,
        scope: zoomScope,
        focus: currentNexFocus,
        onClick: (object) => {
            console.log(object)
            if (object) {
                const properties = object.properties;
                alert(`Polygon clicked: ${JSON.stringify(properties, null, 2)}`);
            }
        }
    })




    // const GeoJsonImportLayer = Object.keys(imports).map((key) =>
    //     imports[key].visible ? new GeoJsonLayer({
    //         id: `geojson-layer-${key}`,
    //         data: imports[key]?.data?.geojson,
    //         opacity: 0.8,
    //         stroked: false,
    //         filled: true,
    //         extruded: true,
    //         wireframe: true,
    //         getElevation: f => 50,
    //         // getFillColor: f => [255, 140, 0, 180],
    //         // getLineColor: [255, 255, 255],
    //         getFillColor: f => [Math.random() * 255, Math.random() * 255, Math.random() * 255, 120],
    //         getLineColor: [0, 0, 0, 255],
    //         pickable: true,
    //         onClick: (info, event) => {
    //             if (info && info.object) {
    //                 const properties = info.object.properties;
    //                 // Perform any action you want here, such as highlighting the polygon, showing details, etc.
    //                 alert(`[${imports[key].asset_id}] Polygon clicked: ${JSON.stringify(properties, null, 2)}`);
    //             }
    //         }
    //     }) : null
    // ).filter(layer => layer !== null);

    // const getPolygonCenter = (feature) => {
    //     const coordinates = feature.geometry.coordinates[0];
    //     const x = coordinates.reduce((sum, coord) => sum + coord[0], 0) / coordinates.length;
    //     const y = coordinates.reduce((sum, coord) => sum + coord[1], 0) / coordinates.length;
    //     return [x, y];
    // };


    // // Create a TextLayer for labels
    // const GeoJsonImportTextLayer = new TextLayer({
    //     id: 'geojson-layer-text',
    //     data: Object.keys(imports).flatMap((key) =>
    //         imports[key].visible
    //             ? imports[key].data.geojson.features.map((feature) => ({
    //                 position: getPolygonCenter(feature), // Position text at the center of each polygon
    //                 text: feature.properties.name || imports[key].asset_id || 'Polygon' // Label text
    //             }))
    //             : []
    //     ),
    //     getPosition: d => d.position,
    //     getText: d => d.text,
    //     getSize: 16,
    //     getColor: [0, 0, 0, 255],
    //     getTextAnchor: 'middle',
    //     getAlignmentBaseline: 'center',
    //     pickable: false
    // });





    // const GeoJsonImportLayer = Object.keys(imports).map(id => {
    //     // if (imports[id].fileType === '.geojson'
    //     // && imports[id].scope === zoomScope

    //     // function getRandomColor() {
    //     //     var letters = '0123456789ABCDEF';
    //     //     var color = '#';
    //     //     for (var i = 0; i < 6; i++) {
    //     //       color += letters[Math.floor(Math.random() * 16)];
    //     //     }
    //     //     return color;
    //     //   }
    //     // )

    //     return new GeoJsonLayer({
    //         id: `geojson-layer-${id}`,
    //         data: imports[id].visible || currentNexFocus === id ? imports[id]?.data?.geojson : {}, // GeoJSON object
    //         opacity: 0.6,
    //         stroked: true,
    //         filled: true,
    //         extruded: false,
    //         wireframe: true,
    //         // getElevation: f => Math.sqrt(f.properties.valuePerSqm) * 10,
    //         // getFillColor: f => COLOR_SCALE(f.properties.growth),
    //         getFillColor: f => [Math.random() * 255, Math.random() * 255, Math.random() * 255, 120],
    //         getLineColor: [0, 0, 0, 255],
    //         pickable: true,
    //         updateTriggers: {
    //             data: [currentNexFocus, imports[id].visible],
    //         }
    //     })
    // });

    // const GeoJsonImportLayer2 = new GeoJsonImportsLayer({
    //     data: Object.values(geojsonImports),
    //     elevation: deckprops.layers.bPht ? 210 : 0,
    //     sizeScale: (1 + (20 - viewState.zoom) * 3) * (deckprops.layers.cubes ? 1 : 0.01),
    //     darkMode: light === 'dusk' || light === 'night' ? true : false,
    //     labels: page !== 'home' && (deckprops.layers.labels || viewState.zoom > 14) ? true : false,
    //     collectionLabel: viewState.zoom >= 16,
    //     colors: {
    //         'TRAFFIC_RTEM': COLORS.RTEM,
    //         'AQ_AIRVIRO': COLORS.AIRVIRO,
    //         'AQ_AIRLY': COLORS.AIRVIRO,
    //         'WIREFRAME': [30, 30, 30]
    //     },
    //     darkColors: {
    //         'TRAFFIC_RTEM': COLORS.RTEM,
    //         'AQ_AIRVIRO': COLORS.AIRVIRO,
    //         'AQ_AIRLY': COLORS.AIRVIRO,
    //         'WIREFRAME': [120, 120, 120]
    //     },
    //     focusNode: focusNode,
    //     // onClick: (object) => {
    //     //     console.log('clicked focus node', object)
    //     //     setFocusNodeFly({
    //     //         name: object.asset_id,
    //     //         node: object,
    //     //         longitude: object?.coordinates?.long,
    //     //         latitude: object?.coordinates?.lat,
    //     //         altitude: deckprops.layers.bPht ? 400 : 0,
    //     //         hosted: true,
    //     //         zoom: viewState.zoom > 17 ? viewState.zoom : 17,
    //     //         pitch: 45,
    //     //     })
    //     // }
    // })


    const goog3dtiles = new Tile3DLayer({
        id: 'google-3d-tiles',
        // data: TILESET_URL,
        data: "https://tile.googleapis.com/v1/3dtiles/root.json?key=" + process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        visible: deckprops.layers.bPht,
        onTilesetLoad: tileset3d => {
            tileset3d.options.onTraversalComplete = selectedTiles => {
                const uniqueCredits = new Set();
                selectedTiles.forEach(tile => {
                    const { copyright } = tile.content.gltf.asset;
                    copyright.split(';').forEach(uniqueCredits.add, uniqueCredits);
                });
                // setCredits([...uniqueCredits].join('; '));
                return selectedTiles;
            };
        },
        loadOptions: {
            fetch: { headers: { 'X-GOOG-API-KEY': GOOGLE_MAPS_API_KEY } }
        },
        operation: 'terrain+draw'
    })



    const handleMouseDown = (event) => {
        const originalEvent = event.originalEvent;

        if (originalEvent.button === 2) {
            isDragging.current = false; // Reset dragging state for right-click
            mouseDownPosition.current = { x: originalEvent.clientX, y: originalEvent.clientY }; // Store the initial position for right-click
        } else if (originalEvent.button === 0) {
            isDragging.current = false; // Reset dragging state for left-click
            setContextMenu({ visible: false, x: 0, y: 0, lat: null, lon: null }); // Hide the context menu when left-click is pressed
            // console.log('mouse down ctx set')
        }

        // console.log('mouse down')
    };

    const handleMouseUp = (event) => {
        const originalEvent = event.originalEvent;

        if (originalEvent.button === 2) { // Right-click
            const { lngLat } = event

            // console.log(lngLat)

            const distance = Math.sqrt(
                Math.pow(originalEvent.clientX - mouseDownPosition.current.x, 2) +
                Math.pow(originalEvent.clientY - mouseDownPosition.current.y, 2)
            );

            if (distance < 5) { // Only show the menu if the mouse didn't move significantly
                event.preventDefault(); // Prevent default context menu
                let x = {
                    visible: true,
                    x: originalEvent.clientX,
                    y: originalEvent.clientY,
                    lat: lngLat.lat, // Latitude
                    lon: lngLat.lng, // Longitude
                }
                setContextMenu(x);
                // console.log('mouse up ctx set', x)
            }
        }

        // console.log(Object.keys(imports).map(x => imports[x]?.data?.raw))
        // console.log(g)

        event.preventDefault()
    };

    const handleMouseMove = (event) => {
        const originalEvent = event.originalEvent;

        if (originalEvent.buttons === 1 || originalEvent.buttons === 2) { // Detect if the left button is held during movement
            isDragging.current = true; // User is dragging with left-click
            setContextMenu({ visible: false, x: 0, y: 0, lat: null, lon: null }); // Hide the context menu when dragging starts
            console.log('mouse move ctx set')
        }
    };



    const hexRadius = (zoom) => {
        switch (zoom) {
            case 'local': return 700;
            case 'regional': return 3000;
            case 'national': return 15000;
        }
    }

    const hexlayer = new HexagonLayer({
        id: 'HexagonLayer',
        data: assetFiltered,
        extruded: false,
        colorRange: light === 'dusk' || light === 'night' ? [COLORS.NEXUS3] : [COLORS.NEXUS3],
        getPosition: d => [d?.coordinates?.long, d?.coordinates?.lat],
        getColorWeight: d => 1,
        getElevationWeight: d => 1,
        elevationScale: 1,
        radius: hexRadius(zoomScope),
        pickable: false,
        opacity: Math.min(54688.752 * Math.exp(-0.8 * (viewState.zoom + 7)), 0.3),//54688.752 * Math.exp(Math.E, -0.928 * viewState.zoom)
        updateTriggers: {
            getPosition: [viewState.zoom],
            data: [viewState.zoom]
        }
    });






    const [draggableStates, setDraggableStates] = useState({
        activeDrags: 0,
        deltaPosition: {
            x: 0, y: 0
        },
        controlledPosition: {
            x: -400, y: 200
        }
    })

    const onStart = () => {
        setDraggableStates({ activeDrags: ++draggableStates.activeDrags });
    };

    const onStop = () => {
        setDraggableStates({ activeDrags: --draggableStates.activeDrags });
    };

    const dragHandlers = { onStart, onStop };

    const setMapLight = (light) => {
        const mode = (light) => {
            switch (light) {
                case 'dawn':
                    return {
                        "range": [0.8, 8],
                        "color": "#dc9f9f",
                        "horizon-blend": 0.15,
                        // "high-color": "#8ec7e8",
                        "high-color": "#245bde",
                        "space-color": "#000000",
                        "star-intensity": 0.25
                    }
                case 'day':
                    return {
                        "star-intensity": 0.05
                    }
                case 'dusk':
                    return {
                        "range": [0.8, 8],
                        "color": "#dc9f9f",
                        "horizon-blend": 0.2,
                        "high-color": "#245bde",
                        "space-color": "#000000",
                        "star-intensity": 0.45
                    }
                case 'night':
                    return {
                        "range": [0.8, 8],
                        "color": "#001d3b",
                        "horizon-blend": 0.03,
                        "high-color": "#245bde",
                        "space-color": "#000000",
                        "star-intensity": 0.65
                    }
                default:
                    return {
                    }
            }
        }
        setLight(light)
        setFog(mode(light))
        if (light !== mapRef.current?.getConfigProperty('basemap', 'lightPreset')) {
            mapRef.current?.setConfigProperty('basemap', 'lightPreset', light)
            mapRef.current?.getMap().setFog(mode(light))
        }
    }

    const debounceSetMapLight = debounce(200, setMapLight)


    const onMapLoad = useCallback(() => {
        mapRef.current.once('load', () => {
            debounceSetMapLight('night');
            mapRef.current?.setConfigProperty('basemap', 'showPlaceLabels', false);
            mapRef.current?.setConfigProperty('basemap', 'showRoadLabels', false);
            mapRef.current?.setConfigProperty('basemap', 'showPointOfInterestLabels', false);
            mapRef.current?.setConfigProperty('basemap', 'showTransitLabels', false);
        })

        mapRef.current.once('load', () => {
            if (init === false) {
                refreshCollections().finally(() =>
                    setTimeout(() => setInit(true), 600))
            }
        })

    }, [])


    const setFocusNodeFly = (value) => {
        // setCanvasMode(false)

        !value && setCurrentNexFocus(null)

        setFocusNode(value)
        value && mapRef.current?.easeTo({
            center: [value.longitude, value.latitude, deckprops.layers.bPht ? 200 : 0],
            essential: true,
            duration: 2000,
            zoom: 16,
            pitch: 35,
            ...value
        })
    }

    const [fog, setFog] = useState({
        "range": [0.8, 8],
        "color": "#dc9f9f",
        "horizon-blend": 0.2,
        "high-color": "#245bde",
        "space-color": "#000000",
        "star-intensity": 0.45
    })


    const [hexCoverage, setHexCoverage] = useState(true)

    let isHovering = false;

    useEffect(() => {
        const mapElement = document.querySelector('.mapboxgl-canvas');

        if (mapElement) {
            // Add an event listener to prevent the default browser context menu
            mapElement.addEventListener('contextmenu', (event) => {
                event.preventDefault(); // Prevent default context menu
            });
        }

        // Clean up the event listener when the component is unmounted
        return () => {
            if (mapElement) {
                mapElement.removeEventListener('contextmenu', (event) => {
                    event.preventDefault(); // Clean up to avoid memory leaks
                });
            }
        };
    }, []); // Run this effect only once after the component mounts

    return <div onClick={() => setCtxMenuPos([0, 0])}>

        {/* USER LOGO */}
        <div style={{ display: page === 'platform' && init ? 'block' : 'none' }}>

            <DTooltip title="Login Account">
                <IconButton style={{
                    ...verticalCenter,
                    ...backgroundStyle,
                    border: 'solid',
                    // color: lightColor.backgroundColor,
                    borderRadius: '50%',
                    borderWidth: 'medium',
                    // marginRight: '0px',
                    top: '5px',
                    height: '75px',
                    width: '75px',
                    position: 'absolute',
                    right: '45px',
                    transform: '',
                    background: 'rgba(97,97,97,0.98)',
                    zIndex: 50,
                    // color: 'rgb(255 255 255 / 98%)',
                    color: colourAccent
                }} onClick={() => { }} >
                    {/* <FullscreenIcon /> */}
                    <Diversity2Icon style={{ color: lightColor.backgroundColor, transform: 'scale(1.4)' }} />
                </IconButton>
            </DTooltip>

            {/* TOPBAR */}
            <div style={{ ...backgroundStyle, width: '100vw', height: '40px', zIndex: 40 }}>

                <DTooltip title="Settings">
                    <IconButton
                        style={{
                            ...verticalCenter, color: lightColor.backgroundColor, borderRadius: '1px', float: 'right', marginLeft: '5px', marginRight: '5px'
                        }}
                        onClick={() => {
                            deckprops.update({ controls: !deckprops.controls })
                        }}
                    >
                        <SettingsIcon />
                    </IconButton>
                </DTooltip>

                <IconButton style={{
                    ...verticalCenter, color: 'rgba(255,255,255,0.8)', borderRadius: '1px',
                    float: 'right', marginLeft: '5px', marginRight: '85px', fontSize: 14
                }}  >
                    {/* {JSON.stringify(Object.keys(assets["traffic_rtem"]), null, 2)} */}
                    COMMUNITY USER
                </IconButton>



                {LEGACY ? <DTooltip title="legacy Demo">
                    <IconButton style={{ ...verticalCenter, color: lightColor.backgroundColor, borderRadius: '1px', float: 'right', marginLeft: '5px' }} onClick={() => setPage('legacy')} >
                        <BlurCircular />
                    </IconButton>
                </DTooltip> : null}

                <DTooltip title="Homepage">
                    <IconButton style={{
                        ...verticalCenter, color: lightColor.backgroundColor, borderRadius: '1px', float: 'left', paddingLeft: '25px', paddingRight: '25px',
                    }} onClick={() => {
                        setPage('home')
                        setWindowOpen(null)
                        setMapLight('night');
                        mapRef.current?.setConfigProperty('basemap', 'showPlaceLabels', false);
                        mapRef.current?.setConfigProperty('basemap', 'showRoadLabels', false);
                        mapRef.current?.setConfigProperty('basemap', 'showPointOfInterestLabels', false);
                        mapRef.current?.setConfigProperty('basemap', 'showTransitLabels', false);
                        // mapRef.current?.flyTo({ ...INITIAL_VIEW_STATE, center: [INITIAL_VIEW_STATE.longitude, INITIAL_VIEW_STATE.latitude] , duration:0})
                        mapRef.current?.easeTo({ ...INITIAL_VIEW_STATE_HOME, center: [INITIAL_VIEW_STATE_HOME.longitude, INITIAL_VIEW_STATE_HOME.latitude] })
                    }} >
                        {/* <HomeIcon /> */}
                        DIATOMIC
                    </IconButton>
                </DTooltip>


                <div syle={{
                    width: '100vw',
                    textAlign: 'center',
                    top: '16px',
                    fontWeight: 'bold',
                    fontSize: 'large'
                }}>{barMessage}</div>

                {/* <DTooltip title="Datasets and Collections"> */}
                <IconButton style={{ fontSize: '14px', ...verticalCenter, color: windowOpen === 'datasets' ? colourAccent : lightColor.backgroundColor, borderRadius: '1px', float: 'left' }} onClick={() => setWindowOpen('datasets')}>
                    {/* <DatasetIcon /> */}
                    Datasets
                </IconButton>
                {/* </DTooltip> */}

                {/* <DTooltip title="APIs">
                    <IconButton style={{ fontSize: '14px', ...verticalCenter, color: windowOpen === "apis" ? colourAccent : lightColor.backgroundColor, borderRadius: '1px', float: 'left' }} onClick={() => setWindowOpen('apis')}>
                        <ApiIcon />
                        API Services
                    </IconButton>
                </DTooltip> */}

                {/* <DTooltip title="Case Studies"> */}
                <IconButton style={{ fontSize: '14px', ...verticalCenter, color: windowOpen === "cases" ? colourAccent : lightColor.backgroundColor, borderRadius: '1px', float: 'left' }} onClick={() => setWindowOpen('cases')}>
                    {/* <InsightsIcon /> */}
                    Twinviews
                    {/* {JSON.stringify(geojsonImports)} */}
                </IconButton>
                {/* </DTooltip> */}

                {/* <DTooltip title="Roadmap"> */}
                <IconButton style={{ fontSize: '14px', ...verticalCenter, color: windowOpen === "roadmap" ? colourAccent : lightColor.backgroundColor, borderRadius: '1px', float: 'right' }} onClick={() => setWindowOpen('roadmap')}>
                    {/* <InsightsIcon /> */}
                    Roadmap
                </IconButton>
                {/* </DTooltip> */}

                {/* <DTooltip title="Contribute"> */}
                <IconButton style={{ fontSize: '14px', ...verticalCenter, color: windowOpen === "contribute" ? colourAccent : lightColor.backgroundColor, borderRadius: '1px', float: 'right' }} onClick={() => setWindowOpen('contribute')}>
                    {/* <InsightsIcon /> */}
                    Contribute
                </IconButton>
                {/* </DTooltip> */}

            </div>

            {/* SETTINGS POPUP - NEED COMPONENTISE*/}
            {!windowOpen && <div style={{ ...backgroundStyle, borderRadius: '10px', right: '0px', top: '70px', width: '250px', zIndex: 400, display: 'inline-grid' }}>
                <div style={{ display: deckprops.controls ? 'inline-grid' : 'none' }}>
                    <IconButton style={{ color: deckprops.layers.bPht ? colourAccent : 'white', ...layerItemStyle }}
                        onClick={() => { deckprops.toggleProps('bPht') }}>
                        Photoreals
                    </IconButton>

                    <IconButton style={{ color: deckprops.layers.labels ? colourAccent : 'white', ...layerItemStyle }}
                        onClick={() => { deckprops.toggleProps('labels') }}>
                        Labels
                    </IconButton>
                </div>
            </div>}

            {false && !windowOpen && <div style={{ ...backgroundStyle, borderBottomRightRadius: '10px', left: '0px', top: '50px', height: '40px', zIndex: 4 }}>

                <DTooltip title="Build ML Models">
                    <IconButton style={{
                        ...verticalCenter, color: canvasMode === 'canvas' ? colourAccent : lightColor.backgroundColor, borderRadius: '1px', float: 'right', marginRight: '5px', fontSize: '12px', width: '100px'
                    }} onClick={() => {
                        setCanvasMode('canvas')
                        setFocusNodeFly(null)
                        mapRef.current?.setConfigProperty('basemap', 'showPlaceLabels', false);
                        mapRef.current?.setConfigProperty('basemap', 'showRoadLabels', false);
                        mapRef.current?.setConfigProperty('basemap', 'showPointOfInterestLabels', false);
                        mapRef.current?.setConfigProperty('basemap', 'showTransitLabels', false);
                    }} >
                        {/* AI */}
                        {/* <CycloneIcon /> */}
                        Build Models
                    </IconButton>
                </DTooltip>

                <DTooltip title="Data Analysis">
                    <IconButton style={{
                        ...verticalCenter, color: canvasMode === 'analytics' || canvasMode === 'analysis' ? colourAccent : lightColor.backgroundColor, borderRadius: '1px', float: 'right', marginRight: '5px', fontSize: '12px', width: '100px'
                    }} onClick={() => {
                        setCanvasMode('analytics')
                        setFocusNodeFly(null)
                    }} >
                        Data Analysis
                    </IconButton>
                </DTooltip>

                <DTooltip title="Explore City View">
                    <IconButton style={{
                        ...verticalCenter, color: !canvasMode ? colourAccent : lightColor.backgroundColor, borderRadius: '1px', float: 'right', marginRight: '5px', fontSize: '12px', width: '100px'
                    }} onClick={() => {
                        setCanvasMode(false)
                        setTimeout(() => {
                            mapRef.current?.setConfigProperty('basemap', 'showPlaceLabels', true);
                            mapRef.current?.setConfigProperty('basemap', 'showRoadLabels', false);
                            mapRef.current?.setConfigProperty('basemap', 'showPointOfInterestLabels', false);
                            mapRef.current?.setConfigProperty('basemap', 'showTransitLabels', false);
                        }, 6000)
                    }} >
                        {/* <ViewInAr /> */}
                        Explore City
                    </IconButton>
                </DTooltip>
            </div>}

            <AnimatePresence>
                {!windowOpen && currentNexFocus === currentModel.id && <motion.div
                    style={{ ...backgroundStyle, borderBottomRightRadius: borderRadius, left: '0px', top: '40px', height: '30px', zIndex: 4, alignContent: 'center', paddingRight: '5px' }}
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1.0 }}
                    exit={{ opacity: 0, scale: 0.6 }}
                    transition={{ duration: 0.1 }}
                >
                    <div style={{ marginLeft: '20px', display: 'inline-flex', paddingTop: '6px' }}>Model: {currentModel?.name}</div>
                    <div style={{ marginLeft: '20px', display: 'inline-flex' }}>Outputs: {Object.keys(currentModel?.outputs).length} </div>
                    <div style={{ marginLeft: '20px', display: 'inline-flex' }}>Algorithm: {currentModel?.algorithm?.algo || 'N/A'} </div>
                    <div style={{ marginLeft: '20px', display: 'inline-flex' }}>Accuracy: {currentModel?.trainingData?.lastAccuracy || 'N/A'}% </div>
                    <div style={{ marginLeft: '20px', display: 'inline-flex' }}>Status: {currentModel?.modelBinary ? "TRAINED" : 'UNTRAINED'} </div>
                    <div style={{ marginLeft: '20px', display: 'inline-flex' }}>Inputs: {Object.keys(currentModel?.inputs).length} </div>
                    {/* <DTooltip title="Data Analysis"> */}
                    <IconButton style={{
                        ...verticalCenter, color: colourAccent, borderRadius: '1px', float: 'right', marginLeft: '5px', marginRight: '5px', fontSize: '12px', width: '60px'
                    }} onClick={() => {
                        setCanvasMode('analytics')
                        setFocusNodeFly(null)

                    }} >
                        {/* Model Analysis */}
                        <AutoGraphIcon />
                    </IconButton>
                    {/* </DTooltip> */}
                    {/* <DTooltip title="Data Analysis"> */}
                    <IconButton style={{
                        ...verticalCenter, color: colourAccent, borderRadius: '1px', float: 'right', marginLeft: '10px', marginRight: '5px', fontSize: '12px', width: '60px'
                    }} onClick={() => {
                        setCanvasMode('canvas')
                        setModelProgress(4);
                        setFocusNodeFly(null)
                    }} >
                        {/* Configure Model */}
                        <SettingsIcon />
                    </IconButton>
                    {/* </DTooltip> */}
                </motion.div>}
            </AnimatePresence>

            {/* SUB TOPBAR */}
            <AnimatePresence>
                {!windowOpen && <motion.div
                    style={{ ...backgroundStyle, borderBottomLeftRadius: borderRadius, right: '0px', top: '40px', height: '32px', zIndex: 4 }}
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1.0 }}
                    exit={{ opacity: 0, scale: 0.6 }}
                    transition={{ duration: 0.1 }}
                >

                    <DTooltip title="City View">
                        <IconButton style={{
                            ...verticalCenter, color: !canvasMode ? colourAccent : lightColor.backgroundColor, borderRadius: '1px', float: 'right', marginLeft: '5px', marginRight: '5px'
                        }} onClick={() => {
                            setCanvasMode(false)
                            setTimeout(() => {
                                mapRef.current?.setConfigProperty('basemap', 'showPlaceLabels', true);
                                mapRef.current?.setConfigProperty('basemap', 'showRoadLabels', false);
                                mapRef.current?.setConfigProperty('basemap', 'showPointOfInterestLabels', false);
                                mapRef.current?.setConfigProperty('basemap', 'showTransitLabels', false);
                            }, 6000)
                        }} >
                            <ViewInAr />
                        </IconButton>
                    </DTooltip>

                    <DTooltip title="Build ML Models">
                        <IconButton style={{
                            ...verticalCenter, color: canvasMode === 'canvas' ? colourAccent : lightColor.backgroundColor, borderRadius: '1px', float: 'right', marginLeft: '5px', marginRight: '85px'
                        }} onClick={() => {
                            setCanvasMode('canvas')
                            setFocusNodeFly(null)
                            mapRef.current?.setConfigProperty('basemap', 'showPlaceLabels', false);
                            mapRef.current?.setConfigProperty('basemap', 'showRoadLabels', false);
                            mapRef.current?.setConfigProperty('basemap', 'showPointOfInterestLabels', false);
                            mapRef.current?.setConfigProperty('basemap', 'showTransitLabels', false);
                        }} >
                            AI
                        </IconButton>
                    </DTooltip>


                    <div style={{
                        color: lightColor.backgroundColor,
                        borderRadius: '1px',
                        float: 'right',
                        marginLeft: '10px',
                        marginRight: '5px',
                        borderLeft: `1px solid white`,
                        height: '100%',
                        opacity: 0.3,
                    }} />


                    <DTooltip title="Night">
                        <IconButton style={{
                            ...verticalCenter, color: lightColor.backgroundColor, borderRadius: '1px', float: 'right', marginLeft: '5px',
                            color: light === 'night' ? colourAccent : colourAccentDisabled// animation: canvasMode?'glow2 1s infinite alternate': null
                        }}
                            onClick={() => {
                                setUserSetMapLight('night')
                                debounceSetMapLight('night');
                            }}>
                            <Brightness1Icon />
                        </IconButton>
                    </DTooltip>

                    <DTooltip title="Dusk">
                        <IconButton style={{
                            ...verticalCenter, color: lightColor.backgroundColor, borderRadius: '1px', float: 'right', marginLeft: '5px',
                            color: light === 'dusk' ? colourAccent : colourAccentDisabled // animation: canvasMode?'glow2 1s infinite alternate': null
                        }}
                            onClick={() => {
                                setUserSetMapLight('dusk')
                                debounceSetMapLight('dusk');
                            }}
                        >
                            <Brightness3Icon />
                        </IconButton>
                    </DTooltip>

                    <IconButton style={{
                        ...verticalCenter, color: 'rgba(255,255,255,0.8)', borderRadius: '1px', float: 'right', marginLeft: '5px', fontSize: 14,
                        width: '80px'
                    }}  >
                        {date.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' })}
                    </IconButton>

                    <DTooltip title="Day">
                        <IconButton style={{
                            ...verticalCenter, color: lightColor.backgroundColor, borderRadius: '1px', float: 'right', marginLeft: '5px',
                            color: light === 'day' ? colourAccent : colourAccentDisabled// animation: canvasMode?'glow2 1s infinite alternate': null
                        }}
                            onClick={() => {
                                setUserSetMapLight('day')
                                debounceSetMapLight('day');
                            }}
                        >
                            <Brightness7Icon />
                        </IconButton>
                    </DTooltip>

                    <DTooltip title="Dawn">
                        <IconButton style={{
                            ...verticalCenter, color: lightColor.backgroundColor, borderRadius: '1px', float: 'right', marginLeft: '5px',
                            color: light === 'dawn' ? colourAccent : colourAccentDisabled// animation: canvasMode?'glow2 1s infinite alternate': null
                        }}
                            onClick={() => {
                                // setLight(dawn)
                                setUserSetMapLight('dawn')
                                debounceSetMapLight('dawn');
                            }}
                        >
                            <Brightness5Icon />
                        </IconButton>
                    </DTooltip>

                </motion.div>}
            </AnimatePresence>

            {/* SIDE BAR */}
            <AnimatePresence>
                {!windowOpen && <motion.div
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1.0 }}
                    exit={{ opacity: 0, scale: 0.6 }}
                    transition={{ duration: 0.1 }}
                    style={{ ...backgroundStyle, position: 'absolute', zIndex: 3, left: 'calc(100vw - 38px)', top: 'calc(70vh - 55px)', borderBottomLeftRadius: '5px', borderTopLeftRadius: '5px', padding: '4px' }}>

                    <DTooltip title="Points of Interests" placement="left">
                        <IconButton style={{
                            ...verticalCenter, color: lightColor.backgroundColor, borderRadius: '1px', transform: ''
                            // animation: canvasMode?'glow2 1s infinite alternate': null
                        }}
                            onClick={() => {
                                mapRef.current?.setConfigProperty('basemap', 'showPlaceLabels', true);
                                mapRef.current?.setConfigProperty('basemap', 'showRoadLabels', true);
                                mapRef.current?.setConfigProperty('basemap', 'showPointOfInterestLabels', true);
                                mapRef.current?.setConfigProperty('basemap', 'showTransitLabels', true);
                            }}
                            onMouseLeave={() => {
                                setTimeout(() => {
                                    mapRef.current?.setConfigProperty('basemap', 'showPlaceLabels', true);
                                    mapRef.current?.setConfigProperty('basemap', 'showRoadLabels', false);
                                    mapRef.current?.setConfigProperty('basemap', 'showPointOfInterestLabels', false);
                                    mapRef.current?.setConfigProperty('basemap', 'showTransitLabels', false);
                                }, (8000));
                            }}
                        >
                            <PlaceIcon />
                        </IconButton>
                    </DTooltip>

                    <DTooltip title="Hex Coverage" placement="left">
                        <IconButton style={{
                            ...verticalCenter, color: lightColor.backgroundColor, borderRadius: '1px', transform: ''
                            // animation: canvasMode?'glow2 1s infinite alternate': null
                        }}
                            onClick={() => { setHexCoverage(!hexCoverage) }}
                        >
                            <HexagonIcon />
                        </IconButton>
                    </DTooltip>

                    {/* <DTooltip title="Import File" placement="left">
                    <IconButton
                        style={{
                            ...verticalCenter, color: lightColor.backgroundColor, borderRadius: '1px', transform: ''
                        }} onClick={() => {
                            setWindowOpen('import')
                        }} >
                        <FileUploadIcon />
                    </IconButton>
                </DTooltip> */}

                    {urlParams?.merlin && <DTooltip title="Merlin Assist" placement="left">
                        <IconButton
                            style={{
                                ...verticalCenter, color: lightColor.backgroundColor, borderRadius: '1px', transform: ''
                            }} onClick={() => {
                                setMerlinAssist()
                            }} >
                            M
                        </IconButton>
                    </DTooltip>}


                    <DTooltip title="Google Maps Photorealistic" placement="left">
                        <IconButton style={{
                            ...verticalCenter, color: lightColor.backgroundColor, borderRadius: '1px', transform: ''
                        }}
                            onClick={() => { deckprops.toggleProps('bPht') }}>
                            <LayersIcon />
                        </IconButton>
                    </DTooltip>

                    <DTooltip title="Asset Labels" placement="left">
                        <IconButton style={{
                            ...verticalCenter, color: lightColor.backgroundColor, borderRadius: '1px', transform: ''
                        }}
                            onClick={() => { deckprops.toggleProps('labels') }}>
                            <LayersIcon />
                        </IconButton>
                    </DTooltip>
                </motion.div>}
            </AnimatePresence>

            {/* SIDE ZOOM SCOPE BAR */}
            <AnimatePresence>
                {!windowOpen && <motion.div

                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1.0 }}
                    exit={{ opacity: 0, scale: 0.6 }}
                    transition={{ duration: 0.1 }}
                    style={{ ...backgroundStyle, position: 'absolute', zIndex: 3, top: '12.5vh', borderBottomRightRadius: '5px', borderTopRightRadius: '5px', boxShadow: '1px 1px 6px black' }}>
                    <div style={{ height: '25vh', width: '20px', backgroundColor: zoomScope === 'local' ? 'darkcyan' : 'transparent', borderTopRightRadius: '2px' }} >
                        <p className="transform rotate-180 text-center" style={{ writingMode: 'vertical-rl', height: '100%', cursor: 'default' }}>
                            Local
                        </p>
                    </div>
                    <div style={{ height: '25vh', width: '20px', backgroundColor: zoomScope === 'regional' ? 'darkcyan' : 'transparent', }} >
                        <p className="transform rotate-180 text-center" style={{ writingMode: 'vertical-rl', height: '100%', cursor: 'default' }}>
                            Regional
                        </p>
                    </div>
                    <div style={{ height: '25vh', width: '20px', backgroundColor: zoomScope === 'national' ? 'darkcyan' : 'transparent', borderBottomRightRadius: '2px' }} >
                        <p className="transform rotate-180 text-center" style={{ writingMode: 'vertical-rl', height: '100%', cursor: 'default' }}>
                            National
                        </p>
                    </div>
                </motion.div>}
            </AnimatePresence>



            {/* <Transitioner open={canvasMode === 'infer' && page === 'platform' && trainingStage === null && !windowOpen} springConfig={{ mass: 5, tension: 2000, friction: 200 }}>
                <InferenceView style={{ zIndex: 4, position: 'absolute', top: '50px' }} />
            </Transitioner> */}

            <Transitioner open={canvasMode === 'canvas' && page === 'platform' && trainingStage === null && !windowOpen} springConfig={{ mass: 5, tension: 2000, friction: 200 }} >
                <Draggable {...dragHandlers} positionOffset={{ x: 'calc(100vw - 580px)', y: '100px' }}>
                    <div  >
                        <CanvasView
                            style={{ zIndex: 4 }}
                            focusCb={(collection, node) => {

                                let n = {
                                    // collection,
                                    name: node.name,
                                    node: {
                                        ...node,
                                        collection: collection,
                                        // meta: {
                                        //     ...node.meta
                                        // },
                                        // actions: {
                                        //     // analytics: () => {
                                        //     //     console.log('analystics clicked')
                                        //     //     setCanvasMode('analytics')
                                        //     //     setFocusNodeFly(null)
                                        //     // }
                                        // },
                                    },
                                    longitude: Array.isArray(node.coordinates) ? node.coordinates[0] : node.coordinates.long,
                                    latitude: Array.isArray(node.coordinates) ? node.coordinates[1] : node.coordinates.lat,
                                    altitude: deckprops.layers.bPht ? 400 : 0,
                                    hosted: true,
                                    zoom: 16,
                                    pitch: 30,
                                }

                                console.log('node object', n)

                                setFocusNodeFly(n)
                            }} />
                    </div>
                </Draggable>
            </Transitioner>

            <Transitioner open={focusNode && !windowOpen} springConfig={{ mass: 5, tension: 2000, friction: 200 }}>
                <Draggable {...dragHandlers} positionOffset={{ x: 'calc(30px)', y: '100px' }}>
                    <div  >
                        <AssetInfo style={{ zIndex: 5, width: 380 }} {...focusNode} canvasMode={canvasMode} focusCb={(n) => mapRef.current?.easeTo(n)} />
                    </div>
                </Draggable>
            </Transitioner>

            <Transitioner open={canvasMode === 'analytics' || canvasMode === 'analysis' && !windowOpen} springConfig={{ mass: 5, tension: 2000, friction: 200 }}>
                <Draggable {...dragHandlers} positionOffset={{ x: 'calc((100vw - 1200px) / 2)', y: '100px' }}>
                    <div  >
                        <Analytics style={{ zIndex: 5, width: 600 }} {...focusNode} canvasMode={canvasMode} focusCb={(n) => mapRef.current?.easeTo(n)} />
                    </div>
                </Draggable>
            </Transitioner>

            <Transitioner open={canvasMode === 'aggregator' || canvasMode === 'aggregator' && !windowOpen} springConfig={{ mass: 5, tension: 2000, friction: 200 }}>
                <Draggable {...dragHandlers} positionOffset={{ x: 'calc((100vw - 1200px) / 2)', y: '100px' }}>
                    <div  >
                        <Aggregator style={{ zIndex: 5, width: 600 }} {...focusNode} canvasMode={canvasMode} focusCb={(n) => mapRef.current?.easeTo(n)} assetFiltered={assetFiltered} lat={contextMenu.lat} lon={contextMenu.lon} />
                    </div>
                </Draggable>
            </Transitioner>

            {/* <Transitioner open={!canvasMode && urlParams?.merlin && !windowOpen} springConfig={{ mass: 5, tension: 2000, friction: 200 }}>
                <Draggable {...dragHandlers} positionOffset={{ x: 'calc(100vw - 580px)', y: '100px' }}>
                    <div  >
                        <MerlinInsights />
                    </div>
                </Draggable>
            </Transitioner> */}

            <Transitioner open={merlinAssist && !windowOpen} springConfig={{ mass: 5, tension: 2000, friction: 200 }}>
                <Draggable {...dragHandlers} positionOffset={{ x: 'calc(10px)', y: '60px' }}>
                    <div  >
                        <Merlin style={{
                            zIndex: 5, position: 'absolute',
                            borderRadius: '8px',
                            maxWidth: '400px',
                            maxHeight: 'min(calc(100vh - 167px), 800px)',
                            minWidth: '330px',
                            padding: '15px',
                            overflowY: 'auto'
                        }}
                            callbacks={{
                                GOTO: (coordinates) => {
                                    setMerlinWard({ coordinates })
                                    mapRef.current?.flyTo({
                                        center: [...coordinates, 200],
                                        essential: true,
                                        duration: 2000,
                                        zoom: 12,
                                        pitch: 30,
                                        bearing: 0
                                    })
                                },
                                CLEARVIEW: () => {
                                    console.log('CLEARVIEW')
                                    Object.keys(collections).forEach((k, i) => updateLayersVisibility(k, false))
                                    Object.keys(collections).forEach((k, i) => setCollectionVisibility(k, false))
                                },
                                LIGHTPRESET: (lightmode) => {
                                    debounceSetMapLight(lightmode.toLowerCase());
                                }
                            }} />
                    </div>
                </Draggable>
            </Transitioner>


            {/* WINDOW HANDLES */}
            {/* <div style={{ ...backgroundStyle, zIndex: 4, width: '100vw', left: '0vw', height: 'calc(100vh - 50px)', top: '50px' }}> */}
            <Transvoider open={windowOpen !== null} springConfig={{ mass: 5, tension: 2000, friction: 200 }} >
                <div style={{ ...backgroundStyle, zIndex: 10, width: '100vw', top: '0px', left: '0vw', height: '100vh', padding: '0px', pointerEvents: !windowOpen ? 'none' : 'auto' }}>
                    <Window show={windowOpen === 'datasets'} title={'Datasets'} children={<Datasets />} />
                    <Window show={windowOpen === 'apis'} title={'API Services'} children={<APIs />}
                        description={"These are our collection of integrated API services. They are made available for you on our AI canvas so you can make your own machine learning models."} />
                    <Window show={windowOpen === 'insights'} title={'Insights'} children={<Insights />}
                        description={"Sift through our collection of published analytics canvases and dashboards."} />
                    <Window show={windowOpen === 'cases'} title={'Twinviews'} children={<Cases />}
                        description={"A collections of our published Twinviews demonstrating different use cases and case studies."} />
                    <Window show={windowOpen === 'import'} title={'File Import'} children={<FileImport
                        coordinates={{ long: contextMenu.lon, lat: contextMenu.lat }}
                        close={(data) => { setWindowOpen(null); console.log(data); setCanvasMode('canvas'); setModelProgress(-1) }}
                        focusCb={(n) => {
                            console.log(n);
                            setFocusNodeFly({ ...n, latitude: n.coordinates.lat, longitude: n.coordinates.long });
                            // setTimeout(() => {
                            //     console.log(csvImports)
                            setCurrentNexFocus(n.id)
                            // }, 1000);

                        }} />}

                    />
                    <Window show={windowOpen === 'contribute'} title={'Contribute'} children={<Contribute close={() => setWindowOpen(null)} />}
                        overflowVert={true}
                        description={"Empower Birmingham's journey towards Smart City excellence by contributing your data and ideas."} />
                    <Window show={windowOpen === 'roadmap'} title={'Roadmap'} children={<Roadmap close={() => setWindowOpen(null)} />}
                        overflowVert={true}
                        description={"This roadmap charts our inspiring journey toward achieving Smart City status for Birmingham, where innovation, collaboration, and community spirit propel us towards a brighter, more connected future."}
                    />
                </div>
            </Transvoider>

        </div>

        <AnimatePresence>
            {contextMenu.visible && !windowOpen && page === 'platform' && (
                <motion.div
                    className='rounded z-10 absolute bg-defaultback mx-2 my-1 w-[200px] pt-[6px] drop-shadow-lg backdrop-blur-sm'
                    style={{
                        top: contextMenu.y,
                        left: contextMenu.x,
                        boxShadow: '2px 2px 10px black',

                    }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    onContextMenu={e => e.preventDefault()}
                >
                    <TMenuItem onClick={() => {
                        setCanvasMode(false)
                        setContextMenu({ ...contextMenu, visible: false })
                    }}>Close All</TMenuItem>
                    <TMenuItem
                        className=""
                        onClick={() => {
                            setCanvasMode('canvas')
                            setModelProgress(0)
                            setContextMenu({ ...contextMenu, visible: false })
                        }}>New AI Model</TMenuItem>
                    <TMenuItem onClick={() => {
                        // alert('Not yet implemented')
                        setCanvasMode('aggregator')
                        // setWindowOpen(false)
                        setContextMenu({ ...contextMenu, visible: false })
                    }}>New Aggregator</TMenuItem>
                    <TMenuItem onClick={() => {
                        alert('Not yet implemented')
                        setContextMenu({ ...contextMenu, visible: false })
                    }}>New Interpolator</TMenuItem>
                    <TMenuItem onClick={() => {
                        // alert('Not yet implemented')
                        setContextMenu({ ...contextMenu, visible: false })
                        setWindowOpen('import')
                    }}>New Import</TMenuItem>

                    <TMenuItem
                        className='text-[9px] py-[4px] text-warmGray-400 text-center pl-0'
                        onClick={() => {
                            setFocusNodeFly({
                                name: 'pointer',
                                node: contextMenu,
                                longitude: contextMenu?.lon,
                                latitude: contextMenu?.lat,
                                altitude: deckprops.layers.bPht ? 400 : 0,
                                hosted: true,
                                zoom: viewState.zoom > 17 ? viewState.zoom : 17,
                                pitch: 45,
                            })
                        }}
                        onContextMenu={() => window.open(`https://google.co.uk/maps/search/${contextMenu.lat},${contextMenu.lon}`, "_blank")}>{contextMenu.lat}, {contextMenu.lon}
                    </TMenuItem>
                    <div style={{ height: '6px' }} />
                </motion.div>
            )}
        </AnimatePresence>

        <div
            // className='grayscale '
            style={{ height: '100vh', width: '100vw', position: 'absolute', top: '0px' }}
        // onContextMenu={e => { setFocusNodeFly(null) }}
        >
            {ENABLE_MAP && <Map
                id="map"
                // style={{ filter: 'grayscale(1)'}}
                key={mapKey}
                ref={mapRef}
                onLoad={onMapLoad}


                mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
                antialias={page === 'home' ? true : false}
                initialViewState={INITIAL_VIEW_STATE_HOME}
                maxPitch={85}
                onError={(e) => setMapKey(`${Math.random()}`)}

                onMouseDown={handleMouseDown} // Capture mouse down event
                onMouseUp={handleMouseUp} // Reset drag on mouse up
                onMouseMove={handleMouseMove} // Hide context menu on left-click drag

                fog={fog}

                // maxBounds={[[-2.3798858331250257, 52.234649826904416], [-1.4582597902333225, 52.692993319132896]]}
                // mapStyle={canvasMode === 'canvas'? "https://basemaps.cartocdn.com/gl/positron-gl-style/style.json" : "mapbox://styles/mapbox/standard"}
                mapStyle={"mapbox://styles/mapbox/standard"}
                // mapStyle="mapbox://styles/mapbox/streets-v9"
                // mapStyle="mapbox://styles/mapbox/light-v9"

                styleDiffing
                onMove={e => {
                    if (parseInt(e.viewState.zoom) !== parseInt(viewState.zoom)) {
                        debounceSetViewState(e.viewState)
                        !merlinAssist && e.viewState.zoom < 13 && light !== 'day' && debounceSetMapLight(page === 'day')
                        !merlinAssist && e.viewState.zoom > 12 && page !== 'home' && light !== userSetMapLight && debounceSetMapLight(userSetMapLight)
                    }

                    // if (e.viewState.zoom <= 6)
                    //     debounceSetZoomScope('global')
                    // else 
                    if (e.viewState.zoom <= 8)
                        debounceSetZoomScope('national')
                    else if (e.viewState.zoom <= 12)
                        debounceSetZoomScope('regional')
                    else
                        debounceSetZoomScope('local')

                    // console.log(e.viewState.zoom)
                }}

                onClick={(e) => {
                    setFocusNodeFly(null)
                    setFocusNode(null)
                    canvasMode === "analytics" && setCanvasMode(false)
                }}
                attributionControl={true}>

                <DeckGLOverlay
                    id='dgloverlay'
                    controller={true}
                    effects={[
                        postProcessEffect,
                        lightingEffect
                    ]}
                    interleaved={false}
                    layers={[
                        deckprops.layers.bPht && goog3dtiles,

                        assets && AssetCompositeLayer,
                        GeoJsonCompositeLayer,
                        // csvImports && ImportCSVCompositeLayer,
                        hexCoverage && hexlayer,

                        currentModel?.inputs && Object.keys(currentModel.inputs).length > 0 && NexusInputLinkLayer_AI,
                        currentModel?.outputs && Object.keys(currentModel.outputs).length > 0 && NexusOutputLinkLayer_AI,

                        Object.keys(API2.aiworkflow().canvasModels).length > 0 && NexusMeshLayer_AI,
                        Object.keys(API2.aiworkflow().canvasModels).length > 0 && NexusMeshWireframeLayer_AI,
                        Object.keys(API2.aiworkflow().canvasModels).length > 0 && NexusTextLayer_AI,

                        Object.keys(API2.aiworkflow().imports).length > 0 && NexusMeshLayer_Imports,
                        Object.keys(API2.aiworkflow().imports).length > 0 && NexusMeshWireframeLayer_Imports,
                        Object.keys(API2.aiworkflow().imports).length > 0 && NexusTextLayer_Imports,

                        Object.keys(API2.aiworkflow().aggregators).length > 0 && NexusMeshLayer_Aggregators,
                        Object.keys(API2.aiworkflow().aggregators).length > 0 && NexusMeshWireframeLayer_Aggregators,
                        Object.keys(API2.aiworkflow().aggregators).length > 0 && NexusTextLayer_Aggregators,

                        PointerMeshLayer,
                        PointerWireframeMeshLayer,

                        merlinAssist && merlinWard && MerlinWardMeshLayer,
                        merlinAssist && merlinWard && MerlinWardTextLayer,
                    ]}

                    onHover={({ object }) => (isHovering = Boolean(object))}
                    getCursor={({ isDragging }) => (isDragging ? 'grabbing' : (isHovering ? 'pointer' : 'grab'))}
                />
            </Map>}

            {/* <div class="absolute inset-0 bg-diatomicDarkCyan opacity-30 pointer-events-none"/> */}
        </div>

    </div >
}

export default Platform;