import React, { useEffect, useState, useRef, useCallback } from 'react';
import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { headStyle, hrStyle, buttonStyle, headStyleInverted, backgroundStyle, lightColor, verticalCenter, colourAccent } from '../../css/styling';
import * as API2 from '../states'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Transvoider } from '../dElements/Animations';

function Window({ title, description, children, show, overflowVert }) {

    const { setWindowOpen, windowOpen , colourAccentSecondary } = API2.globalStore()

    return <div key={windowOpen} style={{ display: show ? 'block' : 'none', paddingTop: '64px' }}>
        {/* <IconButton size='xlarge' style={{ color: 'red', borderRadius: '1px', position: 'absolute', right: '100px', top: '40px', background: 'rgba(255,255,255,0.85)', borderRadius: '100%' }} onClick={() => setWindowOpen(null)} >
            <CloseIcon />
        </IconButton> */}
        <IconButton size='xlarge' style={{ color: colourAccentSecondary, borderRadius: '1px', position: 'absolute', left: '60px', top: '60px', background: 'rgba(255,255,255,0.85)', borderRadius: '100%' }} onClick={() => setWindowOpen(null)} >
            <ArrowBackIcon />
        </IconButton>

        <h1 style={{ fontSize: '18px', marginBottom: '20px', paddingLeft: '120px', paddingTop: '2px' }}>{title}</h1>
        <hr style={{ ...hrStyle, opacity: 0.3 }} />
        <div style={{ paddingTop: '20px', textAlign: '-webkit-center', overflowY: 'auto', bottom: '0px', fontSize: '14px', overflowY: 'auto', height: overflowVert ? 'calc(100vh - 150px)' : null }}>
            {description && <Typography variant='h6' style={{ maxWidth: '650px', textAlign: 'justify', paddingBottom: '20px', paddingLeft: '50px' }}>{description}</Typography>}

            {/* <Transvoider open={true} behaviour={{ y: 0, from: { opacity: 0, scale: 0.5 }, to: { opacity: 1, x: 0, scale: 1 } }}> */}
                {children}
            {/* </Transvoider> */}

            <div style={{ height: overflowVert ? '100px' : 0 }} />
        </div>
    </div>
}

export default Window;