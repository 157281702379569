import React from "react";
import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import * as API2 from "../states.js";
import { colourAccentSecondary, colourFadeBack } from "../../css/styling.js";
import {
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  Tooltip,
  createTheme,
  ThemeProvider,
  TextField,
} from "@mui/material";
import { DButton } from "../dElements/DButton.js";
import { Grid } from "@mui/material";
import { DInput } from "../dElements/DInput.js";
import { TFARCHITECTURES } from "../mlmethods/tensorflow.js";
import { DTooltip } from "../dElements/DTooltip.js";

const style = {
  background: colourAccentSecondary,
  color: "white",
  padding: "5px",
  margin: "3px",
  borderRadius: "2px",
};

/* eslint-enable */
export function CanvasMLConfig() {
  const {
    algorithm,
    setAlgorithm,
    outputs,
    inputs,
    layers,
    compile,
    setLayers,
    setEpochs,
    epochs,
    setCompileOptions
  } = API2.currentModelStore();

  return (
    <Box sx={{ width: "100%", color: "white", paddingTop: '15px', }}>
      {Object.keys(inputs).length > 0 && Object.keys(outputs).length > 0 && (
        <Grid container spacing={2}>

          <Grid xs={1 / 4 * 12} style={{ placeContent: 'center' }}>
            <span style={{ color: 'white', marginLeft: '18px' }}>Algorithms</span>
          </Grid>

          <Grid xs={3 / 4 * 12} style={{ placeContent: 'center', marginTop: '20px' }}>
            <Grid container spacing={2}>

              {Object.keys(TFARCHITECTURES).map(k => TFARCHITECTURES[k].show && <Grid xs={1 / 3 * 12}>
                <DTooltip title={TFARCHITECTURES[k].description}>
                  <DButton
                    // style={{ width: '100%' }}
                    disabled={!TFARCHITECTURES[k].enabled}
                    className="buttonexotic"
                    active={algorithm?.algo === k}
                    onClick={() => {
                      setAlgorithm(k);
                      // setCompileOptions(TFARCHITECTURES[k].compile);
                      setLayers(TFARCHITECTURES[k].layers);
                    }}
                  >
                    {k}
                  </DButton>
                </DTooltip>
              </Grid>)}

            </Grid>
          </Grid>

          



          <Grid xs={1 / 4 * 12} style={{ placeContent: 'center' }}>
            <span style={{ color: 'white', marginLeft: '18px' }}>Epochs</span>
          </Grid>

          <Grid xs={3 / 4 * 12} style={{}}>
            <Grid xs={1 / 3 * 12}>
              <DInput
                value={epochs}
                style={{ textAlign: 'center', width: '100%', marginLeft: '-10px', marginTop: '25px' }}
                onChange={(e) => {
                  const regex = /^[0-9]/;
                  if (e.target.value.toUpperCase().match(regex))
                    setEpochs(Number(e.target.value) || 1)
                }} />
            </Grid>
            <Grid xs={2 / 3 * 12} style={{ placeContent: 'center' }}>
              <span style={{ paddingLeft: '18px' }}></span>
            </Grid>
          </Grid>



          <Grid xs={12}>
            {/* <br /> */}

            <Typography style={{ color: "white", textAlign: 'center' }}>
              Loss: {compile.loss}
            </Typography>
            <Typography style={{ color: "white", textAlign: 'center' }}>
              Optimizer: {compile.optimizer}
            </Typography>
            <br />
            {layers.map((l) => (
              <Typography style={{ color: "white", textAlign: 'center' }}>
                {JSON.stringify(l)}
              </Typography>
            ))}
            {/* <br /> */}
          </Grid>


          {/* <Button className='buttonexotic' style={{ ...style, opacity: algorithm?.algo === "GRU" ? 1 : null }} onClick={() => setAlgorithm("GRU")}>GRU</Button> */}
        </Grid>
      )
      }

      {/* <br />
      <div>Layers</div>
      {algorithm?.algo === "TF.DNN" && (
        <pre>{JSON.stringify(layers, null, 2)}</pre>
      )} */}

      {/* {Object.keys(inputs).length > 0 && Object.keys(outputs).length > 0 && <>
            <Button className='buttonexotic' style={{ ...style, opacity: algorithm?.algo === "LSTM" ? 1 : null }} onClick={() => setAlgorithm("LSTM")}>LSTM</Button>
            <Button className='buttonexotic' style={{ ...style, opacity: algorithm?.algo === "GRU" ? 1 : null }} onClick={() => setAlgorithm("GRU")}>GRU</Button></>}

        {Object.keys(outputs).length > 0 && <>
            <Button className='buttonexotic' style={{ ...style, opacity: algorithm?.algo === "ARIMA" ? 1 : null }} onClick={() => setAlgorithm("ARIMA")}>ARIMA</Button>
            <Button className='buttonexotic' style={{ ...style, opacity: algorithm?.algo === "SARIMA" ? 1 : null }} onClick={() => setAlgorithm("SARIMA")}>SARIMA</Button></>} */}

      {/* <br />
      <Typography style={{ color: "white", paddingLeft: "10px" }}>
        {algorithm?.algo}
      </Typography> */}
      {/* <br /> */}
      {/* <br /> */}

    </Box >
  );
}
