import { model } from "@tensorflow/tfjs";
import axios from "axios";
import parser from 'fast-xml-parser';

const debug = true;
const log = (message) => {
  if (debug) console.log(message);
};
const conf = debug
  ? {
    timeout: 30000,
    // method: 'GET',
    // mode: 'no-cors',
    // headers: {
    //     'Access-Control-Allow-Origin': '*',
    //     'Content-Type': 'application/json',
    // },
    // withCredentials: true,
    // credentials: 'same-origin',
    // crossdomain: true
  }
  : {};

// export const ROOT = "http://diatomic.ddns.net:9580/frontapi/v1/"
// export const endPoint =  'http://diatomic.ddns.net:9580/frontapi/v1/'

const dummyCollections = {
  collections: [
    {
      name: "traffic_rtem",
      dataTypes: {
        assets: true,
        timeseries: true,
      },
      links: [
        {
          rel: "self",
          href: "http://diatomic.ddns.net:9580/frontapi/v1/collections/traffic_rtem",
          method: "GET",
        },
        {
          rel: "assets",
          href: "http://diatomic.ddns.net:9580/frontapi/v1/collections/traffic_rtem/assets",
          method: "GET",
        },
        {
          rel: "data",
          href: "http://diatomic.ddns.net:9580/frontapi/v1/collections/traffic_rtem/data",
          method: "GET",
        },
      ],
    },
  ],
  links: [
    {
      rel: "self",
      href: "http://diatomic.ddns.net:9580/frontapi/v1/collections",
      method: "GET",
    },
  ],
};

const endPoint = false
  ? "http://diatomic.ddns.net:9580/frontapi/v1/"
  : process.env.REACT_APP_DEV_API;



// CORE API CALLS

export async function getUrl(url, callback, errCallback, endpoint = endPoint) {
  try {
    // const url = ROOT + "collections/"
    // log("fetching collections " + url)
    const response = await axios.get(url, conf);
    // const response = dummyCollections

    // let pResponse = {}

    // response.data.collections.forEach(element => {
    //     element.visible = true
    //     pResponse[element.name] = element
    // });

    // // console.log(pResponse, null,2)

    console.log(response.data);

    callback && callback(response.data);
    return response;
  } catch (error) {
    let errmsg = { error: error.message, url };
    errCallback && errCallback(errmsg);
    console.log(errmsg);
    return null;
  }
}

export async function getCollections(
  callback,
  errCallback,
  endpoint = endPoint
) {
  const url = endpoint + "collections/";
  try {
    // const url = ROOT + "collections/"
    // log("fetching collections " + url)
    const response = await axios.get(url, conf);
    // const response = dummyCollections

    let pResponse = {};

    response.data.collections.forEach((element) => {
      element.visible = true;
      pResponse[element.name] = element;
    });

    // console.log(pResponse, null,2)

    callback && callback(pResponse);
    return pResponse;
  } catch (error) {
    let errmsg = { error: error.message, url };
    errCallback && errCallback(errmsg);
    console.log(errmsg);
    return [];
  }
}

export async function getAssets(
  collection,
  callback,
  errCallback,
  endpoint = endPoint
) {
  const url = endpoint + "collections/" + collection + "/assets";
  try {
    // const url = ROOT + "collections/"
    // log("fetching assets " + url)
    const response = await axios.get(url, conf);
    // const response = dummyCollections

    const store = {};
    response.data.forEach((asset) => {
      // alert(JSON.stringify(asset))
      store[asset.asset_id] = asset;
    });

    let rResponse = { [collection]: store };
    // alert(JSON.stringify(rResponse))
    callback && callback(rResponse);
    return rResponse;
  } catch (error) {
    let errmsg = { error: error.message, url };
    errCallback && errCallback(errmsg);
    console.log(errmsg);
    return [];
  }
}

export async function getAssetData3(
  collection,
  assets,
  callback,
  errCallback = null,
  date_start = "today",
  date_end = null,
  dt_type = 'str',
  endpoint = endPoint,
) {
  var url =
    endpoint +
    `collections/${collection}/data?asset_ids=${assets.join(
      ","
    )}&dt_type=${dt_type}&date_start=${date_start}`;

  if (date_end)
    url += `&date_end=${date_end}`




  try {
    log("fetching asset data " + url)
    // console.log("requesting", url);
    const response = await axios.get(url, conf)

    // console.log(response.data)

    // console.log(response.data)
    callback && callback(response.data);
    return response.data;
  } catch (error) {
    let errmsg = { error: error.message, url };
    errCallback && errCallback(errmsg);
    console.log(errmsg);
    return [];
  }
}


export async function getAssetData4(
  collection,
  assets,
  callback,
  errCallback = null,
  date_start = "today",
  date_end = null,
  dt_type = 'str',
  endpoint = endPoint,
) {
  var url =
    endpoint +
    `collections/${collection}/data?asset_ids=${assets.join(
      ","
    )}&dt_type=${dt_type}&date_start=${date_start}`;

  // var stat_url_template = 'http://diatomic.ddns.net:9580/frontapi/v1/collections/traffic_rtem/data/stats?dt_type=str&group_by_asset=false&group_by_ts=false&date_start=2024-01-01&asset_ids=R4212L4'
  var stat_url = endpoint +
    `collections/${collection}/data/stats?asset_ids=${assets.join(
      ","
    )}&dt_type=${dt_type}&date_start=${date_start}&group_by_asset=false&group_by_ts=false`;

  if (date_end) {
    url += `&date_end=${date_end}`
    stat_url += `&date_end=${date_end}`
  }

  try {
    log("fetching asset data " + url)
    // console.log("requesting", url);
    const response = await axios.get(url, conf)
    // console.log(stat_url)
    const statResponse = await axios.get(stat_url, conf)


    // console.log(response.data)

    // console.log(response.data)
    let result = { data: response.data, stats: null }
    callback && callback(result);
    return result;
  } catch (error) {
    let errmsg = { error: error.message, url };
    errCallback && errCallback(errmsg);
    console.log(errmsg);
    return {};
  }
}


// export async function getAssetStats(){
//   let url = 'http://diatomic.ddns.net:9580/frontapi/v1/collections/traffic_rtem/data/stats?dt_type=str&group_by_asset=false&group_by_ts=false&date_start=2024-01-01&asset_ids=R4212L4'
// }

export async function fetchAssetData(
  collection,
  assets,
  date_start = "today",
  date_end = null,
  dt_type = 'str',
  granularity = 60,
  endpoint = endPoint,
) {
  var url =
    endpoint +
    `collections/${collection}/data?asset_ids=${assets.join(
      ","
    )}&dt_type=${dt_type}&date_start=${date_start}&granularity=${granularity}`;

  if (date_end)
    url += `&date_end=${date_end}`

  try {
    let data = (await axios.get(url, conf)).data
    return { [collection]: { [data[0].asset_id]: data[0].data } }

  } catch (error) {
    console.log('getAssetData2().error', error.message, url)
    return { [collection]: {} };

  }
}

export async function fetchAssetStats(
  collection,
  assets,
  date_start = "today",
  date_end = null,
  dt_type = 'int',
  endpoint = endPoint,
) {

  // var stat_url_template = 'http://diatomic.ddns.net:9580/frontapi/v1/collections/traffic_rtem/data/stats?dt_type=str&group_by_asset=false&group_by_ts=false&date_start=2024-01-01&asset_ids=R4212L4'
  var stat_url = endpoint +
    `collections/${collection}/data/stats?asset_ids=${assets.join(
      ","
    )}&dt_type=${dt_type}&date_start=${date_start}&group_by_asset=false&group_by_ts=false`;

  if (date_end) {
    stat_url += `&date_end=${date_end}`
  }

  try {
    let stats = (await axios.get(stat_url, conf)).data

    // console.log(stats)
    return { stats: stats }

  } catch (error) {
    console.log('getAssetData2().error', error.message, stat_url)
    return { stats: {} };
  }
}





export async function getUserData(
  user = "main_user",
  storage_id = null,
  endpoint = endPoint
) {
  axios
    .get(
      endpoint + "users/" + user + "/storage/" + storage_id ? storage_id : ""
    )
    .then(function (response) {
      callback && callback(response);
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
}

export async function putUserData(
  user = "main_user",
  storage_id = new Date().toISOString(),
  contents = {},
  endpoint = endPoint
) {
  axios
    .put(endpoint + "users/" + user + "/storage", {
      storage_id,
      contents,
    })
    .then(function (response) {
      callback && callback(response);
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
}

export async function patchUserData(
  user = "main_user",
  storage_id = new Date().toISOString(),
  contents = {},
  endpoint = endPoint
) {
  axios
    .patch(endpoint + "users/" + user + "/storage", {
      storage_id,
      contents,
    })
    .then(function (response) {
      callback && callback(response);
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
}

export async function deleteUserData(
  user = "main_user",
  storage_id = new Date().toISOString(),
  callback = null,
  endpoint = endPoint
) {
  axios
    .delete(endpoint + "users/" + user + "/storage")
    .then(function (response) {
      callback && callback(response);
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
}


// APP SPECIFICS

export const _yearFormatter = (date) =>
  `${(date.getMonth() + 1).toString()}-${date.getDate().toString()} ${date
    .getHours().toString()}:${('0' + date.getMinutes().toString()).slice(-2)}`;

export function _unflattenObject(data) {
  var result = {};
  for (var i in data) {
    var keys = i.split('.');
    keys.reduce(function (r, e, j) {
      return r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 == j ? data[i] : {}) : []);
    }, result);
  }
  return result;
}

export function _flattenObject(dictToFlatten, levels = 1, sep = '.') {
  function flatten(dict, parent, lvl) {
    var keys = [];
    var values = [];

    for (var key in dict) {
      if (typeof dict[key] === 'object' && !Array.isArray(dict[key]) && lvl > 0) {
        var result = flatten(dict[key], parent ? parent + sep + key : key, lvl - 1);
        keys = keys.concat(result.keys);
        values = values.concat(result.values);
      }
      else {
        keys.push(parent ? parent + sep + key : key);
        values.push(dict[key]);
      }
    }

    return {
      keys: keys,
      values: values
    };
  }

  var result = flatten(dictToFlatten, null, levels);
  var flatDict = {};

  for (var i = 0, end = result.keys.length; i < end; i++) {
    flatDict[result.keys[i]] = result.values[i];
  }

  return flatDict;
}

export function _timeTracks(dataDict) {
  // console.log(dataDict)
  let timetracks = {}

  Object.values(dataDict).filter(v => v && v?.timestamp).map(v => v.timestamp).flat().forEach(t => timetracks[Number(t)] = 0);
  timetracks = Object.keys(timetracks).map(x => Number(x)).sort()

  // console.log('create timetrack', timetracks)
  // timetracks = Array.from(new Set(timetracks));
  // timetracks.sort();
  return { ...dataDict, timestamps: timetracks };
}

export function _fillValues(values, timestamps, timetracks, defaultValue) {

  // let mergedObject = {}

  // timestamps.forEach()

  // console.log('values', values.length)
  // console.log('timestamps', timestamps.length)

  // let newValues = JSON.parse(JSON.stringify(timetracks))
  let newValues = {}

  timetracks.forEach(t => newValues[t] = defaultValue)

  timestamps.forEach((t, i) => newValues[t] = values[i])



  // let dict = timestamps.reduce((acc, curr, idx) => {
  //   if (typeof values[idx] === 'number')
  //     return acc = { ...acc, [curr]: values[idx] }
  //   else
  //     return acc = { ...acc, [curr]: defaultValue }
  // }, {})

  // timetracks.forEach(t => {
  //   if (!dict[t])
  //     dict[t] = defaultValue
  // })

  // newValues = Object.values(dict)

  // console.log('filled', newValues)

  return Object.values(newValues)
}

export async function fetchAggregateData(collection = '', assets = [], date_start, date_end, operation = "avg", granularity = 60, dt_type = 'str') {
  // http://diatomic.ddns.net:9580/frontapi/v1/collections/traffic_rtem/data/aggregation?agg_oper=sum&date_start=today

  var url =
    endPoint +
    `collections/${collection}/data/aggregation?asset_ids=${assets.join(
      ","
    )}&dt_type=${dt_type}&date_start=${date_start}&granularity=${granularity}&agg_oper=${operation}`;

  if (date_end)
    url += `&date_end=${date_end}`


  console.log(url)

  try {
    let { data_validation, ...data } = (await axios.get(url, conf)).data
    console.log(data)
    return data

  } catch (error) {
    console.log('getAssetData2().error', error.message, url)
    return {};
  }
}


export async function canvasFetchData(assets = [], startDate, endDate, filler = undefined, granularity = 60, aggregators, imports) {
  console.log('canvasFetchData()')
  let promises = [];
  // let statsPromises = [];

  // PREPARE PROMISES
  assets.sort().forEach(async (stream) => {
    let resource = stream.split(".");

    switch (resource[0]) {
      case "NEX_AGGREGATOR":
        // try{

        console.log(aggregators, resource[1])
        var aggnode = Object.values(aggregators).find(x => x.asset_id === resource[1])
        promises.push(fetchAggregateData(
          aggnode.aggregation_collection,
          aggnode.aggregation_assets,
          startDate,
          endDate,
          aggnode.agg_operation,
          60,
          "int"
        ).then(x => ({ NEX_AGGREGATOR: { [`${aggnode.asset_id}`]: x.data } })))
        // }
        // catch(e){
        //   alert(`This model depends on aggregator "${resource[1]}" which is missing.`)
        // }
        break;
      default:
        promises.push(fetchAssetData(
          resource[0],
          [resource[1]],
          startDate,
          endDate,
          "int",
          60
        ))
        // statsPromises.push(fetchAssetStats(
        //   resource[0],
        //   [resource[1]],
        //   startDate,
        //   endDate,
        //   "int"
        // ))
        break;
    }
  })

  // FETCH PROMISES FOR DATA
  let promisesResults = await Promise.all(promises)
    .catch(e => console.log('diatomicFetchData, fetch promise all inputs returns', e));

  console.log(promisesResults)

  let flatResult = promisesResults.map(x => _flattenObject(x, 1));
  let shallowMergedResult = flatResult.reduce(
    (acc, curr) => acc = { ...acc, ...curr }, {});


  // FETCH PROMISES FOR STATS
  // let promisesStatsResults = await Promise.all(statsPromises)
  //   .catch(e => console.log('diatomicFetchData, fetch promise stats all inputs returns', e));

  // let flatStatsResult = promisesStatsResults.map((x, i) => x?.stats?.stats?.[0] || {});
  // let finalStatsResult = {}
  // assets.sort().forEach((k, i) => finalStatsResult[k] = flatStatsResult[i][k.split('.').slice(-1)])


  // GET GLOBAL TIMESTAMPS
  let results = _timeTracks(shallowMergedResult);

  for (let index = 0; index < results.timestamps.length - 1; index++) {
    if (results.timestamps[index] >= results.timestamps[index + 1]) {
      console.log(index, results.timestamps[index], results.timestamps[index + 1])
      alert('error: timestamps non-linear progression')
    }
  }

  // FILL MISSING ENTRIES
  // console.log('before fill missing', results)

  Object.keys(results).forEach(asset => {
    try {
      asset !== "timestamps" && Object.keys(results[asset]).forEach(stream => {
        // console.log('fill', asset, stream)

        if (stream !== 'timestamp')
          results[asset][stream] = _fillValues(results[asset][stream], results[asset].timestamp, results.timestamps, filler);
      });
      delete results[asset].timestamp
    }
    catch (e) { console.log(e) }
  });

  // console.log('after fill missing', results)

  results = _flattenObject(results, 1);

  // DELETE IRRELEVANT
  for (var k in results) {
    if (!assets.includes(k) && k !== 'timestamps')
      delete results[k]
  }

  results.invalid = null
  // results.stats = finalStatsResult
  results.granularity = 0

  assets.forEach(asset => {
    if (!(asset in results)) {
      if (results.invalid === null)
        results.invalid = []
      results.invalid.push(`'${asset}' contains no data in this range.`)
    }
  })

  console.log('canvasFetchData results fetched', results)

  return results;
}

export async function canvasFetchModelData(model, startDate, endDate, filler = undefined, granularity = 60, aggregators, imports) {
  console.log('canvasFetchModelData()')

  let data = await canvasFetchData([...Object.keys(model.inputs), ...Object.keys(model.outputs)], startDate, endDate, filler, granularity = 60, aggregators, imports)
  let modelData = { inputs: {}, outputs: {}, invalid: data.invalid }

  Object.keys(model.inputs).forEach(s => modelData.inputs[s] = data[s])
  Object.keys(model.outputs).forEach(s => modelData.outputs[s] = data[s])

  modelData['timestamps'] = data?.timestamps
  modelData['stats'] = data?.stats

  return modelData;
}




// export async function canvasFetchModelData(model, startDate, endDate, filler = undefined, callback) {
//   console.log('canvasFetchModelData()')

//   // console.log('diatomic fetch data array', 'inputs', inputAssetStreams, 'outputs', outputAssetStreams);
//   let inputPromises = [];
//   let outputPromises = [];

//   // PREPARE PROMISES
//   Object.key(model.inputs).sort().forEach(async (stream) => {
//     let resource = stream.split(".");
//     inputPromises.push(getAssetData2(
//       resource[0],
//       [resource[1]],
//       null,
//       null,
//       startDate,
//       endDate,
//       "int"
//     ))
//   })

//   Object.key(model.outputs).sort().forEach(async (stream) => {
//     let resource = stream.split(".");
//     outputPromises.push(getAssetData2(
//       resource[0],
//       [resource[1]],
//       null,
//       null,
//       startDate,
//       endDate,
//       "int"
//     ))
//   })


//   // CALL PROMISES
//   let inputPromisesResults = await Promise.all(inputPromises)
//     .catch(e => console.log('diatomicFetchData, fetch promise all inputs returns', e));
//   let outputPromisesResults = await Promise.all(outputPromises)
//     .catch(e => console.log('diatomicFetchData, fetch promise all outputs returns', e));


//   // console.log(inputPromisesResults, outputPromisesResults)

//   // console.log(promisesResults)
//   let flatInputsResult = inputPromisesResults.map(x => _flattenObject(x, 1));
//   let flatOutputsResult = outputPromisesResults.map(x => _flattenObject(x, 1));
//   let shallowMergedInputsResult = flatInputsResult.reduce(
//     (acc, curr) => acc = { ...acc, ...curr }, {});
//   let shallowMergedOutputsResult = flatOutputsResult.reduce(
//     (acc, curr) => acc = { ...acc, ...curr }, {});

//   // console.log(shallowMergedInputsResult)


//   // GET GLOBAL TIMESTAMPS
//   let results = _timeTracks({ ...shallowMergedInputsResult, ...shallowMergedOutputsResult });


//   console.log(results)
//   // console.log(Object.assign(shallowMergedInputsResult, shallowMergedOutputsResult ))
//   // console.log(Object.assign(shallowMergedInputsResult, shallowMergedOutputsResult ))
//   // console.log(Object.assign(shallowMergedInputsResult, shallowMergedOutputsResult ))

//   // KEEP RELEVANT ASSETS AND STREAMS
//   // let resultsFlat = flattenObject(results, 1);
//   // results = { timestamps: results.timestamps };
//   // streams.forEach(stream => results[stream] = resultsFlat[stream]
//   // );

//   // FILL MISSING ENTRIES
//   Object.keys(results).forEach(asset => {
//     asset !== "timestamps" && Object.keys(results[asset]).forEach(stream => {
//       results[asset][stream] = _fillValues(results[asset][stream], results[asset].timestamp, results.timestamps, filler);
//     });
//   });

//   results = _flattenObject(results, 1);

//   for (var k in results) {
//     if (!streams.includes(k) && k !== 'timestamps')
//       delete results[k]
//   }

//   // console.log(dataDict, results)

//   if (currentModel?.inputs && currentModel?.outputs) {
//     let dataDict = { inputs: currentModel.inputs, outputs: currentModel.outputs, timestamps: results.timestamps }
//     // console.log(dataDict, results)

//     Object.keys(dataDict.inputs).forEach(stream => dataDict.inputs[stream] = results[stream])
//     Object.keys(dataDict.outputs).forEach(stream => dataDict.outputs[stream] = results[stream])
//     results = dataDict
//   }
//   console.log('results', results)

//   callback && callback(results);
//   return results;
// }

export async function fetchHighwaysEnglandFeeds() {
  const feedUrl = 'https://m.highwaysengland.co.uk/feeds/rss/CurrentAndFutureEvents/West%20Midlands.xml'
  const response = await axios.get(feedUrl, conf);
  console.log(response)

  const data = parser.parse(response.data)
}