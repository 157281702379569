import React, { useEffect, useState, useRef, useCallback } from "react";
import { Button } from "@mui/material";
import { colourAccent as defaultColourAccent } from "../../css/styling";
import * as API2 from "../states.js";

export const DButton = ({ className, style, children, onClick, disabled, active }) => {
    const { colourAccent } = API2.globalStore()
    const [focus, setFocus] = useState(false)

    return <button
        className={className}
        onMouseOver={() => setFocus(true)}
        onMouseLeave={() => setFocus(false)}
        style={{
            opacity: disabled ? 0.5 : focus ? 1.0 : 0.8,
            cursor: 'pointer',
            color: active ? "rgba(31, 31, 31, 0.8)" : colourAccent || defaultColourAccent,
            borderWidth: "revert",
            borderColor: active ? "rgba(31, 31, 31, 0.8)" : colourAccent || defaultColourAccent,
            borderStyle: "solid",
            background: active ? colourAccent || defaultColourAccent : "rgba(80,80,80,0.2)",
            borderRadius: '3px',
            width: "100%",
            height: "30px",
            padding: 0,
            margin: 5,
            WebkitTransition: '0.3s',
            transition: '0.3s',
            ...style
        }}
        sx={{ mt: 1, mr: 1 }}
        onClick={disabled ? null : onClick}
    >
        {children}
    </button>
}