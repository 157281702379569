import { FunctionsRounded } from "@mui/icons-material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  headStyle,
  hrStyle,
  buttonStyle,
  headStyleInverted,
  backgroundStyle,
  lightColor,
  verticalCenter,
  colourAccent,
  colourAccentSecondary,
} from "../../css/styling";
import { IconButton, Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import HexagonIcon from "@mui/icons-material/Hexagon";
import InfoIcon from "@mui/icons-material/Info";
import TagIcon from "@mui/icons-material/Tag";
import InputIcon from "@mui/icons-material/Input";
import OutputIcon from "@mui/icons-material/Output";
import * as API2 from "../states";
import * as API from "../apis/APIWeb";
import { LineChart } from "@mui/x-charts/LineChart";
import { BarChart } from "@mui/x-charts/BarChart";
import Box from "@mui/material/Box";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  Transitioner,
  Transvoider,
  spanArray,
  spanArrayStringEqualSplit,
} from "../dElements/Animations";
import { Trail } from "@react-spring/web";
import { Tooltip } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimeRangePickerPopper } from "../dElements/DateTimeRangePicker";
import dayjs from "dayjs";
import { DButton } from "../dElements/DButton";
import DCharts from "../dElements/DCharts";
import { canvasFetchModelData } from "../apis/APIWeb.js";
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import { DTooltip } from "../dElements/DTooltip.js";
import { AnimatePresence } from "framer-motion";
import { DateTime } from "luxon";
import TButton from "../dElements/TButton.js";

const graphHeight = 150

export default function AssetInfo({
  node,
  latitude,
  longitude,
  hosted,
  style,
  focusCb,
}) {
  const canvasMode = API2.aiworkflow().canvasMode;
  const modelProgress = API2.aiworkflow().modelProgress;
  const { aggregators, imports } = API2.aiworkflow();
  const { showMeta, setShowMeta, borderRadius } = API2.globalStore();
  const currentModel = API2.currentModelStore();
  const addFeature = API2.currentModelStore().addFeature;
  const removeFeature = API2.currentModelStore().removeFeature;
  const [timeframe, setTimeframe] = useState(288);
  const [graphDate, setGraphDate] = useState(null);
  const { setFocusNode } = API2.aiworkflow();

  const [update, setUpdate] = useState(Date.now())

  const [thisProps, setThisprops] = useState({
    node,
    latitude,
    longitude,
    hosted,
    style,
  });

  const [data, setData] = useState([
    API2.DUMMYDATA("DUMMYDATA1", 288),
    API2.DUMMYDATA("DUMMYDATA2", 288),
    API2.DUMMYDATA("DUMMYDATA3", 288),
    API2.DUMMYDATA("DUMMYDATA4", 288),
  ]);

  const [stats, setStats] = useState(null)


  const getData = (timeframe, callback) => {
    const newdata = [
      API2.DUMMYDATA("DUMMYDATA1", timeframe),
      API2.DUMMYDATA("DUMMYDATA2", timeframe),
      API2.DUMMYDATA("DUMMYDATA3", timeframe),
      API2.DUMMYDATA("DUMMYDATA4", timeframe),
    ];
    setData(newdata);
    callback && callback(timeframe);
  };

  const subTitleCharStyle = {
    paddingBottom: "5vh",
    fontSize: "3vh",
    textShadow: "0 0 2px #1e1e1e",
    textAlign: "center",
  };

  const bannerStreamsStyle = {
    fontWeight: "bold",
    marginTop: "0px",
    background: 'linear-gradient(to right, rgba(255,255,255,0), rgba(0,0,0,0.2) , rgba(255,255,255,0))',
    textAlign: 'center',
    padding: '5px'
  }

  useEffect(() => {
    switch (node?.collection) {
      case 'NEX_AI':
        const fetchData = async () => {
          const modelData = await canvasFetchModelData(
            currentModel, graphDate?.toISOString().split("T")[0] || "today", undefined, undefined, 60, aggregators, imports)
            .then(response => {
              response.timestamp = response.timestamps.map(d => new Date(d).toISOString())

              let thisData = { timestamp: response.timestamp, inputs: { ...response.inputs }, outputs: { ...response.outputs } }

              console.log(thisData)
              setData(thisData)
              setThisprops({ node: { ...node, meta: node?.meta ? API._flattenObject(node.meta, 1, ".") : {} }, latitude, longitude, hosted, collection: node.collection, style })
              return thisData
            })
        }
        fetchData();
        setTimeout(() => setUpdate(Date.now()), 100);
        break;
      case 'NEX_AGGREGATOR':
        const fetchAggregateData = async () => {
          let result = await API.fetchAggregateData(node.aggregation_collection, node.aggregation_assets.map(x => x.toUpperCase()), graphDate?.toISOString().split("T")[0] || "today", null, node.agg_operation, 60)
            .then(response => {
              let thisData = { timestamp: [], outputs: {} }

              if (response?.data) {
                let { timestamp, ...outputs } = response.data
                timestamp = timestamp.map(d => new Date(d).toISOString())
                thisData = { timestamp, outputs }
              }

              console.log(thisData)
              setData(thisData)
              setThisprops({ node: { ...node, meta: node?.meta ? API._flattenObject(node.meta, 1, ".") : {} }, latitude, longitude, hosted, collection: node.collection, style })
              return thisData
            })
        }
        fetchAggregateData();
        setTimeout(() => setUpdate(Date.now()), 100);
        break;
      case 'NEX_IMPORT':

        switch (node?.fileType) {
          case ".csv":
            const { timestamp, ...newobject } = node.actions.fetchData(node.data, graphDate?.toISOString().split("T")[0] || DateTime.now().toJSDate().toISOString().split("T")[0], null)
            setData({ timestamp: timestamp, inputs: {}, outputs: newobject })
            break;
          case ".geojson":
            setData({ ...node.data, inputs: {}, outputs: {} })
            break;
        }

        setThisprops({ node: { ...node, meta: node?.meta ? API._flattenObject(node.meta, 1, ".") : {} }, latitude, longitude, hosted, collection: node.collection, style })
        setTimeout(() => setUpdate(Date.now()), 100);
        break;
      default:
        if (hosted === true)
          API.fetchAssetData(
            node.collection,
            [node.asset_id],
            graphDate?.toISOString().split("T")[0] || "today"
          ).then((r) => {
            setData(r[node.collection][node.asset_id] || []),
              console.log(r[node.collection][node.asset_id] || [])
            setStats(r.stats || {}),
              setThisprops({ node: { ...node, meta: node?.meta ? API._flattenObject(node.meta, 1, ".") : {} }, latitude, longitude, hosted, collection: node.collection, style })
          });
        else getData(timeframe);
        setTimeout(() => setUpdate(Date.now()), 500);
        break;
    }
  }, [node, graphDate]);

  return (
    <Box
      style={{
        ...backgroundStyle,
        zIndex: 1,
        borderRadius: borderRadius,
        maxHeight: "calc(100vh - 110px)",
        ...style,
      }}
    >
      <DragIndicatorIcon
        style={{
          position: "absolute",
          cursor: "grab",
          zIndex: 2,
          marginTop: "10px",
          marginLeft: "350px",
        }}
      />

      <DTooltip title="Metadata">
        <IconButton
          style={{
            position: "absolute",
            zIndex: 2,
            marginTop: "10px",
            marginLeft: "300px",
            fontSize: "14px",
            borderRadius: 0,
            padding: 0,
            color: showMeta ? "white" : "grey",
          }}
          onClick={() => {
            setShowMeta(!showMeta);
            // focusCb({
            //     center: [longitude, latitude],
            //     essential: true,
            //     duration: 2000,
            //     zoom: 18,
            //     pitch: 65,
            // })
          }}
        >
          <TagIcon />
        </IconButton>
      </DTooltip>

      {node?.actions?.analytics && <DTooltip title="Model Analysis">
        <IconButton
          style={{
            position: "absolute",
            zIndex: 2,
            marginTop: "10px",
            marginLeft: "275px",
            fontSize: "14px",
            borderRadius: 0,
            padding: 0,
            color: "white",
          }}
          onClick={() => {
            node?.actions?.analytics && node?.actions?.analytics()
          }}
        >
          <AutoGraphIcon />
        </IconButton>
      </DTooltip>}

      <DTooltip title="Focus Node">
        <IconButton
          className="buttonexotic"
          style={{
            position: "absolute",
            zIndex: 2,
            marginTop: "10px",
            marginLeft: "325px",
            fontSize: "14px",
            borderRadius: 0,
            padding: 0,
            color: "white",
          }}
          onClick={() => {
            focusCb({
              center: [longitude, latitude],
              essential: true,
              duration: 2000,
              zoom: 16,
              // pitch: 65,
            });
          }}
        >
          <CenterFocusStrongIcon />
        </IconButton>
      </DTooltip>

      {hosted && (
        <>
          <Box
            style={{
              paddingLeft: "15px",
              paddingBottom: "0px",
            }}
          >
            <div style={{
              paddingTop: "10px",
              paddingBottom: "2px",
            }}>
              <Transvoider
                open={true}
                behaviour={{ x: 0, y: 0, height: 18, config: { duration: 100 } }}
              >
                <div>
                  <span style={{ opacity: 0.4 }}>COLL.: </span>
                  {thisProps?.node?.collection?.toUpperCase()}
                </div>
                <div>
                  <span style={{ opacity: 0.4 }}>ASSET: </span>
                  {thisProps?.node?.asset_id || thisProps?.node?.name}
                </div>
              </Transvoider>
            </div>

            <div style={{ paddingTop: 2, maxHeight: '150px', overflowY: thisProps.node?.meta?.length > 8 ? 'auto' : 'hidden', overflowX: 'clip' }}>
              <Transvoider
                open={showMeta}
                behaviour={{ x: !showMeta ? 0 : 5, height: showMeta ? 18 : 0 }}
                behaviourConfig={{ duration: 80 }}
              >
                <div style={{ opacity: 0.6 }}>
                  <span style={{ opacity: 0.4 }}>LAT.:</span>{" "}
                  {thisProps?.latitude}
                </div>
                <div style={{ opacity: 0.6 }}>
                  <span style={{ opacity: 0.4 }}>LONG.:</span>{" "}
                  {thisProps?.longitude}
                </div>

                {thisProps.node?.meta &&
                  Object.keys(thisProps.node.meta).map(
                    (k) =>
                      k !== "last_update" && (
                        <div style={{ opacity: 0.6 }}>
                          {" "}
                          <span style={{ opacity: 0.4 }}>
                            {" "}
                            {k.toUpperCase()}:
                          </span>{" "}
                          {`${thisProps.node.meta[k]}`.toUpperCase()}
                        </div>
                      )
                  )}
              </Transvoider>
            </div>
            <Transvoider
              open={true}
              behaviour={{ x: 0, height: 18, config: { duration: 100 } }}
            >
            </Transvoider>
          </Box>


          <div
            className="flex my-2"
          >
            <TButton
              style={{
                marginLeft: '8px',
                width: "110px"
              }}
              onClick={() => setGraphDate(null)}
            >
              PREVIOUS 24H
            </TButton>

            <DateTimeRangePickerPopper
              range={false}
              btnLabel={`FOR ${graphDate === null
                ? "DAY"
                : graphDate.toISOString().split("T")[0]
                // .split("T")[0]
                }`}
              btnStyle={{
                width: "250px",
                padding: 0,
                margin: 0
              }}
              onChange={(newValue) => {
                setGraphDate(dayjs(newValue).add(1, "day"));
              }}
            />
          </div>

          <Box
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              background: "rgb(31 31 31 / 40%)",
              paddingLeft: "10px",
              color: colourAccent,
              borderTopLeftRadius: "5px",
              borderBottomLeftRadius: "5px",
              height: "calc(90vh - 110px - 140px - 50px)",
              marginTop: "0px",
            }}
          >

            {node?.collection === 'NEX_AI' && data?.outputs && <>
              <div style={bannerStreamsStyle}>
                OUTPUT STREAMS
              </div>
              {Object.keys(data.outputs)
                .sort()
                .map(
                  (v, i) =>
                    v !== "timestamp" &&
                    data.outputs[v]?.length && (
                      <div style={{ paddingTop: "10px" }}>
                        <div
                          style={{
                            fontWeight: "bold",
                            marginTop: "0px",
                            marginBottom: "-25px",
                          }}
                        >
                          {" "}
                          {v.toUpperCase()}
                        </div>

                        <DCharts style={{ height: graphHeight, marginBottom: '0px' }}
                          title=''
                          datetimeArray={data.timestamp}
                          dataArray={[data.outputs[v].map(x => x || 0)]}
                          colors={['cyan']}
                          labels={['Base']}
                          update={update} />

                        <div style={{ height: "5px", width: "100%" }} />
                      </div>
                    ))}
            </>}

            {node?.collection === 'NEX_AI' && data?.inputs && <>
              <div style={bannerStreamsStyle}>
                INPUT STREAMS
              </div>
              {Object.keys(data.inputs)
                .sort()
                .map(
                  (v, i) =>
                    v !== "timestamp" &&
                    data.inputs[v]?.length && (
                      <div style={{ paddingTop: "10px" }}>
                        <div
                          style={{
                            fontWeight: "bold",
                            marginTop: "0px",
                            marginBottom: "-25px",
                          }}
                        >
                          {" "}
                          {v.toUpperCase()}
                        </div>

                        <DCharts style={{ height: graphHeight, marginBottom: '0px', }}
                          title=''
                          datetimeArray={data.timestamp}
                          dataArray={[data.inputs[v].map(x => x || 0)]}
                          colors={['cyan']}
                          labels={['Base']}
                          update={update} />

                        <div style={{ height: "5px", width: "100%" }} />
                      </div>
                    ))}
            </>}

            {node?.collection === 'NEX_IMPORT' && node?.fileType === '.csv' && data?.outputs && <>
              <div style={bannerStreamsStyle}>
                OUTPUT STREAMS
              </div>
              {Object.keys(data.outputs)
                .sort()
                .map(
                  (v, i) =>
                    v !== "timestamp" &&
                    data.outputs[v]?.length && (
                      <div style={{ paddingTop: "10px", width: '100%' }}>
                        <div
                          style={{
                            fontWeight: "bold",
                            marginTop: "0px",
                            marginBottom: "-25px",
                          }}
                        >
                          {" "}
                          {v.toUpperCase()}
                        </div>

                        <DCharts style={{ height: graphHeight, marginBottom: '0px' }}
                          title=''
                          datetimeArray={data.timestamp}
                          dataArray={[data.outputs[v].map(x => x || 0)]}
                          colors={['cyan']}
                          labels={['Base']}
                          update={update} />

                        {canvasMode && modelProgress === 1 ? (
                          <div
                            style={{
                              display: "inline-flex",
                              marginTop: "-20px",
                              marginBottom: "10px",
                            }}
                          >
                            <Transvoider
                              open={true}
                              behaviour={{
                                x: 0,
                                y: 0,
                                from: { scale: 0.8, opacity: 0 },
                                to: { scale: 1, opacity: 1 },
                              }}
                            >
                              {/* <div> */}
                              {!currentModel.inputs[
                                `${node.collection}.${node.asset_id}.${v}`
                              ] ? (
                                <Button
                                  // className='buttonexotic'
                                  style={{
                                    // ...buttonStyle,
                                    // background: 'teal',
                                    color: colourAccent,
                                    borderWidth: "revert",
                                    borderColor: colourAccent,
                                    borderStyle: "solid",
                                    width: "160px",
                                    height: "30px",
                                    // ...buttonStyle, width: '120px'
                                  }}
                                  onClick={() =>
                                    addFeature("inputs", {
                                      name: `${node.collection}.${node.asset_id}.${v}`,
                                      node,
                                      latitude,
                                      longitude,
                                    })
                                  }
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  <AddCircleIcon /> &nbsp; INPUT Feature
                                </Button>
                              ) : (
                                <Button
                                  // className='buttonexotic'
                                  style={{
                                    // ...buttonStyle,
                                    background: colourAccent,
                                    color: "rgb(31 31 31 / 80%)",
                                    borderWidth: "revert",
                                    borderColor: "rgb(31 31 31 / 80%)",
                                    borderStyle: "solid",
                                    width: "160px",
                                    height: "30px",
                                    // ...buttonStyle, width: '120px'
                                  }}
                                  onClick={() =>
                                    removeFeature("inputs", {
                                      name: `${node.collection}.${node.asset_id}.${v}`,
                                      node,
                                      latitude,
                                      longitude,
                                    })
                                  }
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  <RemoveCircleIcon /> &nbsp; INPUT Feature
                                </Button>
                              )}

                              {!currentModel.outputs[
                                `${node.collection}.${node.asset_id}.${v}`
                              ] ? (
                                <Button
                                  // className='buttonexotic'
                                  style={{
                                    // ...buttonStyle,
                                    // background: 'teal',
                                    color: colourAccent,
                                    borderWidth: "revert",
                                    borderColor: colourAccent,
                                    borderStyle: "solid",
                                    width: "170px",
                                    height: "30px",
                                    // paddingRight: '10px',
                                    // ...buttonStyle, width: '120px'
                                  }}
                                  sx={{ mt: 1, mr: 1 }}
                                  onClick={() =>
                                    addFeature("outputs", {
                                      name: `${node.collection}.${node.asset_id}.${v}`,
                                      node,
                                      latitude,
                                      longitude,
                                    })
                                  }
                                >
                                  <AddCircleIcon /> &nbsp; OUTPUT Feature
                                </Button>
                              ) : (
                                <Button
                                  // className='buttonexotic'
                                  style={{
                                    // ...buttonStyle,
                                    background: colourAccent,
                                    color: "rgb(31 31 31 / 80%)",
                                    borderWidth: "revert",
                                    borderColor: "rgb(31 31 31 / 80%)",
                                    borderStyle: "solid",
                                    width: "170px",
                                    height: "30px",
                                    // paddingRight: '10px',
                                    // ...buttonStyle, width: '120px'
                                  }}
                                  sx={{ mt: 1, mr: 1 }}
                                  onClick={() =>
                                    removeFeature("outputs", {
                                      name: `${node.collection}.${node.asset_id}.${v}`,
                                      node,
                                      latitude,
                                      longitude,
                                    })
                                  }
                                >
                                  <RemoveCircleIcon /> &nbsp; OUTPUT Feature
                                </Button>
                              )}
                              {/* </div> */}
                            </Transvoider>
                          </div>
                        ) : null}

                        <div style={{ height: "5px", width: "100%" }} />
                      </div>
                    ))}
            </>}

            {node?.collection === 'NEX_AGGREGATOR' && data?.outputs && <>
              <div style={bannerStreamsStyle}>
                OUTPUT STREAMS
              </div>
              {Object.keys(data.outputs)
                .sort()
                .map(
                  (v, i) =>
                    v !== "timestamp" &&
                    data.outputs[v]?.length && (
                      <div style={{ paddingTop: "10px", width: '100%' }}>
                        <div
                          style={{
                            fontWeight: "bold",
                            marginTop: "0px",
                            marginBottom: "-25px",
                          }}
                        >
                          {" "}
                          {v.toUpperCase()}
                        </div>

                        <DCharts style={{ height: graphHeight, marginBottom: '0px' }}
                          title=''
                          datetimeArray={data.timestamp}
                          dataArray={[data.outputs[v].map(x => x || 0)]}
                          colors={['cyan']}
                          labels={['Base']}
                          update={update} />

                        {canvasMode && modelProgress === 1 ? (
                          <div
                            style={{
                              display: "inline-flex",
                              marginTop: "-20px",
                              marginBottom: "10px",
                            }}
                          >
                            <Transvoider
                              open={true}
                              behaviour={{
                                x: 0,
                                y: 0,
                                from: { scale: 0.8, opacity: 0 },
                                to: { scale: 1, opacity: 1 },
                              }}
                            >
                              {/* <div> */}
                              {!currentModel.inputs[
                                `${node.collection}.${node.asset_id}.${v}`
                              ] ? (
                                <Button
                                  style={{
                                    color: colourAccent,
                                    borderWidth: "revert",
                                    borderColor: colourAccent,
                                    borderStyle: "solid",
                                    width: "160px",
                                    height: "30px"
                                  }}
                                  onClick={() =>
                                    addFeature("inputs", {
                                      name: `${node.collection}.${node.asset_id}.${v}`,
                                      node,
                                      latitude,
                                      longitude,
                                    })
                                  }
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  <AddCircleIcon /> &nbsp; INPUT Feature
                                </Button>
                              ) : (
                                <Button
                                  // className='buttonexotic'
                                  style={{
                                    // ...buttonStyle,
                                    background: colourAccent,
                                    color: "rgb(31 31 31 / 80%)",
                                    borderWidth: "revert",
                                    borderColor: "rgb(31 31 31 / 80%)",
                                    borderStyle: "solid",
                                    width: "160px",
                                    height: "30px",
                                    // ...buttonStyle, width: '120px'
                                  }}
                                  onClick={() =>
                                    removeFeature("inputs", {
                                      name: `${node.collection}.${node.asset_id}.${v}`,
                                      node,
                                      latitude,
                                      longitude,
                                    })
                                  }
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  <RemoveCircleIcon /> &nbsp; INPUT Feature
                                </Button>
                              )}

                              {!currentModel.outputs[
                                `${node.collection}.${node.asset_id}.${v}`
                              ] ? (
                                <Button
                                  // className='buttonexotic'
                                  style={{
                                    // ...buttonStyle,
                                    // background: 'teal',
                                    color: colourAccent,
                                    borderWidth: "revert",
                                    borderColor: colourAccent,
                                    borderStyle: "solid",
                                    width: "170px",
                                    height: "30px",
                                    // paddingRight: '10px',
                                    // ...buttonStyle, width: '120px'
                                  }}
                                  sx={{ mt: 1, mr: 1 }}
                                  onClick={() =>
                                    addFeature("outputs", {
                                      name: `${node.collection}.${node.asset_id}.${v}`,
                                      node,
                                      latitude,
                                      longitude,
                                    })
                                  }
                                >
                                  <AddCircleIcon /> &nbsp; OUTPUT Feature
                                </Button>
                              ) : (
                                <Button
                                  // className='buttonexotic'
                                  style={{
                                    // ...buttonStyle,
                                    background: colourAccent,
                                    color: "rgb(31 31 31 / 80%)",
                                    borderWidth: "revert",
                                    borderColor: "rgb(31 31 31 / 80%)",
                                    borderStyle: "solid",
                                    width: "170px",
                                    height: "30px",
                                    // paddingRight: '10px',
                                    // ...buttonStyle, width: '120px'
                                  }}
                                  sx={{ mt: 1, mr: 1 }}
                                  onClick={() =>
                                    removeFeature("outputs", {
                                      name: `${node.collection}.${node.asset_id}.${v}`,
                                      node,
                                      latitude,
                                      longitude,
                                    })
                                  }
                                >
                                  <RemoveCircleIcon /> &nbsp; OUTPUT Feature
                                </Button>
                              )}
                            </Transvoider>
                          </div>
                        ) : null}

                        <div style={{ height: "5px", width: "100%" }} />
                      </div>
                    ))}
            </>}

            {node?.collection === 'NEX_AGGREGATOR' && <>
              <div style={bannerStreamsStyle}>
                AGGREGATED ASSETS
              </div>
              {node.aggregation_assets.map(x => <div className="pt-5">{x.toUpperCase()}</div>)}
            </>}

            {node?.collection === 'NEX_IMPORT' && node?.fileType === '.geojson' && <>
              <div style={bannerStreamsStyle}>
                FEATURE PROPERTIES
              </div>
              {node.data.features.map(x => <div className="pt-5">{x.toUpperCase()}</div>)}
            </>}

            {!node?.collection.includes('NEX_') && <>
              <div style={bannerStreamsStyle}>
                OUTPUT STREAMS
              </div>

              {Object.keys(data)
                .sort()
                .map(
                  (v, i) =>
                    v !== "timestamp" &&
                    data[v]?.length && (
                      <div style={{ paddingTop: "10px" }}>
                        <div
                          style={{
                            fontWeight: "bold",
                            marginTop: "0px",
                            marginBottom: "-25px",
                          }}
                        >
                          {" "}
                          {v.toUpperCase()}
                        </div>

                        <DCharts style={{ height: graphHeight, marginBottom: '0px' }}
                          title=''
                          datetimeArray={data["timestamp"].map((x) => new Date(x))}
                          dataArray={[data[v]]}
                          colors={['cyan']}
                          labels={['Base']}
                          update={update} />



                        {canvasMode && modelProgress === 1 ? (
                          <div
                            style={{
                              display: "inline-flex",
                              marginTop: "-20px",
                              marginBottom: "10px",
                            }}
                          >
                            <Transvoider
                              open={true}
                              behaviour={{
                                x: 0,
                                y: 0,
                                from: { scale: 0.8, opacity: 0 },
                                to: { scale: 1, opacity: 1 },
                              }}
                            >
                              {/* <div> */}
                              {!currentModel.inputs[
                                `${node.collection}.${node.asset_id}.${v}`
                              ] ? (
                                <Button
                                  // className='buttonexotic'
                                  style={{
                                    // ...buttonStyle,
                                    // background: 'teal',
                                    color: colourAccent,
                                    borderWidth: "revert",
                                    borderColor: colourAccent,
                                    borderStyle: "solid",
                                    width: "160px",
                                    height: "30px",
                                    // ...buttonStyle, width: '120px'
                                  }}
                                  onClick={() =>
                                    addFeature("inputs", {
                                      name: `${node.collection}.${node.asset_id}.${v}`,
                                      node,
                                      latitude,
                                      longitude,
                                    })
                                  }
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  <AddCircleIcon /> &nbsp; INPUT Feature
                                </Button>
                              ) : (
                                <Button
                                  // className='buttonexotic'
                                  style={{
                                    // ...buttonStyle,
                                    background: colourAccent,
                                    color: "rgb(31 31 31 / 80%)",
                                    borderWidth: "revert",
                                    borderColor: "rgb(31 31 31 / 80%)",
                                    borderStyle: "solid",
                                    width: "160px",
                                    height: "30px",
                                    // ...buttonStyle, width: '120px'
                                  }}
                                  onClick={() =>
                                    removeFeature("inputs", {
                                      name: `${node.collection}.${node.asset_id}.${v}`,
                                      node,
                                      latitude,
                                      longitude,
                                    })
                                  }
                                  sx={{ mt: 1, mr: 1 }}
                                >
                                  <RemoveCircleIcon /> &nbsp; INPUT Feature
                                </Button>
                              )}

                              {!currentModel.outputs[
                                `${node.collection}.${node.asset_id}.${v}`
                              ] ? (
                                <Button
                                  // className='buttonexotic'
                                  style={{
                                    // ...buttonStyle,
                                    // background: 'teal',
                                    color: colourAccent,
                                    borderWidth: "revert",
                                    borderColor: colourAccent,
                                    borderStyle: "solid",
                                    width: "170px",
                                    height: "30px",
                                    // paddingRight: '10px',
                                    // ...buttonStyle, width: '120px'
                                  }}
                                  sx={{ mt: 1, mr: 1 }}
                                  onClick={() =>
                                    addFeature("outputs", {
                                      name: `${node.collection}.${node.asset_id}.${v}`,
                                      node,
                                      latitude,
                                      longitude,
                                    })
                                  }
                                >
                                  <AddCircleIcon /> &nbsp; OUTPUT Feature
                                </Button>
                              ) : (
                                <Button
                                  // className='buttonexotic'
                                  style={{
                                    // ...buttonStyle,
                                    background: colourAccent,
                                    color: "rgb(31 31 31 / 80%)",
                                    borderWidth: "revert",
                                    borderColor: "rgb(31 31 31 / 80%)",
                                    borderStyle: "solid",
                                    width: "170px",
                                    height: "30px",
                                    // paddingRight: '10px',
                                    // ...buttonStyle, width: '120px'
                                  }}
                                  sx={{ mt: 1, mr: 1 }}
                                  onClick={() =>
                                    removeFeature("outputs", {
                                      name: `${node.collection}.${node.asset_id}.${v}`,
                                      node,
                                      latitude,
                                      longitude,
                                    })
                                  }
                                >
                                  <RemoveCircleIcon /> &nbsp; OUTPUT Feature
                                </Button>
                              )}
                            </Transvoider>
                          </div>
                        ) : null}

                        <div style={{ height: "5px", width: "100%" }} />
                      </div>
                    )
                )}

            </>}

            <div style={{ height: "6px", width: "100%" }} />
          </Box>
        </>
      )}

    </Box >
  );
}
