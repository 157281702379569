import { create } from "zustand";
import { persist, subscribeWithSelector } from 'zustand/middleware';
import * as API from "./apis/APIS";
import { get } from "immutable";
import { ConstantColorFactor } from "three";
import {
  CANVAS_STRUCTURE,
  MLMODEL_STRUCTURE,
  ACCOUNT_GLOBALS_STRUCTURE,
  AIWORKFLOW_STRUCTURE,
  NewID
} from "./datastructures/UserStructures";
import * as tf from '@tensorflow/tfjs';

export const DUMMYDATA = (name, length) => {
  return {
    name,
    data: Array.from({ length }, () => Math.floor(Math.random() * 100)),
  };
};

const DATALIST = {
  SCOOT: {
    active: true,
    disabled: false,
    layers: ["Metadata", "Timeseries"],
    info: {
      label: "Vehicle Speed Flow (Legacy)",
      category: "Traffic Sensor",
      description:
        "SCOOT Inductive Loops - road embedded sensors measuring vehicle speed and flow. 5 minute intervals.",
    },
    calls: { info: (callback) => API.SCOOTINFO(callback) },
  },
  AIRVIRO: {
    active: false,
    disabled: true,
    layers: ["Metadata", "Timeseries"],
    info: {
      label: "AirViro",
      category: "Air Quality Sensor",
      description:
        "AirViro Sensors - sensor stations measuring pollutants such as NO2, NOX, SO2, O2 and stuffs. Every second, probably.",
    },
    calls: { info: (callback) => API.AIRVIROIMPORT(callback) },
  },
  AIRLY: {
    active: false,
    disabled: true,
    layers: ["Metadata", "Timeseries"],
    info: {
      label: "Airly",
      category: "Air Quality Sensor",
      description:
        "Airly Sensors - sensor stations measuring pollutants such as NO2, NOX, SO2, O2 and stuffs. Every second, probably.",
    },
    calls: { info: (callback) => null },
  },
  RTEM: {
    active: false,
    disabled: true,
    layers: ["Metadata", "Live", "Timeseries"],
    info: {
      label: "Vehicle Counters",
      category: "Traffic Sensor",
      description:
        "RTEM Inductive Loops - road embedded sensors counting vehicles of categories HGV, LGV, cars, motorcycles, buses and stuffs. 5 minute intervals.",
    },
    calls: { info: (callback) => API.RTEMINFO(callback) },
  },
};

const DATASTATES = {
  RTEM: {
    info: {},
  },
  SCOOT: {
    info: {},
  },
  AIRVIRO: {
    info: {},
  },
  AIRLY: {
    info: {},
  },
};



export const apiDataStore = create((set, get) => ({
  datalist: DATALIST,
  datastates: DATASTATES,
  toggleActive: (key, value = null) => {
    const datalist = get().datalist;
    value = value === null ? !datalist[key].active : value;
    datalist[key].active = value;
    set((state) => ({ datalist: state.datalist }));
    if (value === true) get().loadInfoData(key);
  },
  loadInfoData: (key) => {
    const calls = get().datalist[key].calls;
    const datastates = get().datastates;
    calls.info((response) => {
      datastates[key] = { info: {} };
      datastates[key].info = response;
      set((state) => ({ datastates: state.datastates }));
    });
  },
}));

export const ctxMenuStore = create((set) => ({
  ctxMenuPos: [0, 0],
  setCtxMenuPos: (xy) => {
    set((state) => ({ ctxMenuPos: xy }));
  },
}));

export const deckPropsStore = create((set, get) => ({
  layers: {
    b3D: true,
    bPht: false,
    ostraff: false,
    labels: false,
    cubes: true,
  },
  views: false,
  controls: false,
  lens: false,
  settings: false,
  update: (value) => {
    set((state) => ({ ...value }));
  },
  toggleLens: (value = null) => {
    set((state) => ({ lens: value ? value : !state.lens }));
  },
  toggleViews: (value = null) => {
    set((state) => ({ views: value ? value : !state.views }));
  },
  toggleControls: (value = null) => {
    set((state) => ({ controls: value ? value : !state.controls }));
  },
  toggleProps: (key, value = null) => {
    const layers = get().layers;
    value = value === null ? !layers[key] : value;
    layers[key] = value;
    set((state) => ({ layers: state.layers }));
  },
}));




export const aiworkflow = create(
  subscribeWithSelector(
    persist((set, get) => ({
      ...AIWORKFLOW_STRUCTURE,
      _canvasModels: {},

      exportModel: async (modelToSave) => {
        async function toCanvasModelBlob(model) {
          const saveResults = await model.modelBinary.save(tf.io.withSaveHandler(async modelArtifacts => {

            console.log(modelArtifacts)

            let canvasmdl = { ...model, modelBinary: modelArtifacts }

            // Serialize the model topology and weight specs as a JSON string
            const jsonModel = JSON.stringify(canvasmdl);
            const jsonBuffer = new TextEncoder().encode(jsonModel);

            // Convert the length of the JSON string into a Uint32Array
            const jsonLengthBuffer = new Uint32Array([jsonBuffer.length]).buffer;

            // Create a Blob with the JSON length, the JSON data, and the binary weight data
            const modelBlob = new Blob([jsonLengthBuffer, jsonBuffer, modelArtifacts.weightData], { type: 'application/octet-binary' });
            return modelBlob;
          }));

          return saveResults;
        }

        // const model = await tf.loadLayersModel('path/to/your/model');

        var canvasMdl = null;

        if (typeof modelToSave === 'string') {
          canvasMdl = get().canvasModels[modelToSave];
        }
        else {
          canvasMdl = modelToSave;
        }

        console.log(canvasMdl)
        const modelBlob = await toCanvasModelBlob(canvasMdl);

        // Now you can download the blob or use it in your application
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = window.URL.createObjectURL(modelBlob);
        a.download = `${canvasMdl.name}.canvmdl`;
        a.click();
        window.URL.revokeObjectURL(a.href);
      },
      loadModel: async (file) => {
        await tf.ready()
        async function loadModelFromBlob(blob) {
          const arrayBuffer = await blob.arrayBuffer();

          // Assuming the first part is JSON and the second part is binary weight data
          const jsonLength = new Uint32Array(arrayBuffer.slice(0, 4))[0]; // The length of the JSON part
          const rawCanvasModel = new TextDecoder().decode(arrayBuffer.slice(4, 4 + jsonLength));
          var canvasModel = JSON.parse(rawCanvasModel)
          const weightsBlob = new Blob([arrayBuffer.slice(4 + jsonLength)], { type: 'application/octet-binary' });

          const modelArtifacts = {
            modelTopology: canvasModel.modelBinary.modelTopology,
            weightSpecs: canvasModel.modelBinary.weightSpecs,
            weightData: await weightsBlob.arrayBuffer(),
          };

          console.log(canvasModel, modelArtifacts)

          canvasModel.modelBinary = await tf.loadLayersModel(tf.io.fromMemory(modelArtifacts));

          return canvasModel
        }

        // Example usage: Assuming 'modelBlob' is the Blob of the saved model
        const canvasModel = await loadModelFromBlob(file);

        // REPOPULATE missing objects
        if (Object.keys(canvasModel.compile).length === 0)
          canvasModel.compile = MLMODEL_STRUCTURE.compile

        if (!canvasModel.epochs)
          canvasModel.epochs = MLMODEL_STRUCTURE.epochs



        var canvasModels = get().canvasModels;
        canvasModels[`${canvasModel.id}`] = canvasModel
        set({ canvasModels });
        console.log('aiworkflow new model', canvasModel.name)

        // Now you can use 'model' and 'canvasModel' in your application
      },
      updateModel: (model, props) => {
        if (model.name !== "") {
          var canvasModels = get().canvasModels;
          canvasModels[`${model.id}`] = { ...model, ...props };
          set({ canvasModels });
        } else {
          console.log("failed to save, model with empty name")
          // alert("failed to save, model with empty name");
        }
      },

      // Export a single aggregator by ID to a file
      exportAggregator: async (agg_id) => {
        const aggregators = get().aggregators;

        if (!agg_id || !aggregators[agg_id]) {
          console.error('Invalid aggregator ID or aggregator not found.');
          return;
        }

        // Get the specific aggregator
        const aggregator = { [agg_id]: aggregators[agg_id] };

        // Convert the aggregator to a JSON string
        const aggregatorJSON = JSON.stringify(aggregator, null, 2);

        // Create a Blob and download it as a file
        const blob = new Blob([aggregatorJSON], { type: 'application/json' });
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = `${agg_id}.canvagg`; // File name based on aggregator ID
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(a.href);
        console.log(`Aggregator ${agg_id} exported successfully.`);
      },

      // Import a single aggregator from a file
      loadAggregator: async (file) => {
        if (!file) {
          console.error('No file provided for import.');
          return;
        }

        try {
          // Read the file content
          const fileContent = await file.text();

          // Parse the JSON content
          const importedAggregator = JSON.parse(fileContent);

          // Validate the imported structure (must have a single aggregator object)
          const [agg_id, agg_data] = Object.entries(importedAggregator)[0] || [];
          if (!agg_id || typeof agg_data !== 'object') {
            console.error('Invalid file format for aggregator.');
            return;
          }

          // Add the imported aggregator to the store
          const existingAggregators = get().aggregators;
          set({
            aggregators: {
              ...existingAggregators,
              [agg_id]: agg_data,
            },
          });
          console.log(`Aggregator ${agg_id} imported successfully.`);
        } catch (error) {
          console.error('Error importing aggregator:', error);
        }
      },

      // trainingProgress:(tProgress) => set({tProgress}),
      setCurrentNexFocus: (id) => set({ currentNexFocus: id }),
      setModelProgress: (value) => set({ modelProgress: value }),
      setCanvasMode: (value) => set({ canvasMode: value }),
      setFocusNode: (value) => set({ focusNode: value }),
      // setNextCanvasState: () => set((state) => ({canvasState: get().canvasState +1 })),
      // setCanvasState: (value) => set({ canvasState: value }),
      setTrainingStage: (value) => set({ trainingStage: value }),
      // addGeojsonImports: (name, data) =>
      //   set({ geojsonImports: { ...get().geojsonImports, [name]: data } }),
      // addCsvImports: (name, data) =>
      //   set({ csvImports: { ...get().csvImports, [name]: data } }),
      addAggregator: (name, value) =>
        set({ aggregators: { ...get().aggregators, [name]: value } }),
      removeAggregator: (name) => {
        var aggregators = get().aggregators;
        delete aggregators[name];
        set({ aggregators });
      },
      addImport: (name, data) =>
        set({ imports: { ...get().imports, [name]: data } }),
      removeImport: (modelId) => {
        var imports = get().imports;
        delete imports[modelId];
        set({ imports });
      },

      removeModel: (modelId) => {
        var canvasModels = get().canvasModels;
        delete canvasModels[modelId];
        set({ canvasModels });
      },
      setImportVisibility: (modelId, value) => {
        var imports = get().imports;
        imports[modelId].visible = value ? value : !imports[modelId].visible;
        set({ imports });
      },
      setAggregatorVisibility: (name, value) => {
        var aggregators = get().aggregators;
        aggregators[name].visible = value ? value : !aggregators[name].visible;
        set({ aggregators });
      },
      duplicateCanvasModel: (id) => {
        let originalModel = get().canvasModels[id]
        let newModel = {};

        Object.keys(originalModel).forEach(k => {
          if (typeof originalModel[k] !== 'function') {
            newModel[k] = structuredClone(originalModel[k])
          }
        })

        newModel.id = NewID("community")
        newModel.created = new Date().toLocaleString()
        newModel.name = newModel.name + "_COPY"
        newModel.modelBinary = null

        console.log(newModel)

        get().updateModel(newModel)

        return newModel.id
      }
    }),
      {
        name: "ai-workflow",
        partialize: (state) => ({ aggregators: state.aggregators }),
        onRehydrateStorage: () => {
          console.log('Rehydration started');
          return (state, error) => {
            if (error) {
              console.error('Rehydration error:', error);
            } else {
              console.log('Rehydration complete', state);
              // Perform actions after the state is rehydrated
            }
          };
        }
      }
    )));


// const unsubscribe = aiworkflow.subscribe(
//   (state) => state.canvasModels, // Selector: listen to the 'count' property
//   (canvasModels) => {
//     console.log('Count changed:', canvasModels);
//   }
// );

export const currentModelStore = create((set, get) => ({
  ...MLMODEL_STRUCTURE,
  // modelSize: 1,
  // inputs: [],
  // outputs:[],
  // tProgress: 0,

  // modelHash: () => {
  //   let modelHash = ""
  // },
  setScope: (scope) => set({ scope: scope }),
  setEpochs: (epochs) => set({ epochs: epochs }),
  setDrops: (drops) => set({ trainingData: { ...(get().trainingData), drops: drops } }),
  setEmbeddings: (embeddings) => set({ trainingData: { ...(get().trainingData), embeddings: embeddings } }),
  setLayers: (value) => set({ layers: value }),
  setCompileOptions: (value) => set({ compile: value }),
  setTrainingDataEmbeddings: (embed) =>
    set({ algorithm: { ...get().algorithm, embeddings: embed } }),
  saveModelBinary: (modelBin) => set({ modelBinary: modelBin }),
  // saveModelBinary: (modelBin) => set({ modelBinary: new TextEncoder().encode(JSON.stringify(modelBin)) }),
  // setMlConfig: (value) => set({ mlConfig: value }),
  clearModel: (newprops) =>
    set({ ...MLMODEL_STRUCTURE, inputs: {}, outputs: {}, id: NewID("community"), ...newprops, created: new Date().toLocaleString() }),
  // newModel:()=>{get().clearModel()},
  addFeature: (space, feature) => {
    set({ [space]: { ...get()[space], [feature.name]: feature } });
    get().getCoordinates();
    get().saveModelBinary(null)
    // get().getSize()
  },
  updateLastTrainedOn: (datetime) => set({ lastTrainedOn: datetime }),
  setCompileOptions: () => set({ compile: {} }),
  setModelInterval: (value) => set({ modelInterval: value }),
  setAlgorithm: (value) =>
    set({ algorithm: { ...get().algorithm, algo: value } }),
  setAlgorithmConfig: (value) =>
    set({ algorithm: { ...get().algorithm, config: value } }),
  removeFeature: (space, feature) => {
    const newset = get()[space];
    delete newset[feature.name];
    set({ [space]: newset });
    get().getCoordinates();
    get().saveModelBinary(null)
    // get().getSize()
  },
  updateAccuracy: (value) =>
    set({ trainingData: { ...get().trainingData, lastAccuracy: value } }),
  setDataStart: (value) =>
    set({ trainingData: { ...get().trainingData, dataStart: value } }),
  setDataEnd: (value) =>
    set({ trainingData: { ...get().trainingData, dataEnd: value } }),
  getCoordinates: (recalculate = true) => {
    if (recalculate) {
      const outps = get().outputs;

      let latitude = 0
      let longitude = 0
      let count = 0

      // const outputPos = Object.keys(outps).reduce(
      //   (newObj, key, i) => {
      //     newObj["latitude"] = newObj["latitude"] + outps[key].latitude;
      //     newObj["longitude"] = newObj["longitude"] + outps[key].longitude;
      //     newObj["count"] = newObj["count"] + 1;
      //     return newObj;
      //   },
      //   { latitude: 0, longitude: 0, count: 0 }
      // );

      // outputPos["latitude"] = outputPos["latitude"] / outputPos["count"];
      // outputPos["longitude"] = outputPos["longitude"] / outputPos["count"];
      // // console.log(outputPos)

      // const inps = get().inputs;
      // const inputPos = Object.keys(inps).reduce(
      //   (newObj, key, i) => {
      //     newObj["latitude"] = newObj["latitude"] + inps[key].latitude;
      //     newObj["longitude"] = newObj["longitude"] + inps[key].longitude;
      //     newObj["count"] = newObj["count"] + 1;
      //     return newObj;
      //   },
      //   { latitude: 0, longitude: 0, count: 0 }
      // );
      // inputPos["latitude"] = inputPos["latitude"] / inputPos["count"];
      // inputPos["longitude"] = inputPos["longitude"] / inputPos["count"];
      // // console.log(inputPos)

      // const res = [
      //   (outputPos["longitude"] + inputPos["longitude"]) / 2,
      //   (outputPos["latitude"] + inputPos["latitude"]) / 2,
      // ];

      Object.values(get().inputs).forEach((v) => {
        latitude += v["latitude"]
        longitude += v["longitude"]
        count++
      })

      Object.values(get().outputs).forEach((v) => {
        latitude += v["latitude"]
        longitude += v["longitude"]
        count++
      })

      const res = count === 0 ? MLMODEL_STRUCTURE.coordinates : [
        longitude / count,
        latitude / count,
      ];

      set({ coordinates: res });

      return res;
    }
    else {
      return get().coordinates;
    }
  },
  setCurrentModel: (model) => {
    set({ ...model });
    return get();
  },
}));

export const globalStore = create((set, get) => ({
  ...ACCOUNT_GLOBALS_STRUCTURE,
  zoomScope: 'local',
  progress: -1,
  aggregator: { radius: 400, latitude: null, longitude: null, enabled: null },
  setAggregator: (value) => set({ aggregator: value }),
  setZoomScope: (value) => set({ zoomScope: value }),
  setProgress: (value) => set({ progress: value }),
  setPredictionResults: (result) => set({ predictions: result }),
  setMerlinConvo: (state) => set({ merlinConvo: state }),
  setUrlParams: (value) => set({ urlParams: value }),
  setMerlinWard: (value) => set({ merlinWard: value }),
  updateLayers: (name, layer) => {
    if (layer) {
      const layers = get().layers;
      layers[name] = layer;
      set({ layers: layers });
    } else {
      const nestlayers = get().layers;
      delete nestlayers[name];
      set({ layers: nestlayers });
    }
  },
  updateLayersVisibility: (name, bool) => {
    const layers = get().layersVisibility;
    layers[name] = bool ? bool : !layers[name];
    set({ layersVisibility: layers });
  },
  setShowMeta: (value) => set({ showMeta: value ? value : !get().showMeta }),
  setMerlinAssist: (value) =>
    set({ merlinAssist: value ? value : !get().merlinAssist }),
  setPage: (value = null) => {
    set((state) => ({ page: value ? value : "home" }));
  },

  setLight: (value) => set({ light: value }),

  setAssets: (assets) => set({ assets: assets }),

  setCollections: (collections) => set({ collections: collections }),
  setCollectionVisibility: (coll, value) => {
    let colley = get().collections[coll];
    // console.log('before visibility', colley)
    // colley = { ...colley, visible: value? value:!colley.visible }
    colley.visible = value ? value : !colley.visible;
    // console.log('after visibility', colley)
    const newCllections = { ...get().collections, [coll]: colley };
    // console.log('new visibility', newCllections)
    set({ ...newCllections });
  },

  setInit: (value) => set({ init: value }),
  setAppLaunchable: (value) => set({ appLaunchable: value }),
  setWindowDimensions: (value) => set({ windowDimensions: value }),
  setWindowOpen: (value) => set({ windowOpen: value }),
  setPeerConnection: (value) => set({ peerConn: value })

  // setTrainingData: (value, model) => set({ trainingData }),
  // updateState: (state) => set({ state })
}));
