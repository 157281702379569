import React, { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { ToggleButtonGroup, ToggleButton, Button, IconButton, Tooltip, createTheme, ThemeProvider, TextField } from '@mui/material';
import { headStyle, hrStyle, buttonStyle, headStyleInverted, backgroundStyle, lightColor, verticalCenter, colourAccent } from '../../css/styling';
import * as API2 from '../states';
import Papa from 'papaparse';
import { twMerge } from "tailwind-merge";
import { AnimatePresence, color, motion } from "framer-motion";
import { NewID } from "../datastructures/UserStructures";
import TButton from '../dElements/TButton';
import { DButton } from "../dElements/DButton";
import { DInput } from "../dElements/DInput";
import TInput from "../dElements/TInputFile";
import TInputFile from "../dElements/TInputFile";
import TInputText from "../dElements/TInputText";
// import { M } from "ospoint";
// import { isNumeric } from "mathjs";
const { DateTime } = require("luxon");

const parseDate = (dateInput) => {
    if (typeof dateInput === "number") {
        if (dateInput.toString().length === 10) {
            return DateTime.fromSeconds(dateInput);
        } else if (dateInput.toString().length === 13) {
            return DateTime.fromMillis(dateInput);
        }
    }

    if (typeof dateInput === "string") {
        let date = DateTime.fromISO(dateInput, { setZone: true });
        if (date.isValid) return date;
        date = DateTime.fromJSDate(new Date(dateInput));
        if (date.isValid) return date;
    }

    return DateTime.invalid("Invalid date format");
}

const isNumeric = (str) => {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export default function FileImport({ style, coordinates, close, focusCb }) {

    const [fileType, setFileType] = useState('.csv');
    const [dataName, setDataName] = useState('');
    const { csvImports,
        // addCsvImports, geojsonImports, addGeojsonImports, 
        addImport } = API2.aiworkflow();
    const [data, setData] = useState(null);
    const [keyCol, setKeyCol] = useState(null)
    const [dataCols, setDataCols] = useState([]);
    const [id, setId] = useState(NewID('community'))
    const [scope, setScope] = useState('asset');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState(null)

    const valid = useMemo(() => {
        if (!dataName) return false

        switch (fileType) {
            case ".csv":
                let test = [...dataCols]
                test[keyCol] = null
                return test.filter(x => x !== null).length > 0 && dataName
            case ".geojson":
                return true
            default:
                console.log('error: file import type defaulted')
                return false
        }
    }, [JSON.stringify(dataCols), keyCol, dataName, data])

    const geojsonFeatures = useMemo(() => {
        let _dataCol = data?.features?.map(f => Object.keys(f.properties)).flat()
        _dataCol = Array.from(new Set(_dataCol))
        console.log(_dataCol)
        return _dataCol
    }, [data])

    const nodeData = useMemo(() => {
        let _nodeData = {}

        switch (fileType) {
            case '.csv':
                _nodeData = { timestamp: [] }

                dataCols.forEach((v, i) => {
                    if (i !== keyCol && dataCols[i] !== null) {
                        // console.log('timecol')
                        _nodeData[dataCols[i]] = []
                    }
                })

                data?.forEach((row, rowIndex) => {
                    Object.values(row)
                        .forEach((cell, cellIndex) => {
                            if (dataCols[cellIndex] !== null && cellIndex !== keyCol) {
                                _nodeData[dataCols[cellIndex]].push(Number(cell))
                            } else if (cellIndex === keyCol) {
                                try {
                                    // console.log(cell, parseDate(cell).toJSDate().toISOString())
                                    _nodeData.timestamp.push(parseDate(cell).toJSDate().toISOString())
                                } catch (error) {
                                    // alert(cell)
                                    console.log('error on cell', cell)
                                }

                            }
                        })
                })

                // console.log(_nodeData)
                break;
            case '.geojson':
                _nodeData = { geojson: data, features: geojsonFeatures }

                console.log(_nodeData)
                break;
        }

        return _nodeData
    }, [data, JSON.stringify(dataCols), keyCol])


    const node = useMemo(() => {
        // console.log(coordinates)
        let node = {
            id: id,
            // timestamp: [], 
            // data: dataCols,
            collection: 'NEX_IMPORT',
            asset_id: dataName,
            coordinates: coordinates,
            fileType: fileType,
            file: file,
            meta: {
            },
            scope: scope,
            links: [],
            inputs: {},
            outputs: {},
            description: description,
            data: nodeData,
            visible: true,
            actions: {
                fetchData: (dataSource, startDate, endDate) => {
                    const start = new Date(startDate);

                    // Determine the indices of the dates we want to keep
                    const filteredIndices = dataSource.timestamp
                        .map((tstamp, index) => ({ date: new Date(tstamp), index }))
                        .filter(({ date }) => {
                            if (endDate) {
                                const end = new Date(endDate);
                                return date >= start && date <= end;
                            } else {
                                // Match only the same day if endDate is not provided
                                return (
                                    date.getUTCFullYear() === start.getUTCFullYear() &&
                                    date.getUTCMonth() === start.getUTCMonth() &&
                                    date.getUTCDate() === start.getUTCDate()
                                );
                            }
                        })
                        .map(({ index }) => index);

                    // Create a new filtered data object based on the indices
                    const filteredData = {};
                    for (const key in dataSource) {
                        filteredData[key] = filteredIndices.map(index => dataSource[key][index]);
                    }

                    return filteredData;
                }
            }
        }
        return node
    }, [dataName, coordinates, nodeData])



    const clearToDefaults = () => {
        setData(null)
        setKeyCol(null)
        setDataCols([])
        setScope('asset')
        setDescription('')
        setId(NewID('community'))
    }

    const handleFile = (e) => {
        const content = e.target.result;
        // console.log('file content', content);

        try {
            setData(JSON.parse(content))
            // alert('file loaded')
        } catch (error) {
            alert('error', error)
        }
    }

    const handleChangeFile = (target) => {

        if (target.files?.[0]) {

            var file = target.files[0]
            setFile(file)

            switch (fileType) {
                case '.geojson':
                    let fileData = new FileReader();
                    fileData.onloadend = handleFile;
                    fileData.readAsText(file)
                    // console.log(fileData)
                    setData(fileData.result);
                    break;
                case '.csv':
                    Papa.parse(file, {
                        header: true,
                        skipEmptyLines: true,
                        complete: (result) => {
                            setData(result.data); // Set parsed data to state
                            console.log('new headers', result)
                            setDataCols(Object.keys(result.data[0]).map(c => null));
                        }
                    });

                    break;
            }
        }
        else {
            console.log('no fil selected?')
        }
    }



    return (<Box className="mx-[25px]">

        {/* <Tooltip title="restart">
            <RestartAltIcon
                style={{ top: 10, right: '45px', position: 'absolute', cursor: 'pointer' }}
                onClick={() => {
                    clearModel();
                    setCanvasState(0)
                }} />
        </Tooltip>
        <Tooltip title="save Model">
            <SaveAltIcon
                style={{ top: 10, right: '80px', position: 'absolute', cursor: 'pointer' }}
                onClick={() => { updateModels(currentModel); getCoordinates() }} />
        </Tooltip> */}
        {/* <Tooltip title="location">
            <IconButton
                style={{ top: 10, fontSize:14, padding:0,width:'100%', left: '0px', position: 'absolute', cursor: 'pointer', color:'white', opacity:0.1 }}
            >{coordinates && coordinates.join(', ')}</IconButton>
        </Tooltip> */}
        {/* 
            <Tooltip title="Save Model">
                {JSON.stringify(canvas)}
                <SaveAltIcon
                    style={{ right: '160px', position: 'absolute', cursor: 'pointer' }}
                    onClick={() => updateModels(currentModel)} />
            </Tooltip> */}
        {/* <DragIndicatorIcon style={{ top: 10, right: '15px', position: 'absolute', cursor: 'grab' }} /> */}


        <div >
            {/* <span>Import Type</span>
            <br /> */}
            <div className="rounded-md bg-fadeBlackLighter w-min backdrop-blur-xl" >
                <div className="container mx-auto p-6 grid grid-cols-4 gap-3 w-[600px] ">
                    <span className="text-right self-center pr-10">FILETYPE: </span>
                    <div className="container mx-auto grid grid-cols-2 gap-4 w-[100%] col-span-3">
                        <IconButton style={{ borderRadius: '5px', width: '100%', color: colourAccent, opacity: fileType === '.csv' ? 1 : 0.2 }} onClick={() => { setFileType('.csv'); clearToDefaults() }} >CSV</IconButton>
                        <IconButton style={{ borderRadius: '5px', width: '100%', color: colourAccent, opacity: fileType === '.geojson' ? 1 : 0.2 }} onClick={() => { setFileType('.geojson'); clearToDefaults() }}>GEOJSON</IconButton>
                    </div>

                    {/* </div> */}
                    {/* <IconButton style={{ color: fileType === '.api' ? 'cyan' : 'white' }} onClick={() => setFileType('.api')} >API</IconButton> */}
                    {/* <IconButton>Json</IconButton> */}

                    {/* <br />


            <div className="w-[500px] grid grid-flow-row-dense grid-cols-4 pt-5" > */}

                    {/* <TButton className='inline' active={scope === 'asset'} onClick={() => setScope('asset')}>Asset</TButton>
<TButton className='inline' active={scope === 'regional'} onClick={() => setScope('regional')}>Regional</TButton>
<TButton className='inline' active={scope === 'national'} onClick={() => setScope('national')}>National</TButton> */}
                    {/* 

</div>

            <div className="w-[500px] grid grid-flow-row-dense grid-cols-4"> */}
                    <span className="text-right self-center pr-10">SOURCE: </span>
                    <div className="col-span-3">
                        <TInputFile
                            className='self-center'
                            key={id}
                            type="file"
                            // style={{ width: '300px', marginTop: '5px', padding: '6px', borderRadius: '4px', background: backgroundStyle.backgroundColor, color: 'white' }}
                            accept={fileType}
                            onChange={e => handleChangeFile(e.target)} />
                    </div>
                    {/* </div>

            <br />

            <br />

            <div className="w-[500px] grid grid-flow-row-dense grid-cols-4 gap-3"> */}

                    <span className="text-right self-center pr-10">SCOPE: </span>

                    <IconButton style={{ borderRadius: '5px', height: '30px', width: '100%', color: 'cyan', opacity: scope === 'asset' ? 1 : 0.2 }} onClick={() => setScope('asset')} >Local</IconButton>
                    <IconButton style={{ borderRadius: '5px', height: '30px', width: '100%', color: 'cyan', opacity: scope === 'regional' ? 1 : 0.2 }} onClick={() => setScope('regional')} >Regional</IconButton>
                    <IconButton style={{ borderRadius: '5px', height: '30px', width: '100%', color: 'cyan', opacity: scope === 'national' ? 1 : 0.2 }} onClick={() => setScope('national')} >National</IconButton>



                    <span className="text-right self-center pr-10" >ASSET NAME: </span>
                    <TInputText
                        className="col-span-3 w-[100%] bg-transparent"
                        type="text"
                        // style={{ width: '100%', marginLeft: '5px', marginTop: '5px', padding: '6px', borderRadius: '3px', background: backgroundStyle.backgroundColor, color: 'white' }}
                        value={dataName}
                        onChange={e => {
                            e.target.value = e.target.value.toUpperCase().replace('.', '_').replace(' ', '_')
                            const regex = /^[0-9a-zA-Z_.\[\](\-)]+$/;
                            if (e.target.value.toUpperCase().match(regex)) {
                                setDataName(e.target.value)
                            }
                        }} />


                    <span className="text-right self-center pr-10" >DESCRIPTION: </span>
                    <TInputText
                        className="col-span-3 w-[100%] bg-transparent"
                        type="text"
                        placeholder='(OPTIONAL)'
                        // style={{ width: '100%', marginLeft: '5px', marginTop: '5px', padding: '6px', borderRadius: '3px', background: backgroundStyle.backgroundColor, color: 'white' }}
                        value={description}
                        onChange={e => {
                            setDescription(e.target.value)
                        }} />






                    <div className="col-span-4">
                        <IconButton
                            // className='rounded-sm'
                            disabled={!valid}
                            style={{ color: !valid ? 'grey' : 'cyan', width: '200px' }}
                            onClick={() => {
                                switch (fileType) {
                                    case '.geojson':
                                        console.log('adding import geojson', id, node)
                                        addImport(id, node)
                                        break;
                                    case '.csv':
                                        console.log('adding import csv', id, node)
                                        addImport(id, node)
                                        break;
                                }
                                console.log(node)

                                focusCb && focusCb(node)
                                close && close()
                            }}>Create Node</IconButton>
                    </div>
                </div>




            </div>


            <div
                className="overflow-x-auto overflow-y-auto h-[100%] w-[96vw] p-4"
                onContextMenu={e => e.preventDefault()}
            >

                {/* {JSON.stringify(dataCols)} */}

                <AnimatePresence>
                    {fileType === '.csv' && data?.length > 0 && <motion.div>

                        <p className="py-4 text-diatomicCyan">Select columns to include as data streams. Right click a date-time column to set as timestamp. If nothing changes, the column is likely invalid.</p>

                        <br />
                        <div style={{ overflowX: 'auto', width: '95%' }}>
                            <table
                                className="border border-collapse border-slate-500"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.2 }}
                            >
                                <thead>
                                    <tr>
                                        {Object.keys(data[0]).map((header, index) => (
                                            <th className={twMerge("border border-slate-500 bg-warmGray-600 hover:bg-diatomicDarkCyan text-nowrap px-8 py-2 cursor-pointer", index === keyCol ? "bg-yellow-500 hover:bg-yellow-200" : (dataCols[index] !== null ? "bg-green-700 hover:bg-green-400" : ""))} key={index}
                                                onClick={() => {

                                                    // ADD AND TEST CONVERSION
                                                    // let test = isNumeric(Object.values(data[0])[index])
                                                    let _dataCol = Object.values(data).map((d, i) => isNumeric(Object.values(d)[index]))
                                                    let test = _dataCol.every(x => x)

                                                    // console.log(_dataCol, test)

                                                    // console.log('loll', Object.values(data[0])[index], typeof Object.values(data[0])[index])

                                                    if (test) {
                                                        dataCols[index] = dataCols[index] === header ? null : header
                                                        setDataCols(dataCols)
                                                        console.log('Conversion test passed, Numbers')
                                                    }
                                                    else {
                                                        console.log('Failed conversion, Expected Numbers')
                                                    }
                                                }}
                                                onContextMenu={(e) => {
                                                    // console.log('right click', Object.values(data[0])[index])
                                                    // let test = parseDate(Object.values(data[0])[index]);

                                                    let _dataCol = Object.values(data).map((d, i) => {
                                                        try {
                                                            return parseDate(Object.values(d)[index]).toJSDate().toISOString()
                                                        } catch (error) {
                                                            return false
                                                        }

                                                    })
                                                    let test = _dataCol.every(x => x)



                                                    if (test) {
                                                        setKeyCol(index)
                                                        console.log('Conversion test passed, Datetime')
                                                    }
                                                    else {
                                                        console.log('Failed conversion, Expected Datetime')
                                                    }
                                                }}
                                            >
                                                {header}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.slice(0, 5).map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                            {Object.values(row).map((cell, cellIndex) => (
                                                <td className={twMerge("border text-center border-slate-500 bg-warmGray-600 bg-opacity-20 hover:bg-diatomicDarkCyan text-nowrap px-8 py-2 cursor-pointer", cellIndex === keyCol ? "bg-yellow-500 hover:bg-yellow-200" : (dataCols[cellIndex] !== null ? "bg-green-700 hover:bg-green-400" : ""))} key={cellIndex}>{cell}</td>
                                            ))}
                                        </tr>
                                    ))}
                                    {data.slice(0, 2).map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                            {Object.values(row).map((cell, cellIndex) => (
                                                <td className={twMerge("border text-center border-slate-500 bg-warmGray-600 bg-opacity-20 hover:bg-diatomicDarkCyan text-nowrap px-8 py-2 cursor-pointer", cellIndex === keyCol ? "bg-yellow-500 hover:bg-yellow-200" : (dataCols[cellIndex] !== null ? "bg-green-700 hover:bg-green-400" : ""))} key={cellIndex}>...</td>
                                            ))}
                                        </tr>
                                    ))}
                                    {data.slice(Math.max(data.length - 5, 0)).map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                            {Object.values(row).map((cell, cellIndex) => (
                                                <td className={twMerge("border text-center border-slate-500 bg-warmGray-600 bg-opacity-20 hover:bg-diatomicDarkCyan text-nowrap px-8 py-2 cursor-pointer", cellIndex === keyCol ? "bg-yellow-500 hover:bg-yellow-200" : (dataCols[cellIndex] !== null ? "bg-green-700 hover:bg-green-400" : ""))} key={cellIndex}>{cell}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </motion.div>}

                    {/* {data?.[0] && Object.keys(data[0]).map((header, index) =>
                        <motion.div>
                            {header}
                        </motion.div>)} */}

                </AnimatePresence>

                <AnimatePresence>
                    {fileType === '.geojson' && data && <motion.div>
                    </motion.div>}
                </AnimatePresence>


            </div>
        </div>
    </Box >
    )
}