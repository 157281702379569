import { BorderColor, FunctionsRounded } from "@mui/icons-material";
import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import {
    headStyle,
    hrStyle,
    buttonStyle,
    headStyleInverted,
    backgroundStyle,
    lightColor,
    verticalCenter,
    colourAccent,
    colourAccentSecondary,
} from "../../css/styling";
import { IconButton, Button, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import HexagonIcon from "@mui/icons-material/Hexagon";
import InfoIcon from "@mui/icons-material/Info";
import TagIcon from "@mui/icons-material/Tag";
import InputIcon from "@mui/icons-material/Input";
import OutputIcon from "@mui/icons-material/Output";
import * as API2 from "../states";
import * as API from "../apis/APIWeb";
import { LineChart } from "@mui/x-charts/LineChart";
import Box from "@mui/material/Box";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import WidthNormalIcon from '@mui/icons-material/WidthNormal';
import {
    Transitioner,
    Transvoider,
    spanArray,
    spanArrayStringEqualSplit,
} from "../dElements/Animations";
import { Trail } from "@react-spring/web";
import { Tooltip, Grid } from "@mui/material";
import { DateTimeRangePickerPopper } from "../dElements/DateTimeRangePicker";
import { tensorPredictDynamic, _toTensorsMatrix } from "../mlmethods/tensorflow";
import { canvasFetchData, canvasFetchModelData } from "../apis/APIWeb";
import DownloadIcon from '@mui/icons-material/Download';
import * as tf from '@tensorflow/tfjs';
import { DButton } from "../dElements/DButton";
import { exportCSV } from "../dElements/ExportCSV";
import DCharts from "../dElements/DCharts";
import { AnimatePresence, motion } from "framer-motion";
import { create } from 'zustand';
import { DInput } from "../dElements/DInput";
import TInputText from "../dElements/TInputText";
import TButton from "../dElements/TButton";
import TDropdown from "../dElements/TDropdown";
import { twMerge } from "tailwind-merge";
import { NewID } from "../datastructures/UserStructures";

const widthStates = [400, 600, 800, 1000, 1200, 1400]

// export const useRadiusStore = create((set) => ({
//     radius: 0,
//     setRadius: (radius) => set({ radius }),
// }));

export default function Aggregator({ assetFiltered, focusCb, lat, lon, close }) {
    const { colourAccent, colourAccentSecondary, borderRadius } = API2.globalStore();
    const { setCanvasMode, addAggregator, aggregators, setModelProgress } = API2.aiworkflow();
    const [width, setWidth] = useState(4);
    const [step, setStep] = useState(1);
    const [inputCollectionValue, setInputCollectionValue] = useState('');
    const [inputStreamValue, setInputStreamValue] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    const [name, setName] = useState('');
    const [collection, setCollection] = useState('');

    const [coordinates, setCoordinates] = useState({lat: lat, long: lon})

    const [aggregation, setAggregation] = useState([])
    const [sampleData, setSampleData] = useState(null);
    const [update, setUpdate] = useState(Date.now());

    const [aggOp, setAggOp] = useState('avg');
    const [id, setId] = useState(NewID('community'))

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    // const setRadius = useRadiusStore((state) => state.setRadius);

    // if (step !== 1) return null;

    // useEffect(() => setAggregator({ ...aggregator, latitude: lat, longitude: lon }), [])

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     const numValue = parseFloat(inputValue);
    //     if (!isNaN(numValue) && numValue >= 0) {
    //         setAggregator({ ...aggregator, radius: numValue })
    //         setIsOpen(false);
    //     }
    // };

    // const handleCollectionSubmit = (e) => {
    //     e.preventDefault();
    //     setInputCollectionValue(e.target.value)
    // };


    // const handleStreamSubmit = (e) => {
    //     e.preventDefault();
    //     setInputStreamValue(e.target.value)
    // };


    const filteredByCollection = useMemo(() => {
        let assetFilteredStringied = assetFiltered.map(x => JSON.stringify(x).toLowerCase())
        return assetFilteredStringied.filter(x => x.includes(inputCollectionValue) && x.includes(collection))
    }, [inputCollectionValue, collection])


    const nexAgg = useMemo(() => ({
        id: id,
        asset_id: name,
        aggregation_assets: aggregation.map(x => x.toUpperCase()),
        aggregation_collection: collection,
        collection: "NEX_AGGREGATOR",
        agg_operation: aggOp,
        coordinates: coordinates,
        meta: {
            operation: aggOp,
            // agg_assets: aggregation.map(x => x.toUpperCase())
        }
    }), [name, aggregation, aggOp])


    useEffect(() => {
        setTimeout(() => setUpdate(Date.now()), 500)
    }, [sampleData])

    useEffect(() => {
        const exec = async () => {
            let result = await API.fetchAggregateData(collection, aggregation.map(x => x.toUpperCase()), startDate, endDate, aggOp, 60)
            setSampleData(result)
        }
        exec()
    }, [startDate, endDate, aggregation, aggOp])

    return (<Box
        style={{
            ...backgroundStyle,
            zIndex: 1,
            borderRadius: borderRadius,
            maxHeight: "calc(100vh - 140px)",
            width: `${widthStates[width]}px`,
            padding: '2px'
        }}
    >
        <div
            className="absolute cursor-grab z-[2] mt-2.5 right-0 pr-4"
            style={{
                // marginLeft: `calc(${widthStates[width]}px - 25px)`
            }}
        >
            <DragIndicatorIcon />
        </div>

        <div
            className="overflow-y-hidden bg-defaultback rounded-tl rounded-bl h-[80vh]"
            style={{
                color: colourAccent
            }}
        >
            <div
                className="pt-8 px-6 pb-2 font-[12px] bg-fadeBlack grid grid-cols-12 gap-2"
            >
                <div className="col-span-2">
                    <div style={{ height: '5px' }} />
                    <TInputText
                        className='w-full'
                        type="text"
                        value={name}
                        onChange={(e) => {
                            e.target.value = e.target.value.toUpperCase().replace('.', '_').replace(' ', '_')
                            const regex = /^[0-9a-zA-Z_.\[\](\-)]+$/;
                            if (e.target.value.toUpperCase().match(regex)) {
                                setName(e.target.value)
                            }
                        }}
                        placeholder="Aggregator name"
                        min="0"
                        step="any"
                        required
                    />
                </div>


                {/* 
                    <div className="col-span-2">
                        Collection
                        <div style={{ height: '5px' }} />
                        <TInputText
                            type="string"
                            id="collection"
                            value={collection}
                            onChange={(e) => setCollection(e.target.value)}
                            // className="w-full px-3 py-2 border bg-defaultback border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-diatomicCyan"
                            placeholder="Collection to aggregate"
                            min="0"
                            step="any"
                            required
                        />
                        <TDropdown>
                            <li>
                                <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dashboard</a>
                            </li>
                            <li>
                                <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Settings</a>
                            </li>
                            <li>
                                <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Earnings</a>
                            </li>
                            <li>
                                <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Sign out</a>
                            </li>
                        </TDropdown>

                    </div> */}


                <div className="col-span-4">
                    <div style={{ height: '5px' }} />
                    <TInputText
                        type="string"
                        id="collection"
                        value={inputCollectionValue}
                        onChange={(e) => setInputCollectionValue(e.target.value.toLowerCase())}
                        // className="w-full px-3 py-2 border bg-defaultback border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-diatomicCyan"
                        placeholder="Filter by asset info"
                    />

                </div>

                <div className="col-span-2 ">
                    <div style={{ height: '5px' }} />
                    {collection && <span className="text-center block pt-3">[ {collection} ]</span>}
                </div>

                <div className="col-span-2">
                    <div style={{ height: '5px' }} />
                    <div className="flex">
                        <TButton className={aggOp === "avg" ? "bg-diatomicDarkCyan" : ""} onClick={() => { setAggOp('avg') }}>AVG</TButton>
                        <TButton className={aggOp === "sum" ? "bg-diatomicDarkCyan" : ""} onClick={() => { setAggOp('sum') }}>SUM</TButton>
                    </div>
                </div>

                <div className="col-span-2">

                    <div style={{ height: '5px' }} />
                    <TButton
                        onClick={() => {
                            if (sampleData && name && aggregation.length > 1) {
                                // console.log(nexAgg)
                                addAggregator(`NEX_AGGREGATOR.${name}`, nexAgg)
                                // alert("Work in progress")
                                // setTimeout(() => {
                                //     console.log(aggregators)
                                // }, 1000);
                                setCanvasMode('canvas')
                                setModelProgress(-1)
                                // setmod
                                // close && close()
                            }
                            else {
                                alert("Give name and check data")
                            }
                        }}
                    >Add to Canvas</TButton>
                </div>

                <DateTimeRangePickerPopper
                    className="col-span-10 pt-1"
                    range={true}
                    btnSpanWidth={10}
                    closeBtnStyle={{
                        color: "white",
                        fontSize: "12px",
                        background: colourAccentSecondary,
                        borderRadius: "2px",
                        borderColor: colourAccentSecondary,
                        width: "100px",
                        display: "flex",
                        padding: 0,
                        margin: 0,

                    }}
                    btnStyle={{
                        color: "white",
                        fontSize: "12px",
                        // background: "darkcyan",
                        background: "rgba(80,80,80,0.2)",
                        borderRadius: "0px",
                        width: "100%",
                        padding: 0
                        // marginLeft: '-15px'
                    }}
                    startDate={`[ ${startDate ? startDate : 'pick date'} ]`}
                    endDate={`[ ${endDate ? endDate : 'pick date'} ]`}
                    onChange={(range) => {
                        // alert(JSON.stringify(range[0]))
                        setStartDate(range[0].startDate.toISOString().split('T')[0])
                        setEndDate(range[0].endDate.toISOString().split('T')[0])
                    }}
                />

                {/* <div className="col-span-2">
                    <div className="flex py-1">
                        <div style={{ height: '5px' }} />
                        <TButton
                            disabled={startDate === null && endDate === null}
                            onClick={async () => {
                                let result = await API.canvasFetchAggregateData(name, collection, startDate, endDate, aggregation.map(x => x.toUpperCase()), aggOp, 60)
                                setSampleData(result)
                                console.log(result)
                            }}>Check</TButton>
                    </div>
                </div> */}


                <div className="col-span-2">
                    <div className="flex py-1">
                        <div style={{ height: '5px' }} />
                        {/* <TButton
                                disabled={startDate === null && endDate === null}
                                onClick={async () => {
                                    let result = await API.canvasFetchAggregateData(name, "traffic_rtem", startDate, endDate, aggregation.map(x => x.toUpperCase()), aggOp, 60)
                                    setSampleData(result)
                                    console.log(result)
                                }}>Check</TButton> */}
                        <TButton onClick={() => {
                            setAggregation([])
                            setCollection('')
                            // setName('')
                            setInputCollectionValue('')
                            setSampleData(null)
                        }}>Clear</TButton>
                    </div>
                </div>

                <div className="col-span-3 py-2">Filtered Assets</div>
                <div className="col-span-3 py-2">Assets Included</div>
                <div className="col-span-6 py-2">Data Aggregate</div>

            </div>

            <div className="grid grid-cols-12 space-x-4">

                <motion.div className="overflow-y-auto h-[65vh] pb-[100px] col-span-3">
                    {inputCollectionValue !== '' && filteredByCollection.map((x, index) => {
                        let obj = JSON.parse(x)
                        return <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{
                                duration: 0.5,
                                delay: index * 0.1,
                                ease: "easeOut"
                            }}
                            className={"m-2 text-center border border-diatomicDarkCyan border-opacity-15 cursor-pointer py-2"}
                            onClick={() => {
                                let agg = structuredClone(aggregation)
                                setAggregation(Array.from(new Set([...agg, obj.asset_id])))
                                setCollection(obj.collection)
                            }}>
                            {obj.collection.toUpperCase()}.{obj.asset_id.toUpperCase()}
                        </motion.div>
                    })}
                </motion.div>

                <motion.div className="overflow-y-auto h-[65vh] pb-[100px] col-span-3">
                    {aggregation.map((x, index) => {
                        return <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{
                                duration: 0.5,
                                delay: index * 0.1,
                                ease: "easeOut"
                            }}
                            className={"m-2 text-center border border-diatomicDarkCyan border-opacity-15 cursor-pointer py-2"}
                            onClick={() => {
                                let agg = structuredClone(aggregation)
                                setAggregation(agg.filter(item => item !== x))
                            }}
                        >
                            {x.toUpperCase()}
                            {/* .{inputStreamValue.toUpperCase()} */}
                        </motion.div>
                    })}

                </motion.div>

                <div className="overflow-y-auto h-[65vh] pb-[100px] col-span-6">
                    {sampleData?.data && sampleData?.data?.timestamp?.length > 0 && Object.keys(sampleData.data).filter(x => x !== "timestamp").map(
                        (v, i) =>
                            <div style={{ paddingTop: "10px" }}>
                                <DCharts style={{ height: 200 }}
                                    id={`${v}-dchart`}
                                    title={v.toUpperCase()}
                                    datetimeArray={sampleData.data.timestamp}
                                    dataArray={[sampleData.data[v]]}
                                    colors={[colourAccent]}
                                    labels={['Base']}
                                    updateClear={false}
                                    blur={'0px'}
                                    // foreLabel={'PRED'}
                                    update={0}
                                />
                                <div style={{ height: "5px", width: "100%" }} />
                            </div>
                    )}
                    {/* <pre>
                        {JSON.stringify(nexAgg, null, 2)}
                    </pre> */}
                </div>

            </div>
        </div>
    </Box>
    );
}
