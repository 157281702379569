import { useState, useEffect } from 'react';
import { useSpring } from '@react-spring/web';

import Home from './components/Home';
import Platform from './components/Platform';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import * as API2 from './components/states';
import SplashScreen from './SplashScreen';
import { Transitioner } from './components/dElements/Animations';
import { AnimatePresence, motion } from 'framer-motion';

const ENABLE_SPLASHSCREEN = true


const consoleWarn = console.warn;
const SUPPRESSED_WARNINGS = ['There is a chart instance already initialized'];

console.warn = function filterWarnings(msg, ...args) {
  if (!SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry))) {
    consoleWarn(msg, ...args);
  }
};


function App() {
  const handle = useFullScreenHandle();
  const setPage = API2.globalStore().setPage;
  const page = API2.globalStore().page;

  const setWindowDimensions = API2.globalStore().setWindowDimensions;
  const init = API2.globalStore().init;
  const { urlParams, setUrlParams, setWindowOpen } = API2.globalStore();


  useEffect(() => {
    const urlSearchString = window.location.search;
    const thisParams = {}
    const params = new URLSearchParams(urlSearchString)
    params.forEach((v, k) => thisParams[k] = params.get(k));
    setUrlParams(thisParams)


    if (thisParams["page"]) {
      switch (thisParams["page"]) {
        case "platform":
          setPage('platform')
          break;
        case "collections":
        case "archive":
        case "archives":
        case "datasets":
          setWindowOpen("datasets")
          setPage('platform')
          break;
        case "services":
        case "apis":
        case "api":
          setWindowOpen("apis")
          setPage('platform')
          break;
        case "case studies":
        case "use case":
        case "cases":
          setWindowOpen('cases')
          setPage('platform')
          break;
        case "contribute":
          setWindowOpen('contribute')
          setPage('platform')
          break;
        case "roadmap":
          setWindowOpen('roadmap')
          setPage('platform')
          break;
      }
    }
  }, []);

  useEffect(() => {
    function handleResize() {
      const { innerWidth: width, innerHeight: height } = window;
      setWindowDimensions({ width, height });
    }

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <FullScreen
    style={{ overflowY: 'hidden', position: 'fixed', height: '100vh', width: '100vw' }}
    handle={handle}>

    <div style={{ background: '#383a3d', position: 'fixed', height: '100vh', width: '100vw', zIndex: -10 }} />

    <Transitioner open={!init && ENABLE_SPLASHSCREEN && !urlParams?.nosplash}>
    {/* <AnimatePresence> */}
      {/* {!init && ENABLE_SPLASHSCREEN && !urlParams?.nosplash && */}
        {/* // <motion.div
        //   initial={{ opacity: 1 }}
        //   animate={{ opacity: 1 }}
        //   exit={{ opacity: 0 }}
        //   transition={{ duration: 0.1 }}
        // > */}
          <SplashScreen />
        {/* </motion.div>} */}
    {/* </AnimatePresence> */}
    </Transitioner>

    <Transitioner open={init}>
    {/* <AnimatePresence> */}
      {page === 'home' && <Home setPage={(pg) => {
        setPage(pg)
      }} />}
    </Transitioner>

    <Platform
      setPage={(value) => {
        setPage(value)
      }}
      page={page}
      fullscreenToggle={() => {
        if (handle.active)
          handle.exit()
        else
          handle.enter()
      }}
    />

  </FullScreen>
}

export default App;