import * as GSTORE from "../states";
import { twMerge } from "tailwind-merge";

export default function TInputText({ children, className, ...props }) {

    const { colourAccent, colourAccentSecondary } = GSTORE.globalStore()

    return <input
        // style={{ color: 'cyan' }}
        className={twMerge(`w-full bg-transparent text-white text-sm border border-[2px] border-diatomicDarkCyan rounded-[3px] px-3 py-2 transition duration-300 ease focus:outline-none focus:border-diatomicCyan hover:border-diatomicCyan shadow-sm focus:shadow`, className)}
        {...props}>{children}</input>
}