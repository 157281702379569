const colourAccent1 = "cyan"
const colourAccent2 = "orange"

const colourAccentSecondary1 = "darkcyan"
const colourAccentSecondary2 = "darkorange"

const colourFadeBack1 = 'rgba(31, 31, 31, 0.4)'
const colourFadeBackDarker1 = 'rgba(31, 31, 31, 0.65)'

const colourAccentDisabled1 = 'rgba(255, 255, 255, 0.8)'

const colourBackground1 = 'rgba(51, 51, 51, 0.98)'
const colourBackground2 = 'rgba(255,255,255,0.95)'

export const colourAccent = colourAccent1
export const colourAccentSecondary = colourAccentSecondary1
export const colourBackground = colourBackground1
export const colourAccentDisabled = colourAccentDisabled1
export const colourFadeBack = colourFadeBack1
export const colourFadeBackDarker = colourFadeBackDarker1

export const buttonStyle = { width: '220px', backgroundColor: colourBackground2, padding: '10px', textAlign: 'center', borderRadius: '2px', color: 'rgba(0,0,0,0.9)' }
export const lightColor = { backgroundColor: colourBackground2 }

export const headStyle = { color: 'rgba(255,255,255,0.98)', textAlign: 'center' }
export const headStyleInverted = { color: 'rgba(0,0,0,0.98)', textAlign: 'center' }
export const hrStyle = {
    visibility: 'visible',
    margin: '10 auto',
    marginTop: '10px !important',
    marginBottom: '5px !important',
    width: '100%',
    border: 0,
    height: '1px',
    backgroundImage: 'linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0))'
}
export const backgroundStyle = {
    zIndex: 1, position: 'absolute',
    backgroundColor: colourBackground,
    boxShadow: '2px 2px 10px black'
}

export const verticalCenter = {
    margin: 0,
    top: '50%',
    '-ms-transform': 'translateY(-50%)',
    transform: 'translateY(-50%)',
}

export const cardStyle = {
    // backgroundColor: 'rgba(255,255,255,0.95)',
    // color: 'rgba(0,0,0,0.9)',
    padding: '10px',
    textAlign: 'center',
    borderRadius: '2px',
    margin: '10px',
    padding: '10px',
    backgroundColor: colourBackground2,
    color: colourBackground1
}

