import * as GSTORE from "../states";
import { twMerge } from "tailwind-merge";

export default function TButton({ children, className, ...props }) {

    const { colourAccent, colourAccentSecondary } = GSTORE.globalStore()



    return <button
        {...props}
        className={twMerge(`w-full text-diatomicCyan text-sm m-1 border border-[2px] border-diatomicDarkCyan rounded-[3px] px-3 py-2 transition duration-150 ease hover:border-diatomicCyan shadow-sm focus:shadow`, className)}
    >{children}</button>
}
