import * as GSTORE from "../states";
import { twMerge } from "tailwind-merge";

export default function TInputFile({ children, className, ...props }) {

    const { colourAccent, colourAccentSecondary } = GSTORE.globalStore()

    return <input
        // style={{ color: 'cyan' }}
        className={twMerge(`transition-colors bg-transparent rounded opacity-95 w-full h-[30px] file:bg-transparent file:border-solid file:rounded-[3px] file:border-diatomicDarkCyan file:hover:border-diatomicCyan file:text-white file:h-[28px] file:w-[150px]`, className)}
        {...props}>{children}</input>
}