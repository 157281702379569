import md5 from "md5";
import { TFARCHITECTURES, tf } from "../mlmethods/tensorflow";

export const NewID = (account) =>
  md5(account + new Date().toLocaleString() + Math.random());

// ml model structure
export const MLMODEL_STRUCTURE = {
  account: "",
  id: 0,
  name: "",
  scope: null,
  inputs: {},
  outputs: {},
  algorithm: { algo: "TF.FCNN", config: {} },
  modelInterval: 60,
  epochs: 100,
  trainingData: {
    lastAccuracy: -1,
    dataStart: null,
    dataEnd: null,
    embeddings: ["day", "hour"],
    drops: { nulls: true, zeroes: false },
  },
  layers: TFARCHITECTURES["TF.FCNN"].layers,
  compile: TFARCHITECTURES["TF.FCNN"].compile,
  modelBinary: null,
  lastTrainedOn: "",
  coordinates: [-1.9053006641463526, 52.47992709031189],
  created: "",
  modified: "",
};

// canvas model creation sructure
export const AIWORKFLOW_STRUCTURE = {
  canvasModels: {}, // MLMODEL_STRUCTURE objects
  aggregators: {},
  // geojsonImports: {},
  // csvImports: {},
  imports: {},
  timerange: [],
  interval: null,
  trainingStage: null,
  canvasMode: null,
  focusNode: null,
  modelProgress: -1,
  currentNexFocus: null
};

// global variables
export const ACCOUNT_GLOBALS_STRUCTURE = {
  colourAccent: 'cyan',
  colourAccentSecondary: 'darkcyan',
  borderRadius: '4px',
  predictions: null,
  urlParams: {},
  collections: {},
  merlinConvo: [],
  merlinWard: null,
  merlinAssist: false,
  assets: [],
  page: "home",
  layers: {},
  layersVisibility: {},
  message: null,
  light: null,
  windowDimensions: { width: 0, height: 0 },
  init: false,
  windowOpen: null,
  appLaunchable: 0,
  showMeta: true,
  geotaggers: [],
};

// export const CANVAS_STRUCTURE = {   // NOT IN USE
//     account: '',
//     id: 0,
//     models: [] // MLMODEL_STRUCTURE objects
// }
